import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Logger } from '@app/@core';
import { ISnackBarConfig } from '@app/@shared/dialogs/models/dialogs.interfaces';
import { DialogsService } from '@app/@shared/dialogs/services/dialogs.service';
import { InfrastructureNotificationRequestsService } from '@app/@shared/models/infrastructure-notification-requests/infrastructure-notification-requests.service';
import { CreateInfrastructureNotificationRequest } from '@app/@shared/models/infrastructure-notification-requests/params/create-infrastructure-notification-request';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';

const log = new Logger('InfrastructureNotificationRequestCreationDialogComponent');

@Component({
  selector: 'app-infrastructure-notification-request-creation-dialog',
  templateUrl: './infrastructure-notification-request-creation-dialog.component.html',
  styleUrls: ['./infrastructure-notification-request-creation-dialog.component.scss'],
})
export class InfrastructureNotificationRequestCreationDialogComponent implements OnInit {
  form = this.fb.group({
    reason: ['', Validators.required],
    description: [''],
    links: [''],
  });

  isCreating = false;
  infrastructureId: number;

  constructor(
    private dialogRef: MatDialogRef<InfrastructureNotificationRequestCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { infrastructure: Infrastructure2 },
    private fb: FormBuilder,
    private infrastructureNotificationRequestsService: InfrastructureNotificationRequestsService,
    private dialogsService: DialogsService
  ) {
    this.infrastructureId = this.data.infrastructure.id;
  }

  ngOnInit(): void {}

  onSaveButtonClicked(): void {
    this.isCreating = true;

    const formValue = this.form.value;

    const param = {
      infrastructure_id: this.infrastructureId,
      ...formValue,
    } as CreateInfrastructureNotificationRequest;

    this.infrastructureNotificationRequestsService.createInfrastructureNotificationRequest(param).subscribe({
      next: (result: { id: number }) => {
        log.info(`Infrastructure notification request created with id=${result.id}`);
        this.dialogsService.openSnackBar({
          message: 'common.infrastructureNotificationRequestCreatedSuccess',
        } as ISnackBarConfig);
        this.isCreating = false;
        this.dialogRef.close(result.id);
      },
      error: (error) => {
        log.error(error);
        this.isCreating = false;
        this.dialogsService.openSnackBar({
          message: 'common.infrastructureNotificationRequestCreatedError',
        } as ISnackBarConfig);
      },
    });
  }
}
