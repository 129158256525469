import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  contactForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<any>) {
    this.createForm();
  }

  private createForm() {
    this.contactForm = this.formBuilder.group({
      contactName: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      contactPhone: ['', [Validators.required, Validators.maxLength(20)]],
      subject: ['', [Validators.required, Validators.maxLength(50)]],
      message: ['', [Validators.required, Validators.maxLength(500)]],
    });
  }

  sendMessage() {
    const msg = this._getQueryMessage(this.contactForm.value);
    this.dialogRef.close(msg);
  }

  private _getQueryMessage(msg: any): any {
    const query = {
      'contact_name': msg.contactName,
      'contact_email': msg.contactEmail,
      'contact_phone': msg.contactPhone,
      'subject': msg.subject,
      'message': msg.message,
    }
    return query;
  }
}
