import { CreateFailureFactorParam } from '../../models/infrastructures/evaluations/failure-modes/failure-factors/create-failure-factor.param';
import { FailureFactorType } from '../../models/infrastructures/evaluations/failure-modes/failure-factors/failure-factor-type.enum';

export function failureFactorTextIsValid(text: string): boolean {
  if (text == null) {
    return false;
  }
  return (text.startsWith(FailureFactorType.Minus) || text.startsWith(FailureFactorType.Plus)) && text.length > 1;
}

export function failureFactorTextToList(
  text: string,
  infrastructureFailureModesId?: number
): CreateFailureFactorParam[] {
  if (!failureFactorTextIsValid(text)) {
    return null;
  }
  const lines = text.split('\n');

  let item: CreateFailureFactorParam;

  const factorList = [];
  for (let i = 0; i < lines.length; i++) {
    // const [firstChart, ...rest] = lines[i]
    const line = lines[i].trim();

    if (line.startsWith(FailureFactorType.Minus) || line.startsWith(FailureFactorType.Plus)) {
      if (item != null) {
        factorList.push(item);
      }
      const type = line[0];
      const description = line.substring(1).trim();
      item = {
        type,
        infrastructure_failure_modes_id: infrastructureFailureModesId,
        description,
      } as CreateFailureFactorParam;
    } else {
      item.description = `${item.description}\n${line.trim()}`;
    }
  }
  factorList.push(item);
  return factorList;
}
