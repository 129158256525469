import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  public get<T>(resource: string, options?: Object): Observable<T> {
    let params: HttpParams = this.getParams(options);
    return this.httpClient.get<T>(resource, {params: params});
  }

  public post<T>(resource: string, body: any, options?: Object): Observable<T> {
    const params: HttpParams = this.getParams(options);
    return this.httpClient.post<T>(resource, body, { reportProgress: true, params: params });
  }

  public put<T>(resource: string, body: any, options?: Object): Observable<T> {
    return this.httpClient.put<T>(resource, body, options);
  }

  public delete<T>(resource: string, options?: Object): Observable<T> {
    return this.httpClient.delete<T>(resource, options);
  }

  public deleteWithBody<T>(resource: string, body: any, options?: Object): Observable<T> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      reportProgress: true,
      body: body
    };
    return this.httpClient.delete<T>(resource, httpOptions);
  }

  private getParams(options?: Object): HttpParams{
    let params = new HttpParams();

    for (const key in options) {
      if (options[key]) {
        params = params.set(key, options[key]);
      }
    }
    return params;
  }
}
