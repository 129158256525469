<mat-slide-toggle
  [disabled]="isLoading"
  [checked]="riskModel.is_active"
  (change)="onRiskModelEnableChange($event.checked)"
  color="primary"
  fxFlex
>
  <span *ngIf="isLoading && riskModel.is_active"> {{ 'common.deactivating' | translate | uppercase }} </span>
  <span *ngIf="isLoading && !riskModel.is_active"> {{ 'common.activating' | translate | uppercase }} </span>

  <span *ngIf="!isLoading && riskModel.is_active"> {{ 'common.enabled' | translate | uppercase }} </span>
  <span *ngIf="!isLoading && !riskModel.is_active"> {{ 'common.disabled' | translate | uppercase }} </span>
</mat-slide-toggle>
