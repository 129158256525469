<div fxFlexFill fxLayout="column" class="mat-container">
  <div fxFlex="nogrow" fxLayoutAlign="end start">
    <app-language-selector icon="true" style="color: white"></app-language-selector>
  </div>
  <div fxFlex>
    <div fxFlexFill fxLayoutAlign="center center" fxLayout="column">
      <mat-card class="full-width">
        <form [formGroup]="loginForm">
          <div fxFlex fxFill fxLayout="column" fxLayoutGap="5%">
            <div class="login-fields" fxLayout="column" fxLayoutGap="1rem">
              <!-- Platfrom logo -->
              <div fxFlex="nogrow" fxLayoutAlign="center center">
                <img class="infrastructure-logo" src="assets/images/logos/logo_infrastructure_a.png" alt="Platform logo"/>
              </div>

              <mat-form-field>
                <mat-label>{{ 'login.Username' | translate | uppercase }}</mat-label>
                <input type="text" matInput formControlName="username" required />
                <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
                  <span>{{ 'login.Username is required' | translate | uppercase }}</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'login.Password' | translate | uppercase }}</mat-label>
                <input type="password" matInput formControlName="password" required />
                <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
                  <span>{{ 'login.Password is required' | translate | uppercase }}</span>
                </mat-error>
              </mat-form-field>
              <button mat-flat-button color="primary" type="submit" (click)="login()" [disabled]="loginForm.invalid || isLoading">
                <span *ngIf="!isLoading">
                  {{ 'login.Access' | translate | uppercase }}
                </span>
                <span *ngIf="isLoading">
                  {{ 'login.Accessing' | translate | uppercase }}
                </span>
              </button>
            </div>
            <button mat-flat-button color="accent" (click)="recoverPassword()" [disabled]="isLoading">
              {{ 'login.Recover password' | translate | uppercase }}
            </button>
            <mat-divider fxFlex [inset]="true"></mat-divider>

            <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="0.25rem">
              <span style="font-size: 12px;"><strong>{{ 'funded by' | translate }}:</strong></span>
              <img src="assets/images/red_es.png" class="infrastructure-logo" alt="iPresas RedES" />
            </div>

            <mat-progress-bar *ngIf="isLoading" mode="query"> </mat-progress-bar>

          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
