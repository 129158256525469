import { CustomersService } from './users/customers/customers.service';

import { InfrastructureService } from './infrastructures/infrastructure.service';
import { InfrastructureTypeService } from './infrastructure-type/infrastructure-type.service';

export const MODELS_SERVICES = [
  CustomersService,
  InfrastructureService,
  InfrastructureTypeService,
];
