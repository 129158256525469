export class UpdateInfrastructureImageParam {
  infrastructure_id: number;
  file_name: string;
  file?: File;

  constructor(init?: Partial<UpdateInfrastructureImageParam>) {
    Object.assign(this, init);
  }

  toMultipartFormData(): FormData {
    const formData = new FormData();
    Object.keys(this).forEach((key) => {
      if (this[key] != null) {
        formData.append(key, this[key]);
      }
    });
    return formData;
  }
}
