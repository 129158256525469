<table mat-table [dataSource]="list" fxFlex>
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.username' | translate | uppercase }}</th>
    <td mat-cell *matCellDef="let item">{{ item.user_login }}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.date' | translate | uppercase }}</th>
    <td mat-cell *matCellDef="let item">{{ item.notification_datetime | date: 'dd/MM/yyyy HH:mm'}}</td>
  </ng-container>

  <ng-container matColumnDef="reason">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.reason' | translate | uppercase }}</th>
    <td mat-cell *matCellDef="let item">{{ item.reason }}</td>
  </ng-container>

  <ng-container matColumnDef="emailReceiver">
    <th mat-header-cell *matHeaderCellDef>{{ 'common.emailReceiver' | translate | uppercase }}</th>
    <td mat-cell *matCellDef="let item">{{ item.email_receiver }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
