import {
  createValueState,
  PortModel,
  PortModelOptions,
  ValueState,
} from '@rxzu/angular';

export class RiskManagementPortModel extends PortModel {
  direction$: ValueState<'in' | 'out'>;
  constructor(options: PortModelOptions & { direction?: 'in' | 'out' }) {
    super({
      ...options,
      namespace: 'risk-management',
      linkNamespace: 'risk-management',
    });

    this.direction$ = createValueState(options.direction ?? 'in');
    this.setCanCreateLinks(this.direction$.value === 'out');
  }

  canLinkToPort(port: RiskManagementPortModel) {
    if (port.getParent() === this.getParent()) {
      return false;
    }

    return true;
  }
}
