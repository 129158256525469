<mat-card fxLayout="column" fxLayoutGap="0.5rem">
  <app-search-bar
    title="{{ 'infrastructure.tabRiskModels._tabTitle' | translate | uppercase }}"
    outline="true"
    [hasNewButton]="hasNewButton && canCreate"
    [isLoading]="isLoading"
    (searchQueryEvent)="onSearchQuery($event)"
    (buttonRefreshEvent)="onRefreshButtonClicked()"
    (buttonCreateEvent)="onCreateButtonClicked()"
  >
  </app-search-bar>

  <app-risk-model-table [riskModelList]="filteredRiskModelsList" (riskModelChanged)="onRiskModelChanged()">
  </app-risk-model-table>
</mat-card>
