import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { INotificationConfig } from '../models/dialogs.interfaces';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  title: string;
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: INotificationConfig) {
    this.title = data.title;
    this.message = data.message;
  }

}
