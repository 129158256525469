<mat-card fxLayout="column" fxLayoutGap="0.5rem">

  <app-search-bar [hasSearchButton]="false"
    title="{{ 'common.infrastructureNotificationRequestList' | translate | uppercase }}" [hasNewButton]="true"
    (buttonCreateEvent)="onCreateButtonClicked()" (buttonRefreshEvent)="loadData()" outline="true">
  </app-search-bar>

  <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>

  <app-infrastructure-notification-request-list-table *ngIf="!isLoading" [list]="list">
  </app-infrastructure-notification-request-list-table>

  <mat-paginator *ngIf="!isLoading" [pageIndex]="page" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    [length]="total" (page)="onPageEvent($event)" fxFlex>
  </mat-paginator>
  <!-- <app-pagination [page]="page" [size]="pageSize" [total]="total" (pageEvent)="onPageEvent($event)"> </app-pagination>  -->


</mat-card>
