<div fxLayout="row" fxLayoutGap="0.5rem">
  <button
    [disabled]="isDeleting || !report.path_file"
    [matTooltip]="'dashboard.PreviewReport' | translate"
    (click)="previewReportButtonClicked.emit(report)"
    mat-icon-button
    color="primary"
  >
    <mat-icon> preview </mat-icon>
  </button>

  <button
    [disabled]="isDeleting || !report.path_file"
    [matTooltip]="'dashboard.DownloadReport' | translate"
    (click)="downloadReportButtonClicked.emit(report)"
    mat-icon-button
    color="primary"
  >
    <mat-icon> file_download </mat-icon>
  </button>

  <button
    [disabled]="isDeleteReportButtonDisabled()"
    [matTooltip]="'dashboard.DeleteReport' | translate"
    (click)="onDeleteReportButtonClicked()"
    mat-icon-button
    color="warn"
  >
    <mat-icon> delete </mat-icon>
  </button>
</div>
