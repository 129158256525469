<!-- <mat-card>
  <mat-card-content fxLayout="column"> -->
<div fxLayout="column" fxLayoutGap="0.5rem" fxFlex fxFill>
  <app-search-bar
    title="{{ 'common.reportList' | translate | uppercase }}"
    outline="true"
    [isLoading]="isLoading"
    (searchQueryEvent)="query = $event"
    (buttonRefreshEvent)="loadData()"
  >
  </app-search-bar>
  <app-report-list-filter (filterValueChanges)="filterValue = $event"> </app-report-list-filter>
  <app-report-list-table
    *ngIf="list?.length"
    [list]="list"
    [lastGeneratedReport]="lastGeneratedReport"
    [selectedReport]="selectedReport"
    (previewReportButtonClicked)="onPreviewReportButtonClicked($event)"
    (reportDeleted)="onReportDeleted($event)"
    fxFlex
  >
  </app-report-list-table>

  <mat-paginator
    [pageIndex]="page"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [length]="total"
    (page)="onPage($event)"
    fxFlex
  >
  </mat-paginator>
</div>
<!-- </mat-card-content>
</mat-card> -->
