import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'restrictionTypeTranslatedDescription',
})
export class RestrictionTypeTranslatedDescriptionPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(restrictionTypeCode: String): string {
    const key = `RestrictionTypeDescription.${restrictionTypeCode}`;
    return this.translateService.instant(key);
  }
}
