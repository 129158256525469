import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { CustomerSelectionDialogComponent } from './header/selected-customer/customer-selection-dialog/customer-selection-dialog.component';
import { SelectedCustomerComponent } from './header/selected-customer/selected-customer.component';
import { MenuComponent } from './menu/menu.component';
import { ShellComponent } from './shell/shell.component';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    ShellComponent,
    SelectedCustomerComponent,
    CustomerSelectionDialogComponent,
  ],
})
export class ShellModule {}
