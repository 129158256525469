import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface FileToUpload {
  name: string;
  size: number;
  url: string;
  file: File;
}

const allowedImageFormats = ['image/jpeg', 'image/png'];

@Component({
  selector: 'app-files-to-upload',
  templateUrl: './files-to-upload.component.html',
  styleUrls: ['./files-to-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesToUploadComponent),
      multi: true,
    },
  ],
})
export class FilesToUploadComponent implements OnInit, ControlValueAccessor {
  @Input()
  limit: number;

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  files: FileToUpload[] = [];

  value: File[] = [];
  isDisabled: boolean;

  constructor() {}

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit() {}

  writeValue(value: File[]): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  fileBrowseHandler(files: File[]) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileListChanged();
  }

  prepareFilesList(filesToPrepare: File[]) {
    for (const file of filesToPrepare) {
      const fileWithUrl = {} as FileToUpload;

      fileWithUrl.name = file.name;
      fileWithUrl.size = file.size;
      fileWithUrl.file = file;

      const reader = new FileReader();
      if (!allowedImageFormats.includes(file.type)) {
        fileWithUrl.url = 'assets/icons/icon_doc.svg';
        this.files.push(fileWithUrl);
        this.fileListChanged();
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const url = reader.result.toString();
          fileWithUrl.url = url;

          this.files.push(fileWithUrl);
          this.fileListChanged();
        };
      }
    }
    this.fileDropEl.nativeElement.value = '';
  }

  fileListChanged() {
    const fileList = this.getFileList();
    this.value = fileList;
    this.onTouch();
    this.onChange(fileList);
  }

  getFileList(): File[] {
    const fileList: File[] = [];
    for (const file of this.files) {
      fileList.push(file.file);
    }
    return fileList;
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
