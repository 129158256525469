import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Report } from '@shared/models/reports/report';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss'],
})
export class ReportDetailComponent implements OnInit {
  @Input() set reportToPreview(report: Report) {
    this._report = report;
    if (report) {
      this.loadData();
    }
  }
  get reportToPreview(): Report {
    return this._report;
  }

  iframeUrl?: SafeResourceUrl;
  private _report: Report;

  constructor(private dom: DomSanitizer) {}

  ngOnInit(): void {}

  loadData(): void {
    const reportUrl = escape(this.reportToPreview.signed_path_file);
    const url = `https://view.officeapps.live.com/op/embed.aspx?src=${reportUrl}`;
    this.iframeUrl = this.dom.bypassSecurityTrustResourceUrl(url);
  }
}
