import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@app/@shared/services/authentication.service';
import { environment } from '@env/environment';
import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { MismatchValidator } from '@shared/validators/mismatch-validator';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent {
  recoverPasswordForm: FormGroup;
  isDisabled: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private authenticationService: AuthenticationService,
    private dialogService: DialogsService
  ) {
    this.createForm();
  }

  private createForm() {
    const pattern = environment.passwordPattern;
    this.recoverPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
      verificationCode: [{ value: '', disabled: true }, Validators.required],
      newPassword: [{ value: '', disabled: true }, [Validators.pattern(pattern), Validators.required]],
      confirmPassword: [{ value: '', disabled: true }, Validators.required],
    });

    this.recoverPasswordForm
      .get('confirmPassword')
      .setValidators([Validators.required, MismatchValidator.mismatch(this.recoverPasswordForm.get('newPassword'))]);
  }

  onSendCode() {
    const formValues = this.recoverPasswordForm.value;
    this.authenticationService
      .forgotPassword(formValues.username)
      .then((data) => {
        this.dialogService.openSnackBar({
          message: `Verification code sent to ${data.CodeDeliveryDetails.Destination}`,
        });
        this.isDisabled = false;
        this.recoverPasswordForm.enable();
      })
      .catch((error) => this.dialogService.openSnackBar({ message: error.err.message, duration: 2000 }));
  }

  onVerify() {
    const formValues = this.recoverPasswordForm.value;
    this.authenticationService
      .confirmPassword(formValues.username, formValues.verificationCode, formValues.newPassword)
      .then(() => {
        this.dialogRef.close();
        this.dialogService.openSnackBar({ message: 'Password changed' });
      })
      .catch((error) => {
        this.dialogService.openSnackBar({ message: error.err.message });
      });
  }
}
