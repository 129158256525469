<div fxLayout="row" fxLayoutGap="10px">
  <button (click)="viewButtonClicked.emit()" [ngClass.lt-md]="'mat-icon-button'" mat-button color="primary"
    fxFlex.gt-sm>
    <mat-icon> visibility </mat-icon>
    <span fxHide.lt-md> {{ 'common.view' | translate | uppercase }} </span>
  </button>

  <!-- <button
    (click)="editButtonClicked.emit()"
    ngClass.lt-md="mat-icon-button"
    ngClass.gt-sm="mat-raised-button"
    mat-button
    color="accent"
    fxFlex
  >
    <mat-icon> create </mat-icon>
    <span fxHide.lt-md>
      {{ 'common.edit' | translate | uppercase }}
    </span>
  </button>

  <button
    (click)="deleteButtonClicked.emit()"
    ngClass.lt-md="mat-icon-button"
    ngClass.gt-sm="mat-raised-button"
    mat-button
    color="warn"
    fxFlex
  >
    <mat-icon> delete </mat-icon>
    <span fxHide.lt-md>
      {{ 'common.delete' | translate | uppercase }}
    </span>
  </button> -->
</div>
