<div fxLayout="row" fxLayoutGap="0.5rem">
  <mat-form-field fxFlex appearance="outline" class="mat-overwrite" floatLabel="always">
    <mat-label> {{ 'common.infrastructure' | translate | uppercase }} </mat-label>

    <mat-select [formControl]="formControl" multiple="false">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterCtrl"
          showToggleAllCheckbox="multiple"
          toggleAllCheckboxTooltipMessage="{{ 'common.selectAll' | translate | uppercase }}"
          placeholderLabel="{{ 'common.search' | translate | uppercase }}"
          noEntriesFoundLabel="{{ 'common.itemNotFound' | translate | uppercase }}"
        >
        </ngx-mat-select-search>
      </mat-option>
      <!-- <mat-option [value]="null"> Ninguno </mat-option> -->
      <mat-option
        [matTooltip]="'' | translate"
        [matTooltipDisabled]="item.risk_model"
        *ngFor="let item of filteredList"
        [value]="item.id"
        [disabled]="!item.risk_model"
      >
        <div *ngIf="item.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
          {{ item.name }}
          <span *ngIf="!item.risk_model">
            {{ 'common.noRiskModel' | translate | uppercase }}
          </span>
        </div>
      </mat-option>
    </mat-select>

    <!-- <mat-hint>
    {{ 'common.selectOneInfrastructure' | translate | uppercase }}
  </mat-hint> -->
  </mat-form-field>
  <ng-template [ngxPermissionsOnly]="['Manager', 'Engineer']">
    <button
      [disabled]="formControl.value === null || isLoading"
      [routerLink]="['/infrastructures', formControl.value]"
      mat-icon-button
      color="primary"
      style="padding-top: 0.3em"
    >
      <mat-icon>settings</mat-icon>
    </button>
  </ng-template>
</div>
<ng-template #loader>
  <mat-progress-bar mode="query"> </mat-progress-bar>
</ng-template>
