import { Pipe, PipeTransform } from '@angular/core';
import { failureFactorTextToList } from '../libs/failure-factor/failure-factor-text';
import { CreateFailureFactorParam } from '../models/infrastructures/evaluations/failure-modes/failure-factors/create-failure-factor.param';

@Pipe({ name: 'failureFactor' })
export class FailureFactorPipe implements PipeTransform {
  transform(text: string): CreateFailureFactorParam[] {
    return failureFactorTextToList(text);
  }
}
