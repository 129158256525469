import { Component, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, delay} from 'rxjs/operators';

import { ISearchDropdown } from '../../models/search-dropdown.interface'

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnChanges {

  @Input() title: string;
  @Input() items: ISearchDropdown[];
  @Input() required: boolean;

  @Input()
  set clearValue(value: number) {
    if (value === null) {
      this.clear();
    }
  }

  @Input()
  set disabled(disabled: boolean) {
    this.updateForm(disabled);
  }

  @Input()
  set item(item: ISearchDropdown) {
    this._item = item;
    this.formControl.setValue(this.item);
  }

  get item(): ISearchDropdown {
    return this._item;
  }

  @Output() selectedItem: EventEmitter<ISearchDropdown> = new EventEmitter<ISearchDropdown>();

  form: FormGroup;
  formControl = new FormControl();
  filteredOptions: Observable<ISearchDropdown[]>;

  private _item: ISearchDropdown;

  constructor() { }

  ngOnChanges() {
    this.filteredOptions = this.formControl.valueChanges
      .pipe(
        startWith<string | ISearchDropdown>(this.item || ''),
        delay(0),
        map(item => item || ''),
        map(item => typeof item === 'string' ? item : item.searchString),
        map(label => label ? this._filter(label) : this._clear())
      );
  }

  clear() {
    this.formControl.setValue('');
    this.selectedItem.emit(null);
    this.formControl.reset();
  }

  inputDisplay(item?: ISearchDropdown): string | undefined {
    return item ? item.searchString : undefined;
  }

  onSelectItem(item: ISearchDropdown) {
    this.selectedItem.emit(item);
  }

  private updateForm(disabled:  boolean) {
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  private _clear(): ISearchDropdown[] {
    this.selectedItem.emit(null);
    return this.items.slice();
  }

  private _filter(label: string): ISearchDropdown[] {
    if (typeof this.formControl.value === 'string') {
      this.selectedItem.emit(null);
    }
    if (label) {
      const filterValue = label.toLowerCase();
      return this.items.filter(item => item.searchString.toLowerCase().indexOf(filterValue) > -1);
    }
    return this.items;
  }

}
