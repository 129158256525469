import { Injectable } from '@angular/core';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

import { environment } from '@env/environment';


@Injectable()
export class TokenService {

  private userPool: any = null;

  constructor() {
    const poolData = {
      UserPoolId: environment.cognito.UserPoolId,
      ClientId: environment.cognito.ClientId,
      Paranoia: 7
    };
    this.userPool = new CognitoUserPool(poolData);
  }

  getAccessToken(): Promise<string> {
    return this.getCognitoUserSession()
      .then(session => this.recheckExpiration(session, 'access'))
      .then(session => session.getAccessToken().getJwtToken());
  }

  getIdToken(): Promise<string> {
    return this.getCognitoUserSession()
      .then(session => this.recheckExpiration(session, 'id'))
      .then(session => session.getIdToken().getJwtToken());
  }

  getPayload(): Promise<{}> {
    return this.getIdToken()
      .then(token => {
        const payloadStr = token.split('.')[1];
        const payloadDecoded = atob(payloadStr);
        const payloadJsonDecoded = JSON.parse(payloadDecoded);
        return payloadJsonDecoded;
      });
  }

  private getCognitoUserSession(): Promise<CognitoUserSession> {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser === null) {
      return Promise.reject(new Error('No current session'));
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject({reason: 'error in getCognitoUserSession', err: err});
        } else {
          if (session.isValid()) {
            resolve(session);
          }
        }
      });
    });
  }

  private recheckExpiration(session: CognitoUserSession, type: string): Promise<CognitoUserSession> {
    const token = (type === 'id') ? session.getIdToken() : session.getAccessToken();
    const expiration = token.getExpiration();
    const offset = 300;
    if (expiration - (new Date().getTime() / 1000) < offset) {
      return new Promise((resolve, reject) => {
        const cognitoUser = this.userPool.getCurrentUser();
        cognitoUser.refreshSession(session.getRefreshToken(), (err, new_session) => {
          if (err) {
            reject({reason: 'error in recheckExpiration', err: err});
          } else {
            resolve(new_session);
          }
        });
      });
    }
    return Promise.resolve(session);
  }
}