import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Logger } from '@core';
import { Infrastructure2 } from '@shared/models/infrastructures/infrastructure2';
import { SharedDataService } from '@shared/services/shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const log = new Logger('SelectInfrasctructureSingleComponent');

@Component({
  selector: 'app-select-infrastructure-single',
  templateUrl: './select-infrastructure-single.component.html',
  styleUrls: ['./select-infrastructure-single.component.scss'],
})
export class SelectInfrastructureSingleComponent implements OnInit, OnDestroy {
  @Input() set form(form: FormGroup) {
    this._form = form;
    form.addControl('selectedInfrastructureId', this.formControl);
  }
  get form(): FormGroup {
    return this._form;
  }

  // @Input() value?: number;
  list: Infrastructure2[] = [];
  allInfrastructureIdsWithModel = new Array<number>();
  filteredList: Infrastructure2[] = [];
  isDisabled = false;
  filterCtrl = new FormControl();
  formControl: FormControl = new FormControl([]);
  private _isLoading = false;
  public get isLoading() {
    return this._isLoading;
  }
  public set isLoading(isLoading) {
    this._isLoading = isLoading;
    if (isLoading) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  query: string;
  selectFirstWhenIsLoaded = true;

  private _form?: FormGroup;
  // private _infrastructureTypeIds: number[];
  // get infrastructureTypeIds(): number[] {
  //   return this._infrastructureTypeIds;
  // }
  // @Input() set infrastructureTypeIds(infrastructureTypeIds: number[]) {
  //   this._infrastructureTypeIds = infrastructureTypeIds;
  //   this.markInfrastruresByType();

  // }

  // private _selectedInfrastructureId?: number;
  // public get selectedInfrastructureId(): number {
  //   return this._selectedInfrastructureId;
  // }
  // public set selectedInfrastructureId(selectedInfrastructureId: number) {
  //   if (this._selectedInfrastructureId !== selectedInfrastructureId) {
  //     this._selectedInfrastructureId = selectedInfrastructureId;
  //     this.formControl.setValue(selectedInfrastructureId);
  //   }
  // }
  private _onDestroy = new Subject();

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit() {
    this.sharedDataService
      .isLoadingInfrastructureList()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((isLoading) => (this.isLoading = isLoading));

    this.sharedDataService
      .getInfrastructureList()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((infrastructureList) => {
        this.list = infrastructureList;
        this.filteredList = this.list;
        this.allInfrastructureIdsWithModel = this.list.filter((value) => value.risk_model).map((value) => value.id);

        // this.sharedDataService.setInfrastructureList(this.list);

        // if (this.list?.length > 0) {
        //   if (this.selectedInfrastructureId) {
        //     this.formControl.setValue(this.selectedInfrastructureId);
        //   } else if (this.selectFirstWhenIsLoaded) {
        //     const firstValidItem = this.list.find((value) => value.risk_model != null);
        //     if (firstValidItem) {
        //       this.selectedInfrastructureId = firstValidItem.id;
        //       this.formControl.setValue(firstValidItem.id);

        //     }
        //   }
        // }
      });

    // this.sharedDataService
    //   .getSelectedInfrastructureId()
    //   .pipe(takeUntil(this._onDestroy))
    //   .subscribe((selectedInfrastructureId) => {
    //     if (selectedInfrastructureId !== this.formControl.value) {
    //       this.selectedInfrastructureId = selectedInfrastructureId;
    //       this.formControl.setValue(selectedInfrastructureId);
    //     }
    //   });

    this.sharedDataService
      .getSelectedInfrastructureId()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((selectedInfrastructureId) => {
        this.formControl.setValue(selectedInfrastructureId, { emitEvent: false });
        // this.selectedInfrastructureId = selectedInfrastructureId;
      });

    this.formControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((selectedInfrastructureId) => {
      this.sharedDataService.setSelectedInfrastructureId(selectedInfrastructureId);
    });

    // listen for search field value changes
    this.filterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((query) => {
      this.filteredList = this.list.filter((value) => {
        const normalizedName = value.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        const normalizedQuery = query
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        return normalizedName.includes(normalizedQuery);
      });
    });

    // this.sharedDataService.getSelectedCustomer().pipe(takeUntil(this._onDestroy)).subscribe(customer=>{

    // })
  }

  ngOnDestroy() {
    this._onDestroy.next();
  }
}
