<h2 mat-dialog-title class="center-align">
  {{ 'common.editInfrastructure' | translate | uppercase }}
</h2>
<div class="mat-typography" mat-dialog-content fxLayout="column" >
  <app-infrastructure-form [infrastructure]="infrastructure" [customerId]="customerId" [parentForm]="form"
    [mode]="mode"> </app-infrastructure-form>
</div>

<mat-dialog-actions fxLayout="column" fxLayoutAlign="start">
  <app-default-form-actions [createButtonDisabled]="form.invalid" [mode]="mode"
    (saveButtonClicked)="onSaveButtonClicked()" (cancelButtonClicked)="onCancelButtonClicked()" fxFlex>
  </app-default-form-actions>
</mat-dialog-actions>
