import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Report } from '@shared/models/reports/report';

@Component({
  selector: 'app-report-list-table',
  templateUrl: './report-list-table.component.html',
  styleUrls: ['./report-list-table.component.scss'],
})
export class ReportListTableComponent implements OnInit {
  @Input() lastGeneratedReport?: Report;
  @Input() list: Report[];
  @Input() selectedReport?: Report;

  @Output() reportDeleted = new EventEmitter<number>();
  @Output() previewReportButtonClicked = new EventEmitter<Report>();

  readonly displayedColumns = ['generationDate', 'processTime', 'state', 'actions'];

  constructor() {}

  ngOnInit(): void {}

  onPreviewReportButtonClicked(report): void {
    this.previewReportButtonClicked.emit(report);
  }

  onDownloadReportButtonClicked(report): void {
    this.downloadReport(report);
  }

  onReportDeleted(reportId: number): void {
    // If delected report is selelected then unselect report
    if (reportId === this.selectedReport.id) {
      this.selectedReport = null;
    }
    this.reportDeleted.emit(reportId);
  }

  private downloadReport(report: Report) {
    const a = document.createElement('a');
    a.href = report.signed_path_file;
    a.download = 'report.pdf'; // TODO: Customize this
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
