import { PaginatedResult } from '../paginated-result.interface';
import { DiagramFlowInterface, DiagramFlowNode } from './diagram-flow.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DiagramFlowService {

  constructor(private http: HttpClient) { }

  getDiagramModelById(diagramId: number): Observable<DiagramFlowInterface> {
    const url = '/infrastructure_risk_model_diagram';
    const params = new HttpParams().set(
      'infrastructure_risk_model_id', diagramId.toString()
      );
    return this.http.get<PaginatedResult<DiagramFlowInterface>>(url, { params })
    .pipe(map(result => result.items[0]));
  }


  getCompressedFiles(diagramId: number): Observable<string> {
    const url = '/infrastructure_risk_model_diagram_files';
    const params = new HttpParams().set(
      'infrastructure_risk_model_id', diagramId.toString()
    );
    return this.http
      .get(url, { params }).pipe(
        map((url: string) => url['url'])
      );
  }

  patchDiagram(query: any): Observable<DiagramFlowInterface> {
    return this.http
      .patch('/infrastructure_risk_model_diagram', query).pipe(
        map((diagramFlow: DiagramFlowInterface) => {
          return diagramFlow;
        })
      );
  }

  patchDiagramImage(data: FormData): Observable<DiagramFlowInterface> {
    return this.http
      .patch('/infrastructure_risk_model_diagram_image', data).pipe(
        map((diagramFlow: any) => diagramFlow)
      );
  }

  patchDiagramPicture(data: FormData): Observable<DiagramFlowInterface> {
    return this.http
      .patch('/infrastructure_risk_model_diagram_picture', data).pipe(
        map((diagramFlow: any) => diagramFlow)
      );
  }

  postDiagramNode(query: FormData): Observable<number> {
    return this.http
      .post('/infrastructure_risk_model_diagram/node', query).pipe(
        map((node: any) => node.id)
      );
  }

  patchDiagramNode(query: FormData): Observable<DiagramFlowNode> {
    return this.http
      .patch('/infrastructure_risk_model_diagram/node', query).pipe(
        map((node: any) => node)
      );
  }

  deleteDiagramNode(query: any): Observable<number> {
    return this.http
      .delete('/infrastructure_risk_model_diagram/node', { params: query }).pipe(
        map((node: any) => node)
      );
  }

}
