<div *ngIf="isLoading">
  <mat-progress-bar mode="query"></mat-progress-bar>
  <!-- {{ 'common.LoadingRiskRestrictionTypes' | translate | uppercase }} -->
</div>
<mat-form-field *ngIf="!isLoading && filteredList" appearance="outline" fxFlex>
  <mat-label>
    {{ 'common.riskRestrictionType' | translate | uppercase }}
  </mat-label>

  <mat-select [formControl]="formControl" (selectionChange)="onSelectChanged($event)" [multiple]="false">
    <mat-select-trigger>
      <div class="restriction-name">{{ formControl.value?.name }}</div>
      <div class="restriction-description" [matTooltip]="formControl.value?.description">
        {{ formControl.value?.description }}
      </div>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [formControl]="filterCtrl" (toggleAll)="toggleSelectAll($event)"
        showToggleAllCheckbox="true" toggleAllCheckboxTooltipMessage="{{ 'common.selectAll' | translate | uppercase }}"
        placeholderLabel="{{ 'common.search' | translate | uppercase }}"
        noEntriesFoundLabel="{{ 'common.itemNotFound' | translate | uppercase }}">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredList" [value]="item">
      <div *ngIf="item.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
        <div class="restriction-name">{{ item.name }}</div>
        <div class="restriction-description" [matTooltip]="formControl.value?.description">
          <!-- {{ item.description }} -->
          {{ item.name | restrictionTypeTranslatedDescription }}
        </div>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
