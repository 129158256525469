import { Component, Input, OnInit } from '@angular/core';
import { ProcessStatus } from '@shared/models/process-status.enum';

@Component({
  selector: 'app-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss'],
})
export class ProcessStatusComponent implements OnInit {
  @Input() status: ProcessStatus;

  ProcessStatus = ProcessStatus;

  constructor() {}

  ngOnInit(): void {}

  getStatusCssClass(status: ProcessStatus): string {
    return `status-${status.toLocaleLowerCase()}`;
  }
}
