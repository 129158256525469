<mat-card class="item-summary" fxFlex>
  <mat-card-content fxFlex>
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="matIcon">
        <mat-icon aria-label="refresh">{{ matIcon }}</mat-icon>
      </div>
      <div fxFlex></div>
      <div fxLayout="column" fxLayoutGap="0.2rem">
        <span class="summary-title">{{ label | translate | uppercase }}</span>
        <span class="summary-count">{{ count }}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
