import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor() { }

  openWebsite() {
    window.open('http://www.ipresas.com');
  }

  openRedES() {
    window.open('https://www.red.es/es');
  }
}
