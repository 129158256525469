import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from '@app/@core';
import { ConfirmDialogData } from '@app/@shared/components/confirm-dialog/confirm-dialog.component';
import { ProcessStatus } from '@app/@shared/models/process-status.enum';
import { Report } from '@app/@shared/models/reports/report';
import { ReportsService } from '@app/@shared/models/reports/reports.service';
import { NotificationService } from '@app/@shared/services/notification.service';
import { AppConstants } from '@app/app-constants';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-report-list-table-actions',
  templateUrl: './report-list-table-actions.component.html',
  styleUrls: ['./report-list-table-actions.component.scss'],
})
export class ReportListTableActionsComponent implements OnInit {
  @Input() report: Report;

  @Output() previewReportButtonClicked = new EventEmitter<Report>();
  @Output() downloadReportButtonClicked = new EventEmitter<Report>();
  @Output() reportDeleted = new EventEmitter<number>();

  isDeleting = false;
  log = new Logger(ReportListTableActionsComponent.name);

  ProcessStatus = ProcessStatus;
  constructor(private reportsService: ReportsService, private notificationService: NotificationService) {}

  ngOnInit(): void {}

  isDeleteReportButtonDisabled(): boolean {
    const now = moment(new Date()); //todays date

    const start = new Date(
      this.report.dt_process_start.endsWith('Z') ? this.report.dt_process_start : this.report.dt_process_start + 'Z'
    );
    const duration = moment.duration(now.diff(start));

    const duractionAsHours = duration.asHours();

    return (
      this.isDeleting ||
      (this.report.status === ProcessStatus.Processing &&
        duractionAsHours < AppConstants.REPORT_MAX_PROCCESING_DURACTION_AT_HOURS)
    );
  }

  onDeleteReportButtonClicked(): void {
    this.notificationService
      .openConfirmDialog({
        title: 'dashboard.DeleteReport',
        description: 'common.areYouSure',
      } as ConfirmDialogData)
      .subscribe((confirm) => {
        if (confirm) {
          this.deleteReport();
        } else {
          this.log.info('Dialog rejected');
        }
      });
  }

  private deleteReport(): void {
    this.isDeleting = true;
    const reportId = this.report.id;
    this.reportsService
      .deleteReportById(reportId)
      .pipe(finalize(() => (this.isDeleting = false)))
      .subscribe({
        next: () => {
          this.log.info('Report deleted successful');
          this.notificationService.open('dashboard.ReportDeletedSuccessful');
          this.reportDeleted.emit(reportId);
        },
        error: (error) => {
          this.log.error('Error deleting report', error);
          this.notificationService.open('dashboard.ErrorDeletingReport', 'error');
        },
      });
  }
}
