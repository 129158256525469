import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from '@app/@shared/models/users/customers/customer.interface';
import { SharedDataService } from '@app/@shared/services/shared-data.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerSelectionDialogComponent } from './customer-selection-dialog/customer-selection-dialog.component';

@Component({
  selector: 'app-selected-customer',
  templateUrl: './selected-customer.component.html',
  styleUrls: ['./selected-customer.component.scss'],
})
export class SelectedCustomerComponent implements OnInit, OnDestroy {
  selectedCustomer: Customer;

  private _onDestroy = new Subject();
  $isLoadingSelectedCustomer: Observable<boolean>;

  constructor(private sharedDataService: SharedDataService, private dialog: MatDialog, private router: Router) {}

  ngOnInit(): void {
    this.$isLoadingSelectedCustomer = this.sharedDataService.isLoadingSelectedCustomer();

    this.sharedDataService
      .getSelectedCustomer()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((selectedCustomer) => {
        this.selectedCustomer = selectedCustomer;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  onChangeCustomerButtonClick(): void {
    const dialogRef = this.dialog.open(CustomerSelectionDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  // onViewCustomerButtonClick(): void {
  //   this.router.navigate(['/users', 'view', this.selectedCustomer.user_login]);
  // }
}
