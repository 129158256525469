import { Injectable } from '@angular/core';

import { IPermissionInput } from '../models/permission-input.interface';
import { UserService } from './user.service';

@Injectable()
export class PermissionService {
  constructor(private userService: UserService) {}

  getPermission(permissionValue: IPermissionInput): Promise<boolean> {
    return this.userService.permissions().then((permissions) => {
      const values = permissionValue.permissions;
      if (permissionValue.operator && permissionValue.operator === 'or') {
        return values.some((value) => permissions.includes(value));
      } else {
        return values.every((value) => permissions.includes(value));
      }
    });
  }
}
