import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { InfrastructureTypeEnum } from '@shared/models/infrastructure-type/infrastructure-type.enum';

@Component({
  selector: 'app-specific-information-form',
  templateUrl: './specific-information-form.component.html',
  styleUrls: ['./specific-information-form.component.scss'],
})
export class SpecificInformationFormComponent implements OnInit {
  private _infrastructure: Infrastructure2;
  public get infrastructure(): Infrastructure2 {
    return this._infrastructure;
  }
  @Input() public set infrastructure(infrastructure: Infrastructure2) {
    this._infrastructure = infrastructure;
    this.infrastructureTypeId = this.infrastructure.infrastructure_type_id;
  }

  private _parentForm: FormGroup;
  @Input() set parentForm(parentForm: FormGroup) {
    this._parentForm = parentForm;
  }
  get parentForm(): FormGroup {
    return this._parentForm;
  }

  infrastructureTypeId: InfrastructureTypeEnum;
  InfrastructureTypeEnum = InfrastructureTypeEnum;

  constructor() {}

  ngOnInit(): void {}
}
