import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';


@Injectable()
export class PaginatorIntl {
  itemsPerPageLabel = '';
  nextPageLabel = '';
  previousPageLabel = '';
  changes: Subject<void>;

  constructor(private translate: TranslateService) {
    this.itemsPerPageLabel = this.translate.instant('common.Paginator.Rows per page');
    this.nextPageLabel = this.translate.instant('common.Paginator.Next');
    this.previousPageLabel = this.translate.instant('common.Paginator.Previous');
    this.changes = new Subject<void>();
  }


  getRangeLabel(page: number, pageSize: number, length: number): string {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return this.translate.instant('common.Paginator.Range', {from: startIndex + 1, to: endIndex, total: length});
  }
}
