import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { CacheInterceptor } from './http/cache.interceptor';
import { LoaderInterceptor } from './http/loader.interceptor';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { TokenInterceptor } from './http/token.interceptor';

import { I18nService } from './services/i18n.service';
import { LoaderService } from './services/loader.service';
import { ApiService } from './services/api.service';
import { HttpCacheService } from './http/http-cache.service';

import { HttpService } from './http/http.service';


@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    },
    ApiService,
    I18nService,
    LoaderService,
    HttpCacheService,
    LoaderInterceptor,
    CacheInterceptor,
    TokenInterceptor,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
