import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Logger } from '@app/@core';
import { InfrastructureNotificationRequest } from '@app/@shared/models/infrastructure-notification-requests/infrastructure-notification-request';
import { InfrastructureNotificationRequestsService } from '@app/@shared/models/infrastructure-notification-requests/infrastructure-notification-requests.service';
import { FindInfrasctructureNotificationRequestParam } from '@app/@shared/models/infrastructure-notification-requests/params/find-infrastructure-notification-request.param';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { AppConstants } from '@app/app-constants';
import { Subject } from 'rxjs';
import { InfrastructureNotificationRequestCreationDialogComponent } from './infrastructure-notification-request-creation-dialog/infrastructure-notification-request-creation-dialog.component';

const log = new Logger('InfrastructureNotificationRequestListComponent');

@Component({
  selector: 'app-infrastructure-notification-request-list[infrastructure]',
  templateUrl: './infrastructure-notification-request-list.component.html',
  styleUrls: ['./infrastructure-notification-request-list.component.scss'],
})
export class InfrastructureNotificationRequestListComponent implements OnInit {
  private _infrastructure: Infrastructure2;
  public get infrastructure(): Infrastructure2 {
    return this._infrastructure;
  }
  @Input() public set infrastructure(infrastructure: Infrastructure2) {
    this._infrastructure = infrastructure;

    if (infrastructure?.id) {
      this.loadData();
    }
  }

  isLoading = false;
  list?: InfrastructureNotificationRequest[];

  page = 0;
  pageSize = AppConstants.DEFAULT_PAGE_SIZE;
  pageSizeOptions = AppConstants.DEFAULT_PAGE_SIZE_OPTIONS;
  total: number;

  private _onDestroy = new Subject();

  constructor(
    private infrastructureNotificationRequestsService: InfrastructureNotificationRequestsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  loadData(): void {
    this.isLoading = true;

    const param = new FindInfrasctructureNotificationRequestParam({
      infrastructureId: this.infrastructure.id,
      page: this.page,
      pageSize: this.pageSize,
    });

    this.infrastructureNotificationRequestsService.findInfrastructureNotificationRequests(param).subscribe({
      next: (result) => {
        this.page = result.page;
        this.pageSize = result.pageSize;
        this.list = result.items;
        this.total = result.total;

        this.isLoading = false;
      },
      error: (error) => {
        log.error(error);
        this.isLoading = false;
      },
    });
  }

  onPageEvent(event: PageEvent): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  onCreateButtonClicked(): void {
    const infrastructure = this.infrastructure;

    const dialogRef = this.dialog.open(InfrastructureNotificationRequestCreationDialogComponent, {
      data: { infrastructure },
    });

    dialogRef.afterClosed().subscribe((newInfrastructureNotificationRequestId: number) => {
      // if (this.failureList == null) {
      //   this.failureList = [];
      // }

      if (newInfrastructureNotificationRequestId) {
        this.loadData();
      }
    });
  }
}
