<div fxFlex>
  <mat-sidenav-container fxFill>
    <mat-sidenav #sidenav mode="over" ngClass.gt-sm="has-border" class="mat-sidenav-background">
      <app-menu [views]="views" (openLinkEvent)="openLink($event)">
      </app-menu>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column">
      <app-header [title]="title" [username]="username" [roleId]="roleId" (toggleSidenavEvent)="toggleSidenav()"
        (updatePasswordEvent)="updatePassword()" (logoutEvent)="logout()">
      </app-header>
      <div class="mat-container">
        <router-outlet></router-outlet>
      </div>
      <div fxFlex></div>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
