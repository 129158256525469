import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { environment } from '@env/environment';
import { MismatchValidator } from '@shared/validators/mismatch-validator';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent {

  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<any>) {
      this.createForm();
  }

  newPassword() {
    const formValues = this.form.value;
    this.dialogRef.close(formValues.newPassword);
  }

  private createForm() {
    const pattern = environment.passwordPattern;
    this.form = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern(pattern)]],
      confirmPassword: ['', Validators.required]
    });

    this.form.get('confirmPassword').setValidators([
      Validators.required,
      MismatchValidator.mismatch(this.form.get('newPassword'))
    ]);
  }
}
