import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  authState$: Subject<boolean>;
  private userPool: any = null;

  constructor() {
    const poolData = {
      UserPoolId: environment.cognito.UserPoolId,
      ClientId: environment.cognito.ClientId,
      Paranoia: 7,
    };
    this.userPool = new CognitoUserPool(poolData);
    this.authState$ = new BehaviorSubject<boolean>(this.userPool.getCurrentUser() !== null);
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(username: string, password: string): Promise<{}> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result: any) => {
          this.authState$.next(true);
          resolve();
        },
        onFailure: (err) =>
          reject({
            reason: 'error',
            err: err,
          }),
        newPasswordRequired: (userAttributes, requiredAttributes) =>
          reject({
            reason: 'newPasswordRequired',
            cognitoUser: cognitoUser,
            userAttributes: userAttributes,
            requiredAttributes: requiredAttributes,
          }),
        mfaRequired: (details) =>
          reject({
            reason: 'mfaRequired',
            cognitoUser: cognitoUser,
            details: details,
          }),
      });
    });
  }

  completeNewPasswordChallenge(cognitoUser: CognitoUser, newPassword: string, requiredAttributeData: any): Promise<{}> {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributeData, {
        onSuccess: (result: any) => {
          this.authState$.next(true);
          resolve();
        },
        onFailure: (err) =>
          reject({
            reason: 'error',
            err: err,
          }),
        mfaRequired: (details) =>
          reject({
            reason: 'mfaRequired',
            details: details,
          }),
        customChallenge: (details) => reject({ reason: 'customChallenge', details: details }),
      });
    });
  }

  changePassword(oldPassword: string, newPassword: string): Promise<{}> {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser === null) {
      return Promise.reject(new Error('No current session'));
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession(() => {
        cognitoUser.changePassword(oldPassword, newPassword, (err) => {
          if (err) {
            reject({ reason: 'error', err: err });
          } else {
            resolve();
          }
        });
      });
    });
  }

  forgotPassword(username: string): Promise<any> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => reject({ reason: 'error', err: err }),
        inputVerificationCode: (data) => resolve(data),
      });
    });
  }

  confirmPassword(username: string, verificationCode: string, newPassword: string): Promise<{}> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => reject({ reason: 'error', err: err }),
      });
    });
  }
}
