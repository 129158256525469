import { HttpParams } from '@angular/common/http';
import { PaginatedParam } from '../paginated.param';

export class FindReportsParam extends PaginatedParam {
  query?: string;
  infrastructureId?: number;
  priorizationId?: number;
  startCreateDate?: Date;
  endCreateDate?: Date;

  toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.query != null) {
      params = params.set('_filter_dt_process_start', this.query.toString());
    }

    if (this.infrastructureId != null) {
      params = params.set('_filter_infrastructure_id__exact', this.infrastructureId.toString());
    } else {
      params = params.set('_filter_infrastructure_id__isnull', '');
    }

    if (this.priorizationId != null) {
      params = params.set('_filter_priorization_operation_id__exact', this.priorizationId.toString());
    }

    if (this.startCreateDate != null) {
      params = params.set('_filter_created_on__gt', this.startCreateDate.toISOString());
    }

    if (this.endCreateDate != null) {
      params = params.set('_filter_created_on__lt', this.endCreateDate.toISOString());
    }

    return params;
  }
}
