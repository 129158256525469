import { ConfirmComponent } from './confirm/confirm.component';
import { NotificationComponent } from './notification/notification.component';
import { MessageComponent } from './message/message.component';

import { DialogsService } from './services/dialogs.service';

export const DIALOG_COMPONENTS = [
  ConfirmComponent,
  MessageComponent,
  NotificationComponent
]

export const DIALOG_SERVICE = [
  DialogsService
]
