<h1 mat-dialog-title class="center-align">
  {{ 'common.infrastructureNotificationRequestCreation' | translate | uppercase }}
</h1>

<div mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="0.5rem">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'common.reason' | translate | uppercase }}</mat-label>
      <input [formControl]="form.controls.reason" matInput>
      <mat-error *ngIf="form.controls.reason.hasError('required')">
        {{ 'common.thisFieldIsRequired' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'common.description' | translate | uppercase }}</mat-label>
      <textarea [formControl]="form.controls.description" rows="4" matInput></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'common.links' | translate | uppercase }}</mat-label>
      <textarea [formControl]="form.controls.links" rows="4" matInput></textarea>
    </mat-form-field>

  </div>

</div>
<!-- <div fxFlex>
  {{ formControl.value | json }}
</div> -->

<div mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
  <button mat-raised-button mat-dialog-close fxFlex>
    <mat-icon>cancel</mat-icon>
    {{'common.cancel' | translate | uppercase}}
  </button>

  <button [disabled]="form.invalid || isCreating" (click)="onSaveButtonClicked()" color="primary" mat-raised-button
    fxFlex>
    <mat-icon>save</mat-icon>
    {{'common.save' | translate | uppercase}}
  </button>



</div>
