import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { Page } from '../../models/page.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input()
  set page(page: number) {
    this.paginator.pageIndex = page;
  }

  @Input()
  set size(size: number) {
    this.paginator.pageSize = size;
  }

  @Input()
  set total(total: number) {
     this.paginator.length = total;
  }

  @Output() pageEvent: EventEmitter<Page> = new EventEmitter<Page>();

  paginator = { length: 0, pageIndex: 0, pageSize: 10, pageSizeOptions: [10, 25, 50] };

  constructor() { }

  onPage(pageEvent: PageEvent) {
    const page: Page = new Page(pageEvent.pageIndex, pageEvent.pageSize);

    this.pageEvent.emit(page);
  }
}
