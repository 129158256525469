import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  IterableDiffers,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  DefaultNodeComponent,
  MODEL,
  RxZuDiagramComponent,
  EngineService,
} from '@rxzu/angular';
import { BehaviorSubject } from 'rxjs';
import { RiskManagementNodeModel } from '../../models';

@Component({
  selector: 'rxzu-risk-management-node',
  templateUrl: './risk-management-node.component.html',
  styleUrls: ['./risk-management-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskManagementNodeComponent
  extends DefaultNodeComponent
  implements OnInit {
  @ViewChild('portsLayer', { read: ViewContainerRef, static: true })
  portsLayer!: ViewContainerRef;

  nodeColor = 'white';
  boxShadow$: BehaviorSubject<string>;

  constructor(
    @Inject(MODEL) public model: RiskManagementNodeModel,
    engine: EngineService,
    diagram: RxZuDiagramComponent,
    iterableDiffers: IterableDiffers
  ) {
    super(model, engine, diagram, iterableDiffers);
    this.boxShadow$ = new BehaviorSubject('0px 4px 12px rgba(0, 0, 0, 0.25)');

    model.selectExtras().subscribe((extras: any) => {
      if (extras.color) {
        this.nodeColor = this.createNodeColor(extras.color);
      }
    });

  }

  updateLinksState(selected: boolean) {
    this.model.getPorts().forEach((port) => {
      port.getLinks().forEach((link) => {
        link.setSelected(selected);
      });
    });
  }

  createNodeColor(color: string) {
    return `${color}`
  }
}
