<h2 mat-dialog-title class="center-align">
  {{ 'common.createInfrastructure' | translate | uppercase }}
</h2>
<div class="mat-typography" fxLayout="column" fxFlex>
  <app-infrastructure-form [customerId]="customerId" [parentForm]="form" [mode]="mode"> </app-infrastructure-form>
</div>

<mat-dialog-actions fxLayout="column" fxLayoutAlign="start">
  <app-default-form-actions [createButtonDisabled]="form.invalid" [mode]="mode"
    (createButtonClicked)="onCreateButtonClicked()" (cancelButtonClicked)="onCancelButtonClicked()" fxFlex>
  </app-default-form-actions>
</mat-dialog-actions>
