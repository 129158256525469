<div fxLayout="column" fxFlex fxLayoutAlign="center center" class="report-detail">
  <span *ngIf="!iframeUrl" class="mat-h3">
    {{ 'common.noReportSelectedForPreview' | translate | uppercase }}
  </span>

  <iframe
    *ngIf="iframeUrl"
    [src]="iframeUrl"
    fxFlex
    fxFill
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    role="document"
    aria-label="Doc document"
    title="Doc document"
  >
  </iframe>
</div>
