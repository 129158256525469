import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ReportListFilterValue {
  startDate?: Date;
  endDate?: Date;
}

enum DateInputMode {
  // Clear=null,
  LastWeek,
  LastMonth,
  LastYear,
  CustomRange,
}

@Component({
  selector: 'app-report-list-filter',
  templateUrl: './report-list-filter.component.html',
  styleUrls: ['./report-list-filter.component.scss'],
})
export class ReportListFilterComponent implements OnInit, OnDestroy {
  dateInputModeControl = this.fb.control(null);
  form = this.fb.group({
    startDate: [null],
    endDate: [null],
  });

  @Output() filterValueChanges = new EventEmitter<ReportListFilterValue>();
  readonly DateInputMode = DateInputMode;
  readonly dateInputModeList = Object.values(DateInputMode)
    .filter((value) => typeof value === 'number')
    .map((value) => ({ key: Number(value), value: DateInputMode[value] })) as Array<{ key: number; value: string }>;

  private _onDestroy = new Subject();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((input: ReportListFilterValue) => {
      if (input.endDate != null && input.startDate != null) {
        this.dateInputModeControl.setValue(DateInputMode.CustomRange);
      }

      this.filterValueChanges.emit(input);
    });
  }

  ngOnDestroy(): void {
    this._onDestroy;
  }

  onDateInputModeChange(dateInputMode: DateInputMode) {
    const newValue = {} as ReportListFilterValue;
    const today = moment().startOf('day');

    switch (dateInputMode) {
      case null:
        newValue.startDate = null;
        newValue.endDate = null;
        break;

      case DateInputMode.LastWeek:
        newValue.startDate = today.subtract(1, 'week').toDate();
        newValue.endDate = null;
        break;

      case DateInputMode.LastMonth:
        newValue.startDate = today.subtract(1, 'month').toDate();
        newValue.endDate = null;
        break;

      case DateInputMode.LastYear:
        newValue.startDate = today.subtract(1, 'year').toDate();
        newValue.endDate = null;
        break;

      case DateInputMode.CustomRange:
        break;
    }

    this.form.patchValue(newValue);
  }
}
