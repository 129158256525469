import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserRoleEnum } from '@shared/models/users/users/user-role.enum';

import { UserService } from '@app/@shared/services/user.service';

@Injectable()
export class HomeRedirectorGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.userService.roleId().then((roleId) => {
      const defaultPathByRoleId = [
        '', // All (roleId 0)
        'dashboard', // 1 Customer (roleId 1)
        'dashboard', // 2 Engineer (roleId 2)
        'users', // Manager (roleId 3)
      ];
      if (!roleId) {
        return;
      }

      const newPath = defaultPathByRoleId[roleId];
      // const newPath = (roleId === UserRoleEnum.Engineer || roleId === UserRoleEnum.Engineer) ? 'customers' : 'infrastructures'
      this.router.navigate([newPath]);
      return false;
    });
  }
}
