<h1 mat-dialog-title translate style="text-align: center">{{ title | translate: titleParams | uppercase }}</h1>
<div mat-dialog-content fxLayout="column" fxLayoutAlign="start start">
  <div class="mat-typography" [innerText]="description | translate: descriptionParams"></div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem" fxFlexOffset="2rem">
  <button (click)="onNoButtonClicked()" mat-raised-button mat-dialog-close fxFlex>
    <mat-icon> close </mat-icon>
    {{ 'common.no' | translate | uppercase }}
  </button>
  <button (click)="onYesButtonClicked()" mat-raised-button cdkFocusInitial color="primary" fxFlex>
    <mat-icon> done </mat-icon>
    {{ 'common.yes' | translate | uppercase }}
  </button>
</div>
