import { HttpParams } from '@angular/common/http';

export class PaginatedParam {
  page?: number;
  pageSize?: number;
  orderByField?: string;
  orderByAsc?: boolean;

  constructor(init?: Partial<PaginatedParam>) {
    Object.assign(this, init);
  }

  toHttpParams(): HttpParams {
    let params = new HttpParams();

    if (this.pageSize != null) {
      params = params.set('_page_size', this.pageSize.toString());
    }

    if (this.page != null) {
      params = params.set('_page', this.page.toString());
    }

    if (this.orderByField != null) {
      const prefix = this.orderByAsc === false ? '-' : '';
      params = params.set('_order_by', prefix + this.orderByField);
    }
    return params;
  }
}
