import { PaginatedResult } from './../paginated-result.interface';
import { RiskRestrictionType } from './risk-restriction-type';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RiskRestrictionTypesService {

  constructor(private http: HttpClient) { }

  getAllRiskRestrictionTypes(): Observable<RiskRestrictionType[]> {
    const url = '/infrastructure_risk_restrictions_types';
    const params = new HttpParams().set('_page_size', '0');

    return this.http.get(url, { params }).pipe(map(
      (result: PaginatedResult<RiskRestrictionType>) => result.items
    ));
  }
}
