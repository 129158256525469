import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';

import {
  IConfirmConfig,
  IMessageConfig,
  INotificationConfig,
  ISnackBarConfig
} from '../models/dialogs.interfaces';
import { ConfirmComponent } from '../confirm/confirm.component';
import { NotificationComponent } from '../notification/notification.component';
import { MessageComponent } from '../message/message.component';


@Injectable()
export class DialogsService {

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  openConfirm(data: IConfirmConfig): Observable<boolean> {
    const dialog = this.dialog.open(ConfirmComponent, { data: data });
    return dialog.afterClosed();
  }

  openNotification(data: INotificationConfig): MatDialogRef<NotificationComponent> {
    return this.dialog.open(NotificationComponent, { data: data });
  }

  openSnackBar(data: ISnackBarConfig): Observable<MatSnackBarDismiss> {
    const message = data.message;
    const acceptButton = data.acceptButton || 'common.ok';
    const duration = data.duration || 2000;
    const horizontalPosition = data.horizontalPosition || 'center';
    const verticalPosition = data.verticalPosition || 'bottom';

    const translatedMessage = this.translate.instant(message);
    const translatedAcceptButton = this.translate.instant(acceptButton);
    const snackbar = this.snackBar.open(translatedMessage, translatedAcceptButton, {
      duration: duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: 'snackbar'
    });

    return snackbar.afterDismissed();
  }

  openMessage(data: IMessageConfig) {
    this.dialog.open(MessageComponent, { data: data });
  }

  open<T>(component: ComponentType<T>, config?: MatDialogConfig): Observable<any> {
    const dialog = this.dialog.open(component, config);
    return dialog.afterClosed();
  }
}
