import { AuthenticationService } from './authentication.service';
import { ContactService } from './contact.service';
import { NotificationService } from './notification.service';
import { PermissionService } from './permission.service';
import { TokenService } from './token.service';
import { UserService } from './user.service';

export const SERVICES = [
  PermissionService,
  AuthenticationService,
  ContactService,
  TokenService,
  UserService,
  NotificationService,
];
