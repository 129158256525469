export enum FailureModeGradeEnum {
  remote = 'remote',
  remote_low = 'remote_low',
  low = 'low',
  low_medium = 'low_medium',
  medium = 'medium',
  medium_hight = 'medium_hight',
  hight = 'hight',
  hight_extra_hight = 'hight_extra_hight',
  extra_hight = 'hight_extra_hight',
}

