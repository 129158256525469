<h2 mat-dialog-title>{{ 'login.Recover password' | translate | uppercase }}</h2>
<form [formGroup]="recoverPasswordForm" novalidate>
  <mat-dialog-content class="mat-typography">
    <div fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login.Username' | translate | uppercase }}</mat-label>
        <input matInput required type="text" name="username" formControlName="username"
          placeholder="{{ 'login.Username'  | translate | uppercase }}"/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login.Verification code' | translate | uppercase }}</mat-label>
        <input matInput required type="text" name="verificationCode" formControlName="verificationCode"
          placeholder="{{ 'login.Verification code'  | translate | uppercase }}"/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login.New password' | translate | uppercase }}</mat-label>
        <input matInput required type="password" name="newPassword" formControlName="newPassword"
          placeholder="{{ 'login.New password'  | translate | uppercase }}"/>
        <mat-error
          *ngIf="recoverPasswordForm.controls.newPassword.invalid && recoverPasswordForm.controls.newPassword.touched">
          <span>{{ 'common.passwordRequeriments' | translate | uppercase }}</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login.Confirm password' | translate | uppercase }}</mat-label>
        <input matInput required type="password" name="confirmPassword" formControlName="confirmPassword"
          placeholder="{{ 'login.Confirm password'  | translate | uppercase }}"/>
        <mat-error
          *ngIf="recoverPasswordForm.controls.confirmPassword.invalid && recoverPasswordForm.controls.confirmPassword.touched">
          <span>{{ 'login.Passwords do not mismatch' | translate | uppercase }}</span>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button type="button" color="primary" id="send-verification-code"
      [disabled]="recoverPasswordForm.controls.username.invalid" (click)="onSendCode()" fxFlex>
      {{ 'login.Send verification code' | translate | uppercase }}
    </button>
    <button mat-raised-button type="button" color="primary" id="reseat-password" (click)="onVerify()"
      [disabled]="isDisabled" fxFlex>
      {{ 'login.Recover password' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
  <br>
</form>
<br>
