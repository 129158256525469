import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DefaultPortComponent, MODEL } from '@rxzu/angular';
import { RiskManagementPortModel } from '../../models';

@Component({
  selector: 'rxzu-risk-management-port',
  templateUrl: './risk-management-port.component.html',
  styleUrls: ['./risk-management-port.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskManagementPortComponent
  extends DefaultPortComponent
  implements OnInit {
  constructor(
    @Inject(MODEL) public model: RiskManagementPortModel,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {
    super(model);
  }

  ngOnInit() {
    super.ngOnInit();
    this.updatePortRootStyle();
  }

  updatePortRootStyle() {
    const rootEl = this.elRef.nativeElement;
    this.renderer.addClass(rootEl, this.model.direction$.value);
  }

}
