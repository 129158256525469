import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Help } from './help';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(private http: HttpClient) { }

  getHelp(document: string): Observable<Help> {
    const url = '/help';

    let params = new HttpParams();
    params = params.append('document', document);

    return this.http.get<Help>(url, { params });
  }
}
