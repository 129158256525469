import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { I18nService, untilDestroyed } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { interval, merge, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { UserRoleEnum } from './@shared/models/users/users/user-role.enum';
import { AuthenticationService } from './@shared/services/authentication.service';
import { TokenService } from './@shared/services/token.service';
import { UserService } from './@shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  subs: Subscription;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private dialogService: DialogsService,
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService,
    private ngxRolesService: NgxRolesService,
    private authenticationService: AuthenticationService, // private usersService: UsersService
    private tokenService: TokenService
  ) {
    this.updateNgxPermissions();
    // Setup translations
    this.i18nService.init();

    this.updateClient();
    this.checkUpdate();
    this.userService.roleId().then((roleId) => {
      const roleName = UserRoleEnum[roleId];
      this.ngxRolesService.addRole(roleName, []);
    });
  }

  ngOnInit() {
    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
    this.subs.unsubscribe();
  }

  updateClient() {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.available.subscribe((event) => {
      console.log('version_current', event.current, 'version_available', event.available);

      this.dialogService
        .openSnackBar({
          message: 'app.Update available for the app',
          acceptButton: 'common.refresh',
          duration: 24 * 60 * 60 * 1000,
          horizontalPosition: 'start',
          verticalPosition: 'top',
        })
        .subscribe(() => location.reload());
    });

    this.swUpdate.activated.subscribe((event) => {
      console.log('version_previous', event.previous, 'version_available', event.current);
    });
  }

  checkUpdate() {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        // const timeInterval = interval(8 * 60 * 60 * 1000);
        const timeInterval = interval(2 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          this.swUpdate.checkForUpdate().then(() => console.log('version_checked'));
        });
      }
    });
  }

  private async updateNgxPermissions(): Promise<void> {
    this.subs = this.authenticationService.authState$.subscribe(async (isAuth) => {
      console.log('isAuth', isAuth);
      if (isAuth) {
        const payload = await this.tokenService.getPayload();
        const roleId = Number(payload['custom:role_id']);
        const roleName = UserRoleEnum[roleId];
        this.ngxRolesService.addRole(roleName, []);
        this.ngxRolesService.addRole(roleName, []);
      } else {
        this.ngxPermissionsService.flushPermissions();
        this.ngxRolesService.flushRoles();
      }
    });
  }
}
