import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-default-row-actions',
  templateUrl: './default-row-actions.component.html',
  styleUrls: ['./default-row-actions.component.scss']
})
export class DefaultRowActionsComponent implements OnInit {
  // @Input() user: User2;

  @Output() viewButtonClicked = new EventEmitter();
  @Output() editButtonClicked = new EventEmitter();
  @Output() deleteButtonClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
