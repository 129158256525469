import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@app/@core';
import { ConfirmDialogData } from '@app/@shared/components/confirm-dialog/confirm-dialog.component';
import { ProcessStatus } from '@app/@shared/models/process-status.enum';
import { RiskModel } from '@app/@shared/models/risk-models/risk-model';
import { RiskModelsService } from '@app/@shared/models/risk-models/risk-models.service';
import { NotificationService } from '@app/@shared/services/notification.service';
import { AppConstants } from '@app/app-constants';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-risk-model-actions[riskModel]',
  templateUrl: './risk-model-actions.component.html',
  styleUrls: ['./risk-model-actions.component.scss'],
})
export class RiskModelActionsComponent implements OnInit {
  @Input() riskModel: RiskModel;
  @Output() riskModelChanged = new EventEmitter<void>();
  // @Output() riskModelDeleted = new EventEmitter<number>();

  isDeleting = false;
  log = new Logger(RiskModelActionsComponent.name);
  ProcessStatus = ProcessStatus;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private riskModelsService: RiskModelsService,
    private notificationService: NotificationService // private dialogService: DialogsService
  ) {}

  ngOnInit(): void {}

  onDeleteRiskModelButtonClicked(): void {
    this.notificationService
      .openConfirmDialog({
        title: 'infrastructure.DeleteRiskModel',
        description: 'infrastructure.RiskModelDeleteNotice',
      } as ConfirmDialogData)
      .subscribe((confirm) => {
        if (confirm) {
          // this.riskModelDeleted.emit(this.riskModel.id);
          this.deleteRiskModel();
        }
      });
  }

  private deleteRiskModel(): void {
    this.isDeleting = true;
    const riskModelId = this.riskModel.id;

    this.riskModelsService
      .deleteRiskModelById(riskModelId)
      .pipe(finalize(() => (this.isDeleting = false)))
      .subscribe({
        next: () => {
          this.log.info('Risk model deleted successful');
          this.notificationService.open('infrastructure.RiskModelDeletedSuccessful');
          this.riskModelChanged.emit();
        },
        error: (error) => {
          this.log.error('Error deleting risk model', error);
          this.notificationService.open('infrastructure.ErrorDeletingRiskModel', 'error');
        },
      });
  }

  openDocumentationLink(): void {
    const url = this.riskModel.link_documentation;
    window.open(url, '_blank');
  }

  isDeleteButtonVisible(): boolean {
    const now = moment(new Date()); //todays date
    const duration = moment.duration(now.diff(this.riskModel.dt_process_start));

    const duractionAsHours = duration.asHours();

    return (
      (this.riskModel.status === ProcessStatus.Abort ||
        this.riskModel.status === ProcessStatus.Processed ||
        (this.riskModel.status === ProcessStatus.Processing &&
          duractionAsHours > AppConstants.RISK_MAX_MODEL_PROCCESING_DURACTION_AT_HOURS)) &&
      !this.riskModel.is_active
    );
  }

  onDiagramButtonClicked(): void {
    this.router.navigate(['risk-models', this.riskModel.id, 'diagram'], { relativeTo: this.route });
  }
}
