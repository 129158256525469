import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Logger } from '@app/@core';
import { CreateInfrastructureParam } from '@app/@shared/models/infrastructures/params/create-infrastructure-param';
import { ISnackBarConfig } from '@shared/dialogs/models/dialogs.interfaces';
import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { FormMode } from '@shared/FormMode';
import { InfrastructureService } from '@shared/models/infrastructures/infrastructure.service';

const log = new Logger('CreateInfrastructureDialogComponent');

@Component({
  selector: 'app-create-infrastructure-dialog',
  templateUrl: './create-infrastructure-dialog.component.html',
  styleUrls: ['./create-infrastructure-dialog.component.scss'],
})
export class CreateInfrastructureDialogComponent implements OnInit {
  form = new FormGroup({});
  mode = FormMode.Create;
  customerId?: number;
  isCreating = false;
  // infrastructureTypeList: InfrastructureType2[];

  constructor(
    private dialogsService: DialogsService,
    private dialogRef: MatDialogRef<CreateInfrastructureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customerId: number },
    private infrastructuresService: InfrastructureService
  ) {
    this.customerId = data.customerId;
  }

  ngOnInit() {}

  onCreateButtonClicked(): void {
    this.isCreating = true;
    const formValue = this.form.value.infrastructure;

    // const build_date_width_dd_mm_yyyy_format = moment(build_date).format('DD-MM-YYYY');

    const param = {
      ...formValue,
      // build_date: build_date_width_dd_mm_yyyy_format,
    } as CreateInfrastructureParam;

    this.infrastructuresService.createInfrastructure(param).subscribe({
      next: (result) => {
        this.isCreating = false;
        log.info(`Create infrastructure with id ${result?.id}`);
        this.dialogsService.openSnackBar({
          message: 'common.infrastructureCreateSuccess',
        } as ISnackBarConfig);
        this.dialogRef.close(result);
      },
      error: (error) => {
        this.isCreating = false;
        log.error(error);
        this.dialogsService.openSnackBar({
          message: 'common.infrastructureCreateError',
        } as ISnackBarConfig);
      },
    });
  }

  onCancelButtonClicked(): void {
    this.dialogRef.close();
  }
}
