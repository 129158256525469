import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @Input() isLoading = false;

  @Output() loginEvent: EventEmitter<{ username: string; password: string }> = new EventEmitter<{
    username: string;
    password: string;
  }>();
  @Output() recoverPasswordEvent: EventEmitter<void> = new EventEmitter();

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(private fb: FormBuilder) {}

  login() {
    const formValues = this.loginForm.value;
    this.loginEvent.emit({ username: formValues.username, password: formValues.password });
  }

  recoverPassword() {
    this.recoverPasswordEvent.emit();
  }
}
