import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Restriction } from '../models/restriction/restriction';

const RISK_RESTRICTION_TRANSLATION_ROOT = 'riskTypeDefinition';
const UNKNOWN_KEY = 'UNKNOWN';
const TWO_MEASURES_RESTRICTIONS_TYPES = ['ORDER', 'EXCL', 'ELIM', 'GROUP', 'RX', 'OMIT'];

@Pipe({
  name: 'baseRestrictionDefinition',
})
export class BaseRestrictionDefinitionPipe implements PipeTransform {
  static isAFixedYearImplementationRestriction(riskRestrictionType: string): boolean {
    // Check if riskRestriction is like AXXXX:
    //  -> 'A2022' returns true
    //  -> 'A123'  returns false
    //  -> 'A12345' returns false
    //  -> 'A123a' returns false
    //  -> 'a2022' returns false

    const er = /^A\d{4}$/;
    return er.test(riskRestrictionType);
  }

  static getFixedYearImplementationRestriction(riskRestrictionType: string): number {
    const yearAsString = riskRestrictionType.substring(1);
    const yearAsNumber = Number(yearAsString);

    return yearAsNumber;
  }

  constructor(private translateService: TranslateService) {}

  transform(riskRestriction: Restriction): string {
    return riskRestriction?.measures
      .map((measure) => {
        let result = measure?.risk_measure_name;
        const infrastructureName = measure?.infrastructure?.name;
        if (infrastructureName) {
          result = result + `(${infrastructureName})`;
        }
        return result;
      })
      .toString();

    // // TODO: Use this next code when we have definitions
    // let key: string;
    // let params: any;

    // const restrictionType = riskRestriction.restriction_type;

    // if (TWO_MEASURES_RESTRICTIONS_TYPES.includes(restrictionType)) {
    //   key = `${RISK_RESTRICTION_TRANSLATION_ROOT}.${restrictionType}`;
    //   params = {
    //     measureOne: riskRestriction.measures[0].risk_measure_name,
    //     measureTwo: riskRestriction.measures[1].risk_measure_name,
    //   };
    // } else {
    //   if (BaseRestrictionDefinitionPipe.isAFixedYearImplementationRestriction(restrictionType)) {
    //   } else {
    //     key = `${RISK_RESTRICTION_TRANSLATION_ROOT}.${UNKNOWN_KEY}`;
    //     params = {
    //       restrictionType: restrictionType,
    //     };
    //   }
    // }

    // const message = this.translateService.instant(key, params);
    // return message;
  }
}
