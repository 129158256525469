<div>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label> {{ title | translate | uppercase }}</mat-label>
    <mat-icon matPrefix color="primary">search</mat-icon>
    <input type="text" matInput [formControl]="formControl" [matAutocomplete]="auto" [required]="required">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="inputDisplay"
      (optionSelected)="onSelectItem($event.option.value)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.searchString | translate}}
      </mat-option>
    </mat-autocomplete>
    <button mat-icon-button [disabled]="!formControl.value || formControl.disabled" (click)="clear()" matSuffix
      color="primary">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</div>
