import { IPagination } from './pagination.interface';

export class Pagination implements IPagination {

    page: number;
    pageSize: number;
    total: number;

  constructor(public pagination: IPagination) {
    this.page = pagination.page;
    this.pageSize = pagination.pageSize;
    this.total = pagination.total;
  }

}