import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { Logger } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { ISnackBarConfig } from '@shared/dialogs/models/dialogs.interfaces';
import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { RiskModelsService } from '@shared/models/risk-models/risk-models.service';
import { UploadRiskModelParam } from '@app/@shared/models/risk-models/params/upload-risk-model.param';

const log = new Logger('AddRiskModelDialogComponent');

@Component({
  selector: 'app-add-risk-model-dialog',
  templateUrl: './add-risk-model-dialog.component.html',
  styleUrls: ['./add-risk-model-dialog.component.scss'],
})
export class AddRiskModelDialogComponent implements OnInit {
  isSaving = false;

  fileFormControl = this.fb.control(null);

  form = this.fb.group({
    file: [null, [Validators.required]],
    title: ['', [Validators.required]],
    comment: ['', [Validators.required]],
    initYear: [new Date().getFullYear(), [Validators.required]],
    endYear: [new Date().getFullYear(), [Validators.required]],
    link_documentation: [''],
    // link_documentation: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
    // measure_source_id: []
  });
  infrastructure: Infrastructure2;

  constructor(
    private riskModelsService: RiskModelsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddRiskModelDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      infrastructure: Infrastructure2;
    },
    private translateService: TranslateService,
    private dialogsService: DialogsService
  ) {
    this.infrastructure = data.infrastructure;
  }

  ngOnInit(): void {
    this.fileFormControl.valueChanges.subscribe((fileList: File[]) => {
      let file: File;
      if (fileList?.length) {
        file = fileList[0];
      } else {
        file = null;
      }
      this.form.get('file').setValue(file);
    });
  }

  onSaveButtonClicked() {
    this.isSaving = true;
    // const value = this.fileFormControl.get('file').value;
    const file = this.form.get('file').value as File;
    const file_name = file.name;
    const infrastructure_id = this.infrastructure.id;
    const title = this.form.get('title').value;
    const comment = this.form.get('comment').value;
    const init_year = this.form.get('initYear').value as number;
    const end_year = this.form.get('endYear').value as number;
    const link_documentation = this.form.get('link_documentation').value as string;

    const param = {
      file,
      file_name,
      infrastructure_id,
      title,
      comment,
      init_year,
      end_year,
      link_documentation,
    } as UploadRiskModelParam;

    this.riskModelsService.uploadRiskModelFile(param).subscribe(
      (data) => {
        this.dialogsService.openSnackBar({
          message: this.translateService.instant('common.riskModelAddedSuccessfully'),
          duration: 4000,
        } as ISnackBarConfig);

        this.dialogRef.close(data);
        this.isSaving = false;
      },
      (error) => {
        log.error('Error creating risk model', error);
        this.dialogsService.openSnackBar({
          message: this.translateService.instant('common.errorCreatingRiskModel'),
          duration: 4000,
        } as ISnackBarConfig);
        this.isSaving = false;
      }
    );
  }
}
