<h2 mat-dialog-title translate style="text-align: center">
  {{ 'common.customerSelection' | translate | uppercase }}
</h2>

<mat-dialog-content>
  <div fxLayout="column">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>
        {{ 'common.selectedCustomer' | translate | uppercase }}
      </mat-label>

      <mat-select [formControl]="control" [multiple]="false" [disabled]="isLoading">
        <mat-option>
          <ngx-mat-select-search
            [clearSearchInput]="false"
            [formControl]="filterCtrl"
            placeholderLabel="{{ 'common.searchCustomer' | translate | uppercase }}"
            noEntriesFoundLabel="{{ 'common.customerNotFound' | translate | uppercase }}"
          >
          </ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="null">
          {{ 'common.none' | translate | uppercase }}
        </mat-option>
        <mat-option *ngFor="let customer of list" [value]="customer.user_login">
          <div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="start center">
            <div style="height: 40px; width: 40px; line-height: 40px; background-color: white">
              <img
                style="max-height: 100%; max-width: 100%; margin: 0 auto; vertical-align: middle; display: inline-block"
                [src]="customer.signed_path_logo"
              />
            </div>

            <div>{{ customer?.name }} ( {{ customer?.user_login }} )</div>
          </div>

          <!-- <div *ngIf="customer.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start"> -->

          <!-- </div> -->
        </mat-option>

        <div *ngIf="itemsLeft">
          <a [disabled]="isLoading" mat-button (click)="viewMore()" class="center-align" fxFill>
            {{ 'common.itemsLeft' | translate: { itemsLeft: itemsLeft } | uppercase }}
          </a>
          <!-- <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar> -->
        </div>
      </mat-select>
    </mat-form-field>
    <div fxFlex fxLayoutGap="column" fxLayoutAlign="center center">
      <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
  <button mat-raised-button mat-dialog-close fxFlex>
    <mat-icon> close </mat-icon>
    {{ 'common.cancel' | translate | uppercase }}
  </button>

  <button (click)="onSelectCustomerButtonClicked()" mat-raised-button color="primary" fxFlex>
    <mat-icon> done </mat-icon>
    {{ 'common.select' | translate | uppercase }}
  </button>
</mat-dialog-actions>
