<ng-container [ngSwitch]="infrastructureTypeId">
  <ng-container *ngSwitchCase="InfrastructureTypeEnum.Dam">
    <app-dam-specific-information-form
      [parentForm]="parentForm"
      [specificInformation]="infrastructure.specific_information"
    ></app-dam-specific-information-form>
  </ng-container>

  <!-- <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplate"></ng-container> -->
</ng-container>
