import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { AppConstants } from '@app/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { RxZuDiagramComponent } from '@rxzu/angular';
import * as htmlToImage from 'html-to-image';
import { Options } from 'html-to-image';
import { Subscription } from 'rxjs';

export enum ImageFormat {
  Png = 'png',
  Jpg = 'jpg',
  Svg = 'svg',
}

enum DiagramFlowExportFields {
  FileName = 'file_name',
  Quality = 'quality',
  PixelRatio = 'pixel_ratio',
  Format = 'format',
}

export interface DiagramFlowExportDialogData {
  diagram: RxZuDiagramComponent;
  infrastructure: Infrastructure2;
}

@Component({
  selector: 'app-diagram-flow-export-dialog',
  templateUrl: './diagram-flow-export-dialog.component.html',
  styleUrls: ['./diagram-flow-export-dialog.component.scss'],
})
export class DiagramFlowExportDialogComponent implements OnInit, OnDestroy {
  form = this.fb.group({
    [DiagramFlowExportFields.FileName]: [AppConstants.DIAGRAM_FLOW_EXPORT_DEFAULT_FILE_NAME, [Validators.required]],
    [DiagramFlowExportFields.Quality]: [
      AppConstants.DIAGRAM_FLOW_EXPORT_DEFAULT_QUALITY,
      [Validators.required, Validators.min(0.1), Validators.max(1)],
    ],
    [DiagramFlowExportFields.PixelRatio]: [AppConstants.DIAGRAM_FLOW_EXPORT_DEFAULT_PIXEL_RATIO, [Validators.required]],
    [DiagramFlowExportFields.Format]: [ImageFormat.Png, [Validators.required]],
  });

  isExporting = false;

  DiagramFlowExportFields = DiagramFlowExportFields;
  ImageFormat = ImageFormat;

  pixelRatioOptionList = [1, 2, 3, 4, 5];
  subs: Subscription;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DiagramFlowExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DiagramFlowExportDialogData,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const infrastructureName = this.data.infrastructure.name;
    const translatedDiagramText = this.translateService.instant('diagram.Diagram') as string;
    const fileName = `${infrastructureName} ${translatedDiagramText}`.replace(' ', '_').toLowerCase();
    this.form.controls[DiagramFlowExportFields.FileName].setValue(fileName);
    this.activeAndDisableFields();
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  onExportButtonClicked() {
    this.isExporting = true;
    const formValue = this.form.value;

    const options = {
      pixelRatio: formValue[DiagramFlowExportFields.PixelRatio],
      quality: formValue[DiagramFlowExportFields.Quality],
      backgroundColor: '#ffffff',
    } as Options;

    const format = formValue[DiagramFlowExportFields.Format];
    const fileName = formValue[DiagramFlowExportFields.FileName];
    const domNode = this.data.diagram.canvas.nativeElement;

    // if (format === 'png') {
    // }
    // document.getElementById('flow-diagram')

    const method = format === 'png' ? htmlToImage.toPng : format === 'jpg' ? htmlToImage.toJpeg : htmlToImage.toSvg;

    method(domNode, options).then((dataUrl) => {
      this.isExporting = false;
      var link = document.createElement('a');
      link.download = `${fileName}.${format}`;
      link.href = dataUrl;
      link.click();
    });
  }

  onFormatChange(): void {
    this.activeAndDisableFields();
  }

  private activeAndDisableFields(): void {
    const imageFormat = this.form.controls[DiagramFlowExportFields.Format].value as ImageFormat;
    switch (imageFormat) {
      case ImageFormat.Jpg:
        this.form.controls[DiagramFlowExportFields.PixelRatio].enable();
        this.form.controls[DiagramFlowExportFields.Quality].enable();
        break;

      case ImageFormat.Png:
        this.form.controls[DiagramFlowExportFields.PixelRatio].enable();
        this.form.controls[DiagramFlowExportFields.Quality].disable();
        break;

      case ImageFormat.Svg:
        this.form.controls[DiagramFlowExportFields.PixelRatio].disable();
        this.form.controls[DiagramFlowExportFields.Quality].disable();
        break;
    }
  }
}
