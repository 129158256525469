export enum InfrastructureTypeEnum {
  Dam = 1,
  Seaport = 2,
  Pier = 3,
  Dike = 4,
  Via = 5,
  Bridge = 6,
  UrbanArea = 7,
  Infrastructure = 8,
}
