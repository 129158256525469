<mat-form-field fxFlex appearance="outline">
  <mat-label>
    {{ 'common.userRole' | translate | uppercase }}
  </mat-label>

  <mat-select [formControl]="formControl" (selectionChange)="onSelectChanged($event)" [multiple]="false">
    <mat-option>
      <ngx-mat-select-search [formControl]="filterCtrl" placeholderLabel="{{ 'common.search' | translate | uppercase }}"
        noEntriesFoundLabel="{{ 'common.itemNotFound' | translate | uppercase }}">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredList" [value]="item.id">
      <div *ngIf="item.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
        {{ 'Roles.' + item.name | translate }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
