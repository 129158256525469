<table mat-table [dataSource]="list" multiTemplateDataRows fxFlex>
  <ng-container matColumnDef="generationDate">
    <th mat-header-cell *matHeaderCellDef>
      <div class="center-align">
        {{ 'common.generationDate' | translate | uppercase }}
      </div>
    </th>
    <td mat-cell *matCellDef="let report">
      <span *ngIf="report.id !== lastGeneratedReport?.id">
        <!-- {{ report.dt_process_start | date: 'dd/MM/YY' }} -->
        {{ report.dt_process_start.substring(0, 10) }}
      </span>
      <span *ngIf="report.id === lastGeneratedReport?.id" class="primary-color">
        {{ 'common.addedNow' | translate | uppercase }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="processTime">
    <th mat-header-cell *matHeaderCellDef>
      <div class="center-align">
        {{ 'common.processTime' | translate | uppercase }}
      </div>
    </th>
    <td mat-cell *matCellDef="let report">
      {{ report | reportProcessTime }}
    </td>
  </ng-container>

  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef>
      <div class="center-align">
        {{ 'common.state' | translate | uppercase }}
      </div>
    </th>
    <td mat-cell *matCellDef="let report">
      <app-process-status [status]="report.status"></app-process-status>
      <!-- <mat-progr pinner *ngIf="report.status === ReportStatus.Processing" diameter="20"></mat-spinner> -->
      <!-- {{ report.status | translate | uppercase }} -->
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef width="150px">
      <div class="center-align">
        {{ 'common.actions' | translate | uppercase }}
      </div>
    </th>
    <td mat-cell *matCellDef="let report">
      <app-report-list-table-actions
        [report]="report"
        (previewReportButtonClicked)="onPreviewReportButtonClicked($event)"
        (downloadReportButtonClicked)="onDownloadReportButtonClicked($event)"
        (reportDeleted)="onReportDeleted($event)"
      ></app-report-list-table-actions>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    [ngClass]="row?.id === selectedReport?.id ? 'selected' : ''"
    *matRowDef="let row; columns: displayedColumns"
  ></tr>
</table>
