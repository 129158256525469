import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FailureModeGradeEnum } from '../models/infrastructures/evaluations/failure-modes/failure-mode-grade.enum';
import { Logger } from '@app/@core';

@Pipe({ name: 'translatedFailureModeGrade' })
export class TranslatedFailureModeGradePipe implements PipeTransform {
  log = new Logger(this.constructor.name);
  constructor(private translateService: TranslateService) {}
  transform(failureModeGrade: string): string {
    if (failureModeGrade == null) {
      return '';
    }
    const isKnownGrade = Object.values(FailureModeGradeEnum)
      .map((value) => value.toString())
      .includes(failureModeGrade);

    if (isKnownGrade) {
      return this.translateService.instant(`failureModeGrades.${failureModeGrade}`);
    } else {
      this.log.warn('Unknown Failure Mode Grade', failureModeGrade);
      return this.translateService.instant('failureModeGrades.unknown');
    }
  }
}
