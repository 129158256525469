<div fxShow fxHide.lt-xl fxLayout="row" fxLayoutAlign="start center">
  <app-risk-model-enabled-button
    *ngIf="riskModel.status === ProcessStatus.Processed"
    [riskModel]="riskModel"
    (riskModelChanged)="riskModelChanged.emit($event)"
    fxFlex
    style="margin-left: 1rem"
  >
  </app-risk-model-enabled-button>

  <button
    [routerLink]="['/infrastructures', riskModel.infrastructure_id, 'risk-models', riskModel.id, 'diagram']"
    [disabled]="riskModel.status !== ProcessStatus.Processed"
    [matTooltip]="'infrastructure.GoToRiskModelDiagram' | translate"
    mat-icon-button
  >
    <mat-icon>addchart</mat-icon>
  </button>

  <button
    [disabled]="riskModel.status !== ProcessStatus.Processed || !riskModel.link_documentation"
    (click)="openDocumentationLink()"
    mat-icon-button
  >
    <mat-icon [matTooltip]="'common.openDocumentationLink' | translate"> menu_book </mat-icon>
  </button>

  <a [disabled]="!riskModel.signed_original_path_file" [href]="riskModel.signed_original_path_file" mat-icon-button>
    <mat-icon [matTooltip]="'common.downloadRiskModel' | translate"> file_download </mat-icon>
  </a>

  <button
    [disabled]="!isDeleteButtonVisible()"
    [matTooltip]="'infrastructure.DeleteRiskModel' | translate"
    (click)="onDeleteRiskModelButtonClicked()"
    mat-icon-button
    color="warn"
  >
    <mat-icon> delete </mat-icon>
  </button>
</div>
<div fxHide fxShow.lt-xl fxLayout="row" fxLayoutAlign="start center">
  <app-risk-model-enabled-button
    *ngIf="riskModel.status === ProcessStatus.Processed"
    [riskModel]="riskModel"
    (riskModelChanged)="riskModelChanged.emit($event)"
    fxFlex
    style="margin-left: 1rem"
  >
  </app-risk-model-enabled-button>
  <button [matTooltip]="'common.moreOptions' | translate" fxShow.lt-xl mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon> expand_more </mat-icon>
    <!-- {{ 'common.actions' | translate }} -->
  </button>
</div>

<mat-menu #menu="matMenu">
  <button
    [routerLink]="['/infrastructures', riskModel.infrastructure_id, 'risk-models', riskModel.id, 'diagram']"
    [disabled]="riskModel.status !== ProcessStatus.Processed"
    [matTooltip]="'infrastructure.GoToRiskModelDiagram' | translate"
    mat-button
  >
    <mat-icon>addchart</mat-icon>
    {{ 'infrastructure.GoToRiskModelDiagram' | translate }}
  </button>

  <button
    [disabled]="riskModel.status !== ProcessStatus.Processed || !riskModel.link_documentation"
    (click)="openDocumentationLink()"
    mat-button
  >
    <mat-icon [matTooltip]="'common.openDocumentationLink' | translate"> menu_book </mat-icon>
    {{ 'common.openDocumentationLink' | translate }}
  </button>

  <a [disabled]="!riskModel.signed_original_path_file" [href]="riskModel.signed_original_path_file" mat-button>
    <mat-icon [matTooltip]="'common.downloadRiskModel' | translate"> file_download </mat-icon>
    {{ 'common.downloadRiskModel' | translate }}
  </a>

  <button
    [disabled]="!isDeleteButtonVisible()"
    [matTooltip]="'infrastructure.DeleteRiskModel' | translate"
    (click)="onDeleteRiskModelButtonClicked()"
    mat-button
    color="warn"
  >
    <mat-icon> delete </mat-icon>
    {{ 'infrastructure.DeleteRiskModel' | translate }}
    <!-- <span fxHide.lt-md> {{ 'common.delete' | translate | uppercase }} </span> -->
  </button>
</mat-menu>
