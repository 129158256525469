<div class="risk-management-node" [ngStyle]="{ 'box-shadow': boxShadow$ | async }">
  <ng-container *ngIf="model.selectExtras() | async as extras">
    <div class="content" [ngStyle]="{ background: nodeColor }">
      <div class="header">
        <div class="title">
          {{ extras.title }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="ports">
    <ng-template #portsLayer></ng-template>
  </div>
</div>
