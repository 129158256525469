import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from './../paginated-result.interface';
import { UpdateRiskModelParam } from './params/update-risk-model.param';
import { UploadRiskModelParam } from './params/upload-risk-model.param';
import { RiskModel } from './risk-model';

@Injectable({
  providedIn: 'root',
})
export class RiskModelsService {
  constructor(private http: HttpClient) {}

  findAllRiskModelsByInfrastructureId(infrastructureId: number): Observable<RiskModel[]> {
    const infrastructureIdAsString = infrastructureId.toString();
    const url = '/infrastructure/risk_models';
    const params = new HttpParams()
      .set('_page_size', '0')
      .set('_filter_infrastructure_id__exact', infrastructureIdAsString)
      .set('_order_by', '-dt_process_start');

    return this.http.get<PaginatedResult<RiskModel>>(url, { params }).pipe(
      map((result) =>
        result.items.map((item) => ({
          ...item,
          //TODO: Remove + 'Z' when endpoint returns with 'Z'
          dt_process_start: item.dt_process_start ? new Date(item.dt_process_start + 'Z') : null,
          dt_process_end: item.dt_process_end ? new Date(item.dt_process_end + 'Z') : null,
        }))
      )
    );
  }

  // TODO: Type this method result
  uploadRiskModelFile(param: UploadRiskModelParam): Observable<any> {
    const url = '/infrastructure/risk_model';

    const formData = new FormData();
    formData.append('file', param.file);
    formData.append('file_name', param.file_name);
    formData.append('infrastructure_id', param.infrastructure_id.toString());
    formData.append('title', param.title.toString());
    formData.append('comment', param.comment.toString());
    formData.append('init_year', param.init_year.toString());
    formData.append('end_year', param.end_year.toString());
    if (param.link_documentation) {
      formData.append('link_documentation', param.link_documentation);
    }

    return this.http.post(url, formData);
  }

  deleteRiskModelById(riskModelId: number): Observable<void> {
    const url = `/infrastructure/risk_model?id=${riskModelId}`;

    return this.http.delete<void>(url);
  }

  updateRiskModel(param: UpdateRiskModelParam) {
    const url = '/infrastructure/risk_model';

    return this.http.patch(url, param);
  }
}
