import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { InfrastructureService } from '@shared/models/infrastructures/infrastructure.service';
import { SharedDataService } from '@shared/services/shared-data.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Infrastructure2 } from '../../models/infrastructures/infrastructure2';

const log = new Logger('SelectInfrasctructureComponent');

@Component({
  selector: 'app-select-infrastructure',
  templateUrl: './select-infrastructure.component.html',
  styleUrls: ['./select-infrastructure.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInfrastructureComponent),
      multi: true,
    },
  ],
})
export class SelectInfrastructureComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() multiple = false;
  @Input() value: number | number[] = this.multiple ? Array<number>() : null;
  formControl = new FormControl();
  @Input() set disabledControl(disabledControl: boolean) {
    if (disabledControl) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  private _infrastructureTypeIds: number[];
  get infrastructureTypeIds(): number[] {
    return this._infrastructureTypeIds;
  }
  @Input() set infrastructureTypeIds(infrastructureTypeIds: number[]) {
    this._infrastructureTypeIds = infrastructureTypeIds;
    this.markInfrastruresByType();
  }

  private _customerId: number;
  get customerId(): number {
    return this._customerId;
  }
  @Input()
  set customerId(customerId: number) {
    this._customerId = customerId;
    this.loadList();
  }

  list: Infrastructure2[] = [];
  allInfrastructureIdsWithModel = new Array<number>();
  filteredList: Infrastructure2[] = [];
  isDisabled = false;
  filterCtrl = new FormControl();

  isLoading = false;
  query: string;
  subs: Subscription;

  private _onDestroy = new Subject();

  constructor(
    private infrastructureService: InfrastructureService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.sharedDataService.getSelectedInfrastructureIdList().subscribe((infrastructureIdList) => {
      this.value = infrastructureIdList;
    });
  }

  ngOnInit() {
    this.loadList();

    // listen for search field value changes
    this.subs = this.filterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((query) => {
      this.filteredList = this.list.filter((value) => {
        const normalizedName = value.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        const normalizedQuery = query
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        return normalizedName.includes(normalizedQuery);
      });
    });
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange(value: number | number[]): void {}
  onTouched() {}

  loadList() {
    this.sharedDataService
      .getInfrastructureList()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((infrastructructureList) => {
        if (infrastructructureList === null) {
          return;
        }
        this.list = infrastructructureList;
        this.filteredList = this.list;
        this.allInfrastructureIdsWithModel = this.list.filter((value) => value.risk_model).map((value) => value.id);
        this.isLoading = false;

        this.markInfrastruresByType();
        this.updateSelectedInfrastructureIdsOnSharedData();
        // this.updateInfrastructureListOnSharedData();
        this.getSelectedInfrastructureIdFromQueryParams();
      });
    // });
  }
  getSelectedInfrastructureIdFromQueryParams(): void {
    const infrastructureIdAsString = this.route.snapshot.queryParamMap.get('infrastructureId');
    const infrastructureId = infrastructureIdAsString != null ? Number(infrastructureIdAsString) : undefined;

    if (!this.multiple && infrastructureId != null) {
      this.sharedDataService.setSelectedInfrastructureIdList([infrastructureId]);

      this.value = infrastructureId;
      this.formControl.setValue(infrastructureId);
    }
  }

  markInfrastruresByType() {
    if (this.multiple) {
      const selectedIds = this.list
        .filter((value) => value.risk_model && this.infrastructureTypeIds.includes(value.infrastructure_type_id))
        .map((value) => value.id);
      this.value = selectedIds;

      this.onTouched();
      this.onChange(this.value);
      this.formControl.setValue(this.value);
    } else {
      console.warn('You must use infrastructureTypeIds with multiple option');
    }
  }

  updateSelectedInfrastructureIdsOnSharedData() {
    const valueAsNumberArray = this.value as number[];
    const valueAsNumber = this.value as number;
    const selectedIds = this.multiple ? valueAsNumberArray : [valueAsNumber];
    const modelIds = this.list
      .filter((value) => selectedIds.includes(value.id) && value.risk_model)
      .map((value) => value.risk_model.id);

    this.sharedDataService.setSelectedInfrastructureIdList(selectedIds);
    this.sharedDataService.setSelectedModelIds(modelIds);
  }

  // updateInfrastructureListOnSharedData() {
  //   this.sharedDataService.setInfrastructureList(this.list);
  // }

  onSelectChanged(event: MatSelectChange) {
    const newSelect = event.value;
    this.value = newSelect;

    this.updateSelectedInfrastructureIdsOnSharedData();
    this.onTouched();
    this.onChange(this.value);
  }

  toggleSelectAll(value: boolean) {
    if (value === true) {
      this.selectAll();
    } else {
      this.clear();
    }
    this.updateSelectedInfrastructureIdsOnSharedData();
    this.onTouched();
    this.onChange(this.value);
  }

  selectAll() {
    this.value = this.allInfrastructureIdsWithModel;
  }

  clear() {
    this.value = [];
  }

  getTriggerValue(): string {
    let valueAsString: string;

    if (Array.isArray(this.value)) {
      const itemCount = this.value.length;

      if (itemCount === 1) {
        valueAsString = this.list
          .filter((value) => value.id === this.value[0])
          .map((value) => value.name)
          .toString();
      } else if (this.isAllItemsSelected()) {
        valueAsString = this.translateService.instant('common.allInfrastructuresSelected');
      } else {
        valueAsString = this.translateService.instant('common.nInfrastructuresSelected', { itemCount }); //`${itemCount} infrastructures selected`;
      }
    } else {
      const valueAsNumber = this.value as number;
      valueAsString = this.list
        .filter((infrastructure) => infrastructure.id === valueAsNumber)
        .map((infrastructure) => infrastructure.name)
        .toString();
    }
    return valueAsString;
  }

  isAllItemsSelected(): boolean {
    if (Array.isArray(this.value)) {
      const valueAsArray = this.value as number[];

      const isAllItemsSelected = this.allInfrastructureIdsWithModel.every((id) => valueAsArray.includes(id));

      return isAllItemsSelected;
    }
    return false;
  }

  ngOnChanges() {}

  writeValue(value: number | number[]) {
    this.value = value;
    this.loadList();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }
}
