import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { UserRoleEnum } from '@app/@shared/models/users/users/user-role.enum';
import { UserService } from '@app/@shared/services/user.service';
import { Logger } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { ProcessStatus } from '@shared/models/process-status.enum';
import { RiskModel } from '@shared/models/risk-models/risk-model';
import { RiskModelsService } from '@shared/models/risk-models/risk-models.service';
import * as moment from 'moment';
import { AddRiskModelDialogComponent } from './add-risk-model-dialog/add-risk-model-dialog.component';

const log = new Logger('TabRiskModelComponent');

@Component({
  selector: 'app-risk-model-list',
  templateUrl: './risk-model-list.component.html',
  styleUrls: ['./risk-model-list.component.scss'],
})
export class RiskModelListComponent implements OnInit {
  @Input() hasNewButton = false;
  @Input() disabled: boolean;

  @Output() formChanged: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  private _riskModelsList: RiskModel[];
  public get riskModelsList(): RiskModel[] {
    return this._riskModelsList;
  }
  public set riskModelsList(value: RiskModel[]) {
    this._riskModelsList = value;
  }
  filteredRiskModelsList?: RiskModel[];
  isLoading = false;
  query: string;
  ProcessStatus = ProcessStatus;

  private _infrastructure: Infrastructure2;

  @Input()
  set infrastructure(value: Infrastructure2) {
    this._infrastructure = value;
    if (value) {
      this.loadData();
    }
  }
  get infrastructure(): Infrastructure2 {
    return this._infrastructure;
  }

  canCreate: boolean;

  constructor(
    public dialog: MatDialog,
    private riskModelsService: RiskModelsService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.userService.roleId().then((roleId) => {
      this.canCreate = [UserRoleEnum.Manager, UserRoleEnum.Engineer].includes(roleId);
    });

    // this.loadList();
  }

  loadData() {
    this.isLoading = true;
    const infrastructureId = this.infrastructure.id;

    this.riskModelsService.findAllRiskModelsByInfrastructureId(infrastructureId).subscribe((riskModelList) => {
      this.riskModelsList = riskModelList;
      this.filteredRiskModelsList = riskModelList;
      this.query = '';
      this.isLoading = false;
    });
  }

  onSearchQuery(query: string) {
    this.query = query.trim().toLocaleLowerCase();
    this.filteredRiskModelsList = this.riskModelsList.filter(
      (value) =>
        value.init_year.toString().includes(query) ||
        value.end_year.toString().includes(query) ||
        moment(value.dt_process_start).format('DD/MM/YY HH:mm').includes(this.query) ||
        moment(value.dt_process_end).format('DD/MM/YY HH:mm').includes(this.query) ||
        value.status.toLocaleLowerCase().includes(query)
    );
  }

  onRefreshButtonClicked() {
    this.loadData();
  }

  onCreateButtonClicked() {
    const dialogRef = this.dialog.open(AddRiskModelDialogComponent, {
      // width: '400px',
      // height: '500px',
      // minHeight: '500px',
      data: {
        infrastructure: this.infrastructure,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.loadData();
    });
  }

  isAnyRiskmodelBeingProcessed(): boolean {
    const processingRiskModels = this.riskModelsList.filter((value) => value.status === ProcessStatus.Processing);
    const isAnyRiskmodelBeingProcessed = processingRiskModels.length > 0;
    return isAnyRiskmodelBeingProcessed;
  }

  onRiskModelChanged(): void {
    this.loadData();
  }
}
