<mat-form-field fxFlex appearance="outline">
  <mat-label>
    {{ 'common.customer' | translate | uppercase }}
  </mat-label>

  <mat-select [formControl]="control" [multiple]="false">
    <!-- <mat-select-trigger>
      {{ getTriggerValue() }}
    </mat-select-trigger> -->
    <mat-option>
      <ngx-mat-select-search
        [formControl]="filterCtrl"
        placeholderLabel="{{ 'common.searchCustomer' | translate | uppercase }}"
        noEntriesFoundLabel="{{ 'common.customerNotFound' | translate | uppercase }}"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of list" [value]="item">
      <div *ngIf="item.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
        {{ item.name }}
      </div>
    </mat-option>
  </mat-select>
  <mat-hint>
    {{ 'user.SelectCustomer' | translate | uppercase }}
  </mat-hint>
</mat-form-field>
