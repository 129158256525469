import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from '@app/@core';
import { IConfirmConfig, ISnackBarConfig } from '@app/@shared/dialogs/models/dialogs.interfaces';
import { DialogsService } from '@app/@shared/dialogs/services/dialogs.service';
import { InfrastructureService } from '@app/@shared/models/infrastructures/infrastructure.service';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { UpdateInfrastructureImageParam } from '@app/@shared/models/infrastructures/params/update-infrastructure-image.param';
import { EditInfrastructureDialogComponent } from '../dialogs/edit-infrastructure-dialog/edit-infrastructure-dialog.component';
import { UploadFileDialogComponent } from '../dialogs/upload-file-dialog/upload-file-dialog.component';

const log = new Logger();

@Component({
  selector: 'app-infrastructure-detail-actions[infrastructure]',
  templateUrl: './infrastructure-detail-actions.component.html',
  styleUrls: ['./infrastructure-detail-actions.component.scss'],
})
export class InfrastructureDetailActionsComponent implements OnInit {
  @Input() infrastructure: Infrastructure2;
  @Output() infrastructureEdited = new EventEmitter<Infrastructure2>();
  @Output() infrastructureImageUpdated = new EventEmitter<number>();
  @Output() infrastructureDeleted = new EventEmitter<number>();

  constructor(
    public dialog: MatDialog,
    private infrastructuresService: InfrastructureService,
    private dialogsService: DialogsService
  ) {}

  ngOnInit(): void {}

  onEditInfrastructureButtonClicked(): void {
    const dialogRef = this.dialog.open(EditInfrastructureDialogComponent, {
      data: { infrastructure: this.infrastructure },
    });
    dialogRef.afterClosed().subscribe((newInfrastructure) => {
      if (newInfrastructure) {
        this.infrastructureEdited.emit(newInfrastructure);
      }
    });
  }
  onDeleteInfrastructureButtonClicked(): void {
    this.dialogsService
      .openConfirm({
        title: 'common.deleteInfrastructure',
        message: 'common.areYouSure',
      } as IConfirmConfig)
      .subscribe((confirm) => {
        if (confirm) {
          // this.isDeleting = true;
          const infrastructureId = this.infrastructure.id;
          this.infrastructuresService.deleteInfrastructure(infrastructureId).subscribe({
            next: (result) => {
              log.info(`Infrastructure with id=${infrastructureId} deleted`);
              this.dialogsService.openSnackBar({
                message: 'common.infrastructureDeleteSuccess',
              } as ISnackBarConfig);
              this.infrastructureDeleted.emit(infrastructureId);
              // this.loadData();
            },
            error: (error) => {
              log.error(error);
              this.dialogsService.openSnackBar({ message: 'common.infrastructureDeleteError' } as ISnackBarConfig);
            },
          });
        }
      });
  }

  onUpdateImageButtonClicked(): void {
    const dialogRef = this.dialog.open(UploadFileDialogComponent, {});
    dialogRef.afterClosed().subscribe((file) => {
      if (file) {
        const param = new UpdateInfrastructureImageParam();
        param.infrastructure_id = this.infrastructure.id;
        param.file = file;
        param.file_name = param.file.name;
        this.infrastructuresService.updateInfrastructureImage(param).subscribe({
          next: (result) => {
            log.info('Infrastructure image updated');

            this.infrastructureImageUpdated.emit(this.infrastructure.id);
          },
          error: (error) => {
            log.error('Error updating infrastructure image', error);
          },
        });
      }
    });
  }
}
