import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@shared/models/pagination.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../paginated-result.interface';
import { InfrastructureTypeCount } from './infrastructure-type-count';
import { InfrastructureTypeList } from './infrastructure-type-list.model';
import { InfrastructureType } from './infrastructure-type.model';
import { InfrastructureType2 } from './infrastructure-type2.interface';


@Injectable()
export class InfrastructureTypeService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<InfrastructureTypeList> {

    const params = this.http.getParams({
      '_page_size': '0',
      '_fields': 'id,name,infrastructure_specific_schema'
    });

    return this.http.cache()
      .get('/infrastructure_types', { params }).pipe(
        map((json: any) => {
          const items: InfrastructureType[] =
            json.items.map(infrastructureType => new InfrastructureType(infrastructureType));
          const pagination: Pagination = new Pagination(json);
          return new InfrastructureTypeList(items, pagination);
        })
      );
  }


  getAllInfrastructureTypes(): Observable<InfrastructureType2[]> {
    const url = '/infrastructure_types';
    const params = this.http.getParams({
      '_page_size': '0',
      '_fields': 'id,name,infrastructure_specific_schema'
    });
    return this.http.cache().get(url, { params }).pipe(
      map((result: PaginatedResult<InfrastructureType>) => result.items));
  }


  // // TODO: Get value from API and return Observable
  // getInfrastructureTypeCountByCustomerId(customerId: number = null): InfrastructureTypeCount[] {

  //   // const url = '/api/infrastructures';
  //   // const params = new HttpParams()
  //   //   .set('_page_size', '0')
  //   //   .set('_fields', 'infrastructure_type_id');

  //   // this.http.get(url, { params }).subscribe((value: PaginatedResult<{ infrastructure_type_id: number; }>) =>{
  //   //   const items= value.items
  //   // });



  //   const result = [
  //     {
  //       infrastucture_type: {
  //         id: 1,
  //         name: 'Presa'
  //       },
  //       count: 2
  //     } as InfrastructureTypeCount,
  //     {
  //       infrastucture_type: {
  //         id: 3,
  //         name: 'Muelle'
  //       },
  //       count: 1
  //     } as InfrastructureTypeCount,
  //     {
  //       infrastucture_type: {
  //         id: 5,
  //         name: 'Vía'
  //       },
  //       count: 1
  //     } as InfrastructureTypeCount,

  //   ];
  //   return result;


  // }



}
