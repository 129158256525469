import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InfrastructureSpecificSchema2 } from '@shared/models/infrastructure-type/infrastructure-specific-schema2.interface';
import { InfrastructureType } from '@shared/models/infrastructure-type/infrastructure-type.model';
import { InfrastructureTypeService } from '@shared/models/infrastructure-type/infrastructure-type.service';
import { InfrastructureType2 } from '@shared/models/infrastructure-type/infrastructure-type2.interface';
import { InfrastructureService } from '@shared/models/infrastructures/infrastructure.service';
import { Infrastructure2 } from '@shared/models/infrastructures/infrastructure2';
import { UbicationValidator } from '@shared/validators/ubication-validator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormMode } from '../../../FormMode';
import { InfrastructureTypeEnum } from '../../../models/infrastructure-type/infrastructure-type.enum';
import { UploadFileDialogComponent } from '../../dialogs/upload-file-dialog/upload-file-dialog.component';

@Component({
  selector: 'app-infrastructure-form',
  templateUrl: './infrastructure-form.component.html',
  styleUrls: ['./infrastructure-form.component.scss'],
})
export class InfrastructureFormComponent implements OnInit, OnDestroy {
  infrastructureType: InfrastructureType2;

  @Input() set parentForm(parentForm: FormGroup) {
    parentForm.addControl('infrastructure', this.form);
  }
  @Input() set customerId(customerId: number) {
    this.form.controls.customer_id.setValue(customerId);
  }

  infrastructureTypeList?: InfrastructureType2[];

  @Input() set infrastructure(infrastructure: Infrastructure2) {
    this._infrastructure = infrastructure;

    if (infrastructure) {
      this.form.patchValue(infrastructure);
    } else {
      this.form.reset();
    }
  }

  get infrastructure(): Infrastructure2 {
    return this._infrastructure;
  }

  @Input() set infrastructureId(infrastructureId: number) {
    this._infrastructureId = infrastructureId;
    this.loadInfrastructure();
  }
  get infrastructureId(): number {
    return this._infrastructureId;
  }

  @Input() set mode(mode: FormMode) {
    this._mode = mode;
    this.form.enable();
    switch (mode) {
      case FormMode.Create:
        this.form.enable();
        break;
      case FormMode.Edit:
        this.form.enable();
        this.form.controls.infrastructure_type_id.disable();
        break;
      case FormMode.View:
        this.form.disable();
        break;
    }
  }
  get mode(): FormMode {
    return this._mode;
  }

  @Output() infrastructureTypeChanged: EventEmitter<InfrastructureType> = new EventEmitter<InfrastructureType>();
  @Output() formChanged: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  FormMode = FormMode;
  InfrastructureTypeEnum = InfrastructureTypeEnum;

  form = this.fb.group({
    // picture: [], // ['', Validators.required],
    customer_id: [null, Validators.required],
    infrastructure_type_id: [null, Validators.required],
    typology: ['', [Validators.required, Validators.maxLength(100)]],
    name: ['', [Validators.required, Validators.maxLength(50)]],
    tag: ['', Validators.maxLength(50)],
    location: [''],
    region: ['', Validators.maxLength(100)],
    build_date: [new Date().getFullYear(), [Validators.min(1900), Validators.max(3000)]],
    ubication: ['', [Validators.maxLength(50), UbicationValidator]],
    is_risk_incremental: [false],
    is_risk_total: [false],
    specific_information: this.fb.group({}),
  });
  infrastructureTypeId: InfrastructureTypeEnum;

  fileFormControl = new FormControl();

  get specificInformationGroup(): FormGroup {
    return this.form.controls.specific_information as FormGroup;
  }

  specificSchema?: InfrastructureSpecificSchema2[];

  private _formDisabled?: boolean;
  private _infrastructureId?: number;
  private _mode?: FormMode;
  private _infrastructure?: Infrastructure2;
  protected _onDestroy = new Subject();

  constructor(
    private fb: FormBuilder,
    private infrastructuresService: InfrastructureService,
    private infrastructureTypesService: InfrastructureTypeService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.infrastructureTypesService.getAllInfrastructureTypes().subscribe((infrastructureTypeList) => {
      this.infrastructureTypeList = infrastructureTypeList;

      this.initForm();

      if (this.infrastructure) {
        this.infrastructureType = this.infrastructureTypeList.find(
          (value) => value.id === this.infrastructure.infrastructure_type_id
        );
        this.specificSchema = this.infrastructureType.infrastructure_specific_schema;
        this.form.patchValue(this.infrastructure);
        this.updateSpecificSchema();
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  loadInfrastructure(): void {
    this.infrastructuresService
      .getInfrastructureById(this.infrastructureId)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((infrastructure) => {
        this.infrastructure = infrastructure;
      });
  }
  loadInfrastructureTypeList(): void {
    this.infrastructureTypesService
      .getAllInfrastructureTypes()

      .subscribe((infrastructureTypeList) => {
        this.infrastructureTypeList = infrastructureTypeList;
      });
  }
  onInfrastructureTypeChange(infrastructureTypeId: InfrastructureTypeEnum): void {
    this.infrastructureTypeId = infrastructureTypeId;
    const infrastructureType = this.infrastructureTypeList.find((value) => value.id === infrastructureTypeId);
    this.specificSchema = infrastructureType.infrastructure_specific_schema;

    this.specificSchema.forEach((specificSchemaItem) => {
      const validators = [];
      if (specificSchemaItem.required) {
        validators.push(Validators.required);
      }
      if (specificSchemaItem.restriction) {
        validators.push(Validators.maxLength(specificSchemaItem.restriction));
      }
      const value = this.infrastructure?.specific_information[specificSchemaItem.name];
      const control = this.fb.control(value, validators);
      const controlName = specificSchemaItem.name;
      this.specificInformationGroup.addControl(controlName, control);
    });

    if (this.mode === FormMode.View) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  onSelectInfrastructureTypes(infrastructureType: InfrastructureType) {
    this.form.patchValue({ infrastructureType });
    this.infrastructureTypeChanged.emit(infrastructureType);
  }

  onFileUploaded(picture: File) {
    this.form.patchValue({ picture });
  }

  updateSpecificSchema() {
    if (this.form) {
      this.form.setControl('specific_information', this.fb.group({}));
      const specificSchemaGroup = this.form.get('specific_information') as FormGroup;
      this.specificSchema?.forEach((control) => {
        const validators = [];
        if (control.required) {
          validators.push(Validators.required);
        }
        if (control.restriction) {
          validators.push(Validators.maxLength(control.restriction));
        }
        specificSchemaGroup.addControl(
          control.name,
          new FormControl(
            this.infrastructure && this.infrastructure.specific_information
              ? this.infrastructure.specific_information[control.name]
              : '',
            validators
          )
        );
      });

      if (this.mode === FormMode.View) {
        this.form.disable();
      }
    }
  }

  private initForm() {
    if (this.infrastructure) {
      this.updateSpecificSchema();
    }
  }

  onUpdateImageButtonClick(): void {
    const dialogRef = this.dialog.open(UploadFileDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}
