import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../paginated-result.interface';
import { ViewType } from '../view-type.enum';
import { FindReportsParam } from './find-reports.param';
import { MakeReportParam } from './make-report.param';
import { Report } from './report';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  findReports(param: FindReportsParam): Observable<PaginatedResult<Report>> {
    const url = '/reports';
    const params = param.toHttpParams();

    return this.http.get<PaginatedResult<Report>>(url, { params });
  }

  makeReport(param: MakeReportParam): Observable<Report> {
    // Forcing string on body
    const paramWithForcedStrings = {
      options: {
        language: param.options.language.toString(),
        x_axis_min: param.options.x_axis_min.toString(),
        x_axis_max: param.options.x_axis_max.toString(),
        x_axis_log: param.options.x_axis_log.toString(),
        x_axis_ext: param.options.x_axis_ext.toString(),
        y_axis_min: param.options.y_axis_min.toString(),
        y_axis_max: param.options.y_axis_max.toString(),
        y_axis_log: param.options.y_axis_log.toString(),
        y_axis_ext: param.options.y_axis_ext.toString(),

        crit03: param.options.crit03.toString(),
        crit09: param.options.crit09.toString(),

        crit_ancold: param.options.crit_ancold.toString(),
        crit_ancoldn: param.options.crit_ancoldn.toString(),
        crit_usace: param.options.crit_usace.toString(),
        crit_usacen: param.options.crit_usacen.toString(),

        crit_gen: param.options.crit_gen.toString(),
        c_value: param.options.crit_gen ? param.options.c_value.toString() : '',
        n_value: param.options.crit_gen ? param.options.n_value.toString() : '',

        measure_effect: param.options.measure_effect.toString(),
      },
    };

    if (param?.options?.fatality_value) {
      paramWithForcedStrings.options['fatality_value'] = param.options.fatality_value.toString();
    }

    if (param?.options?.restrictions?.length) {
      paramWithForcedStrings.options['restrictions'] = param.options.restrictions.toString();
    }

    if (param?.priorization_op) {
      paramWithForcedStrings['priorization_op'] = param.priorization_op.toString();
    }

    let url: string;
    switch (param.viewType) {
      case ViewType.Port:
        url = '/make_report_portfolio';
        paramWithForcedStrings['priorization_op'] = param.priorization_op.toString();
        break;

      case ViewType.Dam:
        url = '/make_report_infrastructure';
        paramWithForcedStrings['infrastructure_id'] = param.infrastructure_id.toString();
        break;
    }

    return this.http.post<Report>(url, paramWithForcedStrings);
  }

  deleteReportById(reportId: number): Observable<void> {
    const url = `/report?id=${reportId}`;
    return this.http.delete<void>(url);
  }
}
