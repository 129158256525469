import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Logger } from '@app/@core';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { UpdateInfrastructureInput } from '@app/@shared/models/infrastructures/params/update-infrastructure.input';
import { ISnackBarConfig } from '@shared/dialogs/models/dialogs.interfaces';
import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { FormMode } from '@shared/FormMode';
import { InfrastructureService } from '@shared/models/infrastructures/infrastructure.service';

@Component({
  selector: 'app-edit-infrastructure-dialog',
  templateUrl: './edit-infrastructure-dialog.component.html',
  styleUrls: ['./edit-infrastructure-dialog.component.scss'],
})
export class EditInfrastructureDialogComponent implements OnInit {
  form = new FormGroup({});
  mode = FormMode.Edit;
  customerId?: number;
  infrastructure: Infrastructure2;
  isUpdating = false;
  // infrastructureTypeList: InfrastructureType2[];

  log = new Logger('EditInfrastructureDialogComponent');

  constructor(
    private dialogsService: DialogsService,
    private dialogRef: MatDialogRef<EditInfrastructureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { infrastructure: Infrastructure2 },
    private infrastructuresService: InfrastructureService
  ) {
    this.infrastructure = data.infrastructure;
  }

  ngOnInit() {}

  onSaveButtonClicked(): void {
    this.isUpdating = true;

    const formValue = this.form.value.infrastructure;

    // const build_date_width_dd_mm_yyyy_format = moment(build_date).format('DD-MM-YYYY');

    const param = {
      ...formValue,
      id: this.infrastructure.id,
      // build_date: build_date_width_dd_mm_yyyy_format,
    } as UpdateInfrastructureInput;

    const specificInformationId = this.infrastructure?.specific_information?.id;

    if (specificInformationId) {
      param.specific_information.id = specificInformationId;
    }

    this.infrastructuresService.updateInfrastructure(param).subscribe({
      next: (infrastructure) => {
        this.isUpdating = false;
        this.log.info(`Infrastructure with id=${infrastructure?.id} updated`);
        this.dialogsService.openSnackBar({
          message: 'common.infrastructureUpdateSuccess',
        } as ISnackBarConfig);

        this.dialogRef.close(infrastructure);
      },
      error: (error) => {
        this.isUpdating = false;
        this.log.error('Error updating infraestructure', error);
        this.dialogsService.openSnackBar({
          message: 'common.infrastructureUpdateError',
        } as ISnackBarConfig);
      },
    });
  }

  onCancelButtonClicked(): void {
    this.dialogRef.close();
  }
}
