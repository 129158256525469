import { Component, Input, OnInit } from '@angular/core';
import { InfrastructureNotificationRequest } from '@app/@shared/models/infrastructure-notification-requests/infrastructure-notification-request';

@Component({
  selector: 'app-infrastructure-notification-request-list-table',
  templateUrl: './infrastructure-notification-request-list-table.component.html',
  styleUrls: ['./infrastructure-notification-request-list-table.component.scss'],
})
export class InfrastructureNotificationRequestListTableComponent implements OnInit {
  @Input() list?: InfrastructureNotificationRequest[];

  readonly displayedColumns = ['username', 'date', 'reason', 'emailReceiver'];

  constructor() {}

  ngOnInit(): void {}
}
