<div fxLayout="column">
  <div *ngIf="files?.length < limit" class="dropzone">

    <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />
    <div fxLayout="column" fxLayoutAlign="center center" style="min-height: 200px;">
      <div [innerText]="'common.dragFilesHint'| translate | uppercase"></div>
      <!-- <span translate>Drag files</span>
        <span translate>(o click to search)</span> -->
      <mat-icon>cloud_upload</mat-icon>
    </div>

  </div>
  <div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let file of files; let i = index">
      <div fxFlex="45px">
        <img [src]="file.url" style="width: 100%; height: 100%; object-fit: contain; margin: auto" alt="file">
      </div>

      <div>
        <div>{{ file?.name }}</div>
        <div>{{ formatBytes(file?.size) }}</div>
      </div>
      <button mat-icon-button color="primary" (click)="deleteFile(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
