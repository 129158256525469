<mat-form-field fxFlex appearance="outline" class="mat-overwrite mat-select-white">
  <mat-label>
    {{ 'common.infrastructure' | translate | uppercase }}
  </mat-label>

  <mat-select
    [formControl]="formControl"
    (selectionChange)="onSelectChanged($event)"
    [value]="value"
    [multiple]="multiple"
  >
    <mat-select-trigger>
      {{ getTriggerValue() }}
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [formControl]="filterCtrl"
        (toggleAll)="toggleSelectAll($event)"
        [toggleAllCheckboxChecked]="isAllItemsSelected()"
        showToggleAllCheckbox="multiple"
        toggleAllCheckboxTooltipMessage="{{ 'common.selectAll' | translate | uppercase }}"
        placeholderLabel="{{ 'common.search' | translate | uppercase }}"
        noEntriesFoundLabel="{{ 'common.itemNotFound' | translate | uppercase }}"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      [matTooltip]="'common.noRiskModel' | translate | uppercase"
      [matTooltipDisabled]="item.risk_model"
      *ngFor="let item of filteredList"
      [value]="item.id"
      [disabled]="!item.risk_model"
    >
      <div *ngIf="item.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
        {{ item.name }}
        <span *ngIf="!item.risk_model">
          {{ 'common.noRiskModel' | translate | uppercase }}
        </span>
      </div>
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="multiple">
    {{ 'common.selectOneOrMoreInfrastructures' | translate }}
    <span translate> </span>
  </mat-hint>
  <mat-hint *ngIf="!multiple">
    {{ 'common.selectOneInfrasctructure' | translate }}
  </mat-hint>
</mat-form-field>
