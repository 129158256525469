import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'scientificNotation' })
export class ScientificNotationPipe implements PipeTransform {
  constructor() {}

  transform(value: number | string, decimals: number = 2): string {
    if (value == null) {
      return '';
    }
    if (typeof value === 'string') {
      value = Number(value);
    }
    const result = value.toExponential(decimals);
    return result;
  }
}
