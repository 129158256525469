import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { ISnackBarConfig } from './../../@shared/dialogs/models/dialogs.interfaces';
import { DialogsService } from './../../@shared/dialogs/services/dialogs.service';


const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private dialogsService: DialogsService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      log.error('Request error', response);

      this.dialogsService.openSnackBar({
        message: response['message'],
        duration: 4000
      } as ISnackBarConfig);

    }
    throw response;
  }

}
