<h2 mat-dialog-title translate style="text-align: center">
  {{ 'diagram.DiagramExport' | translate | uppercase }}
</h2>

<mat-dialog-content>
  <div [formGroup]="form" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="0.5rem">
      <mat-form-field floatLabel="always" appearance="outline" fxFlex>
        <mat-label>
          {{ 'diagram.FileName' | translate | uppercase }}
        </mat-label>

        <input matInput [formControlName]="DiagramFlowExportFields.FileName" />
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" fxFlex="100px">
        <mat-label>
          {{ 'diagram.Format' | translate | uppercase }}
        </mat-label>

        <mat-select
          #format
          [formControlName]="DiagramFlowExportFields.Format"
          (selectionChange)="onFormatChange()"
          fxFlex
          style="text-align: center"
        >
          <mat-option [value]="ImageFormat.Png" style="text-align: center"> png </mat-option>
          <mat-option [value]="ImageFormat.Jpg" style="text-align: center"> jpg </mat-option>
          <mat-option [value]="ImageFormat.Svg" style="text-align: center"> svg </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="0.5rem" fxFlex>
      <mat-form-field floatLabel="always" appearance="outline" fxFlex>
        <mat-label>
          {{ 'diagram.PixelRatio' | translate | uppercase }}
        </mat-label>
        <mat-select [formControlName]="DiagramFlowExportFields.PixelRatio" style="text-align: center">
          <mat-option
            *ngFor="let pixelRatioOption of pixelRatioOptionList"
            [value]="pixelRatioOption"
            style="text-align: center"
          >
            {{ pixelRatioOption }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" appearance="outline" fxFlex>
        <mat-label>
          {{ 'diagram.Quality' | translate | uppercase }}
        </mat-label>
        <input
          matInput
          [formControlName]="DiagramFlowExportFields.Quality"
          type="number"
          min="0.1"
          max="1"
          step="0.1"
          style="text-align: center"
        />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
  <button mat-raised-button mat-dialog-close fxFlex>
    <mat-icon> cancel </mat-icon>
    {{ 'common.close' | translate | uppercase }}
  </button>

  <button
    [disabled]="isExporting || form.invalid"
    (click)="onExportButtonClicked()"
    mat-raised-button
    fxFlex
    color="primary"
  >
    <mat-icon *ngIf="!isExporting"> insert_photo </mat-icon>

    {{ (isExporting ? 'diagram.Exporting' : 'diagram.Export') | translate | uppercase }}
  </button>

  <!-- <button [disabled]="form.invalid" (click)="onSaveButtonClicked()" mat-raised-button color="primary" fxFlex>
    <mat-icon> save </mat-icon>
    <span translate> Save </span>
  </button> -->
</mat-dialog-actions>
