<form [formGroup]="form" autocomplete="off">
  <div fxFlexFill>
    <div fxFlex="50%">
      <img fxFlex fxFill [src]="url" [ngClass]="{'imageDisabled': disabled}">
    </div>
    <div fxFlex="50%" fxLayout="column">
      <span [ngClass]="{'disabled': disabled}"> {{ title | translate}}</span>
      <input #file type="file" [hidden]="true" accept="image/*" formControlName="file" (change)="onFileSelect($event)">
      <button mat-flat-button color="primary" (click)="file.click()" [disabled]="disabled"> {{ 'user.Upload file' |
        translate }} </button>
    </div>
  </div>
</form>
