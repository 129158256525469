<div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="0.5rem" fxFlex fxFill>
  <!-- <div style="padding-top: 1em;" fxFlex="50px">
    <button mat-icon-button class="large" (click)="onMinusClick()">
      <mat-icon>
        remove_circle
      </mat-icon>
    </button>
  </div> -->

  <mat-form-field class="select-year" fxFlex appearance="outline" fxFlex>
    <mat-label>{{ label | translate | uppercase }}</mat-label>
    <button *ngIf="value" [disabled]="isDisabled" (click)="onPrevYearButtonClicked($event)" matPrefix mat-icon-button
      aria-label="prev year">
      <mat-icon>
        arrow_back
      </mat-icon>
    </button>

    <input matInput [ngModel]="value" [disabled]="isDisabled" (focus)="dp.open()" readonly style="text-align: center; "
      class="input-headline">
    <input [min]="minDate" [max]="maxDate" hidden="true" matInput [matDatepicker]="dp">
    <button *ngIf="value" [disabled]="isDisabled" (click)="onNextYearButtonClicked($event)" matSuffix mat-icon-button
      aria-label="next year">
      <mat-icon>arrow_forward</mat-icon>
    </button>

    <button *ngIf="value && showClearButton" [disabled]="isDisabled" (click)="onResetButtonClicked($event)" matSuffix
      mat-icon-button aria-label="next year">
      <mat-icon>cancel</mat-icon>
    </button>

    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>

    <mat-datepicker #dp [disabled]="isDisabled" startView="multi-year" (yearSelected)="chosenYearHandler($event, dp)">
    </mat-datepicker>


    <!-- <button mat-icon-button matPrefix>
      <mat-icon>
        add_circle
      </mat-icon>
    </button> -->
  </mat-form-field>


  <!-- <div style="padding-top: 1em;" fxFlex="50px">
    <button mat-icon-button class="large" (click)="onPlusClick()">
      <mat-icon>
        add_circle
      </mat-icon>
    </button>
  </div> -->
</div>
