import { IInfrastructureSpecificSchema } from './infrastructure-specific-schema.interface';

export class InfrastructureSpecificSchema {

  name: string;
  label: string;
  required: boolean;
  restriction: number;
  type: string;

  constructor(public infrastructureSpecificSchema: IInfrastructureSpecificSchema) {
    this.name = infrastructureSpecificSchema.name;
    this.label = `infrastructure.${infrastructureSpecificSchema.name}`;
    this.required = infrastructureSpecificSchema.required;
    this.restriction = infrastructureSpecificSchema.restriction;
    this.type = infrastructureSpecificSchema.type;
  }

}