<div>
  <mat-form-field fxFlex appearance="outline" class="mat-overwrite">
    <mat-label>
      {{ 'dashboard.boardPriorization.panelAdditionalRestrictions.riskMeasure' | translate | uppercase }}
    </mat-label>
    <mat-select [formControl]="formControl" (selectionChange)="onSelectChanged($event)" [multiple]="false">
      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterCtrl"
          (toggleAll)="toggleSelectAll($event)"
          showToggleAllCheckbox="true"
          toggleAllCheckboxTooltipMessage="{{ 'common.selectAll' | translate | uppercase }}"
          placeholderLabel="{{ 'common.search' | translate | uppercase }}"
          noEntriesFoundLabel="{{ 'common.itemNotFound' | translate | uppercase }}"
        >
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let item of filteredList" [value]="item" [disabled]="selectedMeasureIds.includes(item.id)">
        <div class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
          <span style="font-weight: bold">
            {{ item.name }}
          </span>
          <span style="font-size: small"> ({{ item.infrastructure?.name }}) </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
