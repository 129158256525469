import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from '@app/@shared/models/users/customers/customer.interface';
import { CustomersService } from '@app/@shared/models/users/customers/customers.service';
import { FindCustomersParam } from '@app/@shared/models/users/customers/inputs/find-customers.param';
import { SharedDataService } from '@app/@shared/services/shared-data.service';
import { UserService } from '@app/@shared/services/user.service';
import { AppConstants } from '@app/app-constants';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customer-selection-dialog',
  templateUrl: './customer-selection-dialog.component.html',
  styleUrls: ['./customer-selection-dialog.component.scss'],
})
export class CustomerSelectionDialogComponent implements OnInit {
  isLoading = false;
  list?: Customer[];
  private _query = '';
  currentUsername: string;
  public get query() {
    return this._query;
  }
  public set query(query) {
    this._query = query;
    if (query != null) {
      localStorage.setItem(`lastSelectCustomerQuery:${this.currentUsername}`, query);
    }
  }
  pageSize = 10;
  total = 0;
  get itemsLeft(): number {
    return this.list?.length < this.total ? this.total - this.list?.length : 0;
  }

  control = new FormControl();
  filterCtrl = new FormControl();

  private _onDestroy = new Subject();

  constructor(
    private customersService: CustomersService,
    private sharedDataService: SharedDataService,
    private router: Router,
    public dialogRef: MatDialogRef<CustomerSelectionDialogComponent>,
    private userService: UserService
  ) {
    this.currentUsername = this.userService.username();
    const query = localStorage.getItem(`lastSelectCustomerQuery:${this.currentUsername}`);
    if (query != null) {
      this.query = query;
    }

    this.filterCtrl.setValue(query);
  }

  ngOnInit(): void {
    this.sharedDataService
      .getSelectedCustomer()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((customer) => {
        if (!customer) {
          return;
        }
        const customerUsername = customer?.user_login;

        if (!customerUsername) {
          return;
        }

        if (this.control.value !== customerUsername) {
          this.control.setValue(customerUsername);
        }
      });

    this.filterCtrl.valueChanges
      .pipe(debounceTime(AppConstants.DEFAULT_DEBOUNCE))
      .pipe(takeUntil(this._onDestroy))
      .subscribe((query) => {
        this.query = query;
        this.pageSize = 10;
        this.loadData();
      });

    // this.control.valueChanges.subscribe((customerUsername) => {
    //   if (customerUsername) {
    //     this.router.navigate(['dashboard', 'customer', customerUsername]);
    //   } else {
    //     // this.router.navigate(['dashboard']);
    //   }
    //   this.sharedDataService.setSelectedCustomerUsername(customerUsername);
    // });

    this.loadData();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  loadData(): void {
    const param = new FindCustomersParam();
    param.query = this.query;
    param.pageSize = this.pageSize;
    this.isLoading = true;

    this.customersService.findCustomers(param).subscribe((result) => {
      this.list = result.items;

      this.total = result.total;

      this.isLoading = false;
    });
  }

  viewMore() {
    this.pageSize += 10;
    this.loadData();
  }

  onSelectCustomerButtonClicked(): void {
    const customerUsername = this.control.value;

    this.sharedDataService.setSelectedCustomerUsername(customerUsername);

    if (customerUsername) {
      this.router.navigate(['dashboard', 'customer', customerUsername]);
    } else {
      this.router.navigate(['dashboard', 'manager']);
    }
    this.dialogRef.close();
  }
}
