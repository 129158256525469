import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthModule } from '@app/auth';
import { CoreModule, I18nService } from '@core';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@shared/view-snippets/loader/loader.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';

export function getLocaleId(i18nService: I18nService) {
  const sessionLanguage = i18nService.getLanguage();
  return sessionLanguage;
}

registerLocaleData(localeEs, localeEsExtra);

@NgModule({
  imports: [
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    HomeModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    ShellModule,
    TranslateModule.forRoot(),
    NgxPermissionsModule.forRoot(),

    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent, LoaderComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: getLocaleId,
      deps: [I18nService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
