<table fxFlex *ngIf="riskModelList" [dataSource]="riskModelList" mat-table class="risk-model-table">
  <ng-container [matColumnDef]="RiskModelTableColumns.InitYear">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'common.initYear' | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let row" [ngClass]="{ active: row.is_active }">
      {{ row.init_year }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="RiskModelTableColumns.EndYear">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'common.endYear' | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let row" [ngClass]="{ active: row.is_active }">
      {{ row.end_year }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="RiskModelTableColumns.ProcessStart">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'infrastructure.tabRiskModels.processStart' | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let row" [ngClass]="{ active: row.is_active }">
      {{ row.dt_process_start | date: 'dd/MM/yy HH:mm' }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="RiskModelTableColumns.ProcessEnd">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'infrastructure.tabRiskModels.processEnd' | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let row" [ngClass]="{ active: row.is_active }">
      {{ row.dt_process_end | date: 'dd/MM/yy HH:mm' }}
    </td>
  </ng-container>

  <ng-container [matColumnDef]="RiskModelTableColumns.Status">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'infrastructure.tabRiskModels.status' | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let row" [ngClass]="{ active: row.is_active }">
      <app-process-status [status]="row.status"></app-process-status>
    </td>
  </ng-container>

  <!-- <ng-container [matColumnDef]="RiskModelTableColumns.Model">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'infrastructure.tabRiskModels.diagram' | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let row">
      <button (click)="openDiagram(row)" mat-icon-button [disabled]="row.status != 'PROCESSED'">
        <mat-icon>addchart</mat-icon>
      </button>
    </td>
  </ng-container> -->

  <ng-container [matColumnDef]="RiskModelTableColumns.Actions">
    <th mat-header-cell *matHeaderCellDef style="max-width: 200px">
      {{ 'common.actions' | translate | uppercase }}
    </th>
    <td mat-cell *matCellDef="let row">
      <app-risk-model-actions [riskModel]="row" (riskModelChanged)="riskModelChanged.emit($event)">
      </app-risk-model-actions>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<!-- <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
    [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator> -->
