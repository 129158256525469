import { InfrastructureType } from './infrastructure-type.model';
import { Pagination } from '@shared/models/pagination.model';

export class InfrastructureTypeList {

  constructor(
    public items: InfrastructureType[],
    public pagination: Pagination) {
  }

}