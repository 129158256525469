<div fxLayout="column" fxLayoutGap="0.5rem">
  <!-- <mat-divider fxFlex></mat-divider> -->
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem" style="padding-top: 12px">
    <mat-checkbox color="primary" [checked]="showLegends" (change)="showLegendsChanged.emit($event.checked)">
      {{ 'common.showLegends' | translate | uppercase }}
    </mat-checkbox>

    <mat-checkbox
      *ngIf="showLabelsCheckboxVisible"
      color="primary"
      [checked]="showLabels"
      (change)="showLabelsChanged.emit($event.checked)"
    >
      {{ 'common.showLabels' | translate | uppercase }}
    </mat-checkbox>

    <button mat-raised-button color="primary" (click)="buttonExportAsImageClicked.emit()">
      <mat-icon>insert_photo</mat-icon>
      {{ 'common.exportAsImage' | translate | uppercase }}
    </button>

    <button mat-raised-button color="primary" (click)="buttonExportDataClicked.emit()">
      <mat-icon>text_snippet</mat-icon>
      {{ 'common.exportData' | translate | uppercase }}
    </button>
  </div>
</div>
