import { HttpParams } from '@angular/common/http';
import { PaginatedParam } from '../../../paginated.param';

export class FindCustomersParam extends PaginatedParam {
  query: string;
  orderBy: string = 'name';

  toHttpParams(): HttpParams {
    const filterSchema = ['user_login', 'name', 'contact_name', 'contact_phone'];

    let params = super.toHttpParams();
    if (this.query) {
      params = params.set('_operator', 'or');
      filterSchema.forEach((key) => {
        params = params.set(`_filter_${key}`, this.query);
      });
    }
    params = params.set('_order_by', this.orderBy);
    return params;
  }
}
