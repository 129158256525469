import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@app/@shared/services/token.service';
import { UserService } from '@app/@shared/services/user.service';
import { from as observableFrom, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private tokenService: TokenService, private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return observableFrom(this.getHeaders()).pipe(
      switchMap((headers) => {
        if (headers) {
          return this.requestWithHeaders(req, next, headers);
        } else {
          return next.handle(req);
        }
      })
    );
  }

  private requestWithHeaders(req: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
    const headers: HttpHeaders = req.headers.set('Authorization', token);
    const headReq = req.clone({ headers: headers });
    return next.handle(headReq);
  }

  private getHeaders(): Promise<string | void> {
    return this.tokenService.getIdToken().catch((err) => {
      this.userService
        .logout()
        .then(() => this.router.navigate(['/login']))
        .then(() => false);
    });
  }
}
