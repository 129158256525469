export class AppConstants {
  public static readonly DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
  public static readonly DEFAULT_PAGE_SIZE = AppConstants.DEFAULT_PAGE_SIZE_OPTIONS[0];

  public static readonly DEFAULT_X_AXIS_MIN = 1;
  public static readonly DEFAULT_X_AXIS_MAX = 1e4;
  public static readonly DEFAULT_Y_AXIS_MIN = 1e-10;
  public static readonly DEFAULT_Y_AXIS_MAX = 1;

  public static readonly DEFAULT_C_VALUE = 0.01;
  public static readonly DEFAULT_N_VALUE = 1;

  public static readonly DEFAULT_DEBOUNCE = 400;
  public static readonly DEFAULT_GRAPH_FORM_MICROSECONDS_DEBOUNCE = 500;

  public static readonly DEFAULT_LEGEND_LEN = 10;
  public static readonly CHARTJS_TOOLTIPS_FRACTION_DIGITS_ = 4;

  public static readonly DEFAULT_IMAGE_EXPORT_WIDTH = 800;
  public static readonly DEFAULT_IMAGE_EXPORT_HEIGHT = 600;

  public static readonly DEFAULT_POSITION = { lat: 39.4636701, lng: -0.3457826 };
  public static readonly DEFAULT_ZOOM = 16;

  public static readonly DIAGRAM_FLOW_EXPORT_DEFAULT_FILE_NAME = 'flow-diagram';
  public static readonly DIAGRAM_FLOW_EXPORT_DEFAULT_QUALITY = 0.9;
  public static readonly DIAGRAM_FLOW_EXPORT_DEFAULT_PIXEL_RATIO = 3;

  public static readonly DIAGRAM_FLOW_EXPORT_DEFAULT_FORMAT = 'png';

  static readonly REPORT_MAX_PROCCESING_DURACTION_AT_HOURS = 1.0;
  static readonly RISK_MAX_MODEL_PROCCESING_DURACTION_AT_HOURS = 1.0;

  // public static readonly LOCAL_STORAGE_SELECTED_CUSTOMER_USERNAME_KEY = 'selectedCustomerUsername';
  // public static readonly CUSTOME_HTTP_HEADER_SELECTED_CUSTOMER_USERNAME_KEY = 'selected-customer-username';
}
