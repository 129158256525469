import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Logger } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { InfrastructureTypeList } from '@shared/models/infrastructure-type/infrastructure-type-list.model';
import { InfrastructureType } from '@shared/models/infrastructure-type/infrastructure-type.model';
import { InfrastructureTypeService } from '@shared/models/infrastructure-type/infrastructure-type.service';
import { Subscription } from 'rxjs';

const log = new Logger('SelectInfrasctructureTypeComponent');

@Component({
  selector: 'app-select-infrastructure-type',
  templateUrl: './select-infrastructure-type.component.html',
  styleUrls: ['./select-infrastructure-type.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInfrasctructureTypeComponent),
      multi: true,
    },
  ],
})
export class SelectInfrasctructureTypeComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() value = [];
  @Input() set disabledControl(disabledControl: boolean) {
    if (disabledControl) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  // InfrastructureTypeEnum = InfrastructureTypeEnum;
  formControl = new FormControl();
  list: InfrastructureType[] = [];
  allIds = new Array<number>();
  filteredList: InfrastructureType[] = [];
  isDisabled = false;
  filterCtrl: FormControl = new FormControl();
  isLoading = false;
  query: string;
  subs: Subscription;

  constructor(
    private infrasctructureTypeService: InfrastructureTypeService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.loadList();
    // this.selectAll();
    this.subs = this.filterCtrl.valueChanges.subscribe((query) => {
      this.filteredList = this.list.filter((value) => {
        const normalizedName = value.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        const normalizedQuery = query
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        return normalizedName.includes(normalizedQuery);
      });
    });
  }

  onChange: (value: number[]) => void = () => {};
  onTouched = () => {};

  loadList() {
    this.isLoading = true;

    this.infrasctructureTypeService.getAll().subscribe((result: InfrastructureTypeList) => {
      this.list = result.items;
      this.filteredList = this.list;
      this.isLoading = false;
      this.allIds = this.list.map((value) => value.id);
      this.toggleSelectAll(true);
      this.formControl.setValue(this.allIds);
    });
  }

  onSelectChanged(event: MatSelectChange) {
    const newSelect = event.value;
    this.value = newSelect;

    this.onTouched();
    this.onChange(this.value);
  }

  toggleSelectAll(value: boolean) {
    if (value === true) {
      this.selectAll();
    } else {
      this.clear();
    }
    this.onTouched();
    this.onChange(this.value);
  }

  selectAll() {
    this.value = this.allIds;
  }

  clear() {
    this.value = [];
  }

  getTriggerValue(): string {
    if (!Array.isArray(this.value)) {
      return;
    }

    let valueAsString: string;

    const itemCount = this.value.length;

    if (itemCount === 1) {
      valueAsString = this.list
        .filter((value) => value.id === this.value[0])
        .map((value) => value.name)
        .toString();
    } else if (this.isAllItemsSelected()) {
      valueAsString = this.translateService.instant('common.allInfrastructureTypesSelected');
    } else {
      valueAsString = this.translateService.instant('common.nInfrastructureTypesSelected', { itemCount });
    }

    return valueAsString;
  }

  isAllItemsSelected(): boolean {
    if (Array.isArray(this.value)) {
      const valueAsArray = this.value as number[];
      const isAllItemsSelected = this.allIds.every((id) => valueAsArray.includes(id));

      return isAllItemsSelected;
    }
    return false;
  }

  writeValue(value: number[]) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
