<h2 mat-dialog-title>{{ 'contact.Contact form' | translate | uppercase }}</h2>
<form (ngSubmit)="sendMessage()" [formGroup]="contactForm" novalidate>
  <mat-dialog-content class="mat-typography">
    <div fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'contact.Contact name' | translate | uppercase }}
        </mat-label>
        <input type="text" matInput formControlName="contactName" />
        <mat-error *ngIf="contactForm.controls.contactName.invalid && contactForm.controls.contactName.touched">
          <span>
            {{ 'contact.Contact name is required' | translate | uppercase }}
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'contact.Contact email' | translate | uppercase }}
        </mat-label>
        <input type="text" matInput formControlName="contactEmail" />
        <mat-error *ngIf="contactForm.controls.contactEmail.invalid && contactForm.controls.contactEmail.touched">
          <span>
            {{ 'contact.Contact email is required' | translate | uppercase }}
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'contact.Contact phone' | translate | uppercase }}
        </mat-label>
        <input type="number" matInput formControlName="contactPhone" />
        <mat-error *ngIf="contactForm.controls.contactPhone.invalid && contactForm.controls.contactPhone.touched">
          <span>
            {{ 'contact.Contact phone is required' | translate | uppercase }}
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'contact.Subject' | translate | uppercase }}
        </mat-label>
        <input type="text" matInput formControlName="subject" />
        <mat-error *ngIf="contactForm.controls.subject.invalid && contactForm.controls.subject.touched">
          <span>
            {{ 'contact.Subject is required' | translate | uppercase }}
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'contact.Message' | translate | uppercase }}</mat-label>
        <textarea matInput formControlName="message" rows="3"></textarea>
        <mat-error *ngIf="contactForm.controls.message.invalid && contactForm.controls.message.touched">
          <span>
            {{ 'contact.Message is required' | translate | uppercase }}
          </span>
        </mat-error>
      </mat-form-field>
    </div>
    <small>
      <p>This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </p>
    </small>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button fxFill mat-raised-button color="primary" type="submit" [disabled]="contactForm.invalid">
      <span>
        {{ 'common.accept' | translate | uppercase }}
      </span>
    </button>
  </mat-dialog-actions>
  <br>
</form>
