import { HttpParams } from '@angular/common/http';
import { PaginatedParam } from '../../paginated.param';

export class FindInfrasctructureNotificationRequestParam extends PaginatedParam {
  infrastructureId: number;

  constructor(init?: Partial<FindInfrasctructureNotificationRequestParam>) {
    super();
    Object.assign(this, init);
  }

  toHttpParams(): HttpParams {
    let params = super.toHttpParams().set('_order_by', '-notification_datetime');

    if (this.infrastructureId != null) {
      params = params.set('_filter_infrastructure_id__exact', this.infrastructureId.toString());
    }

    return params;
  }
}
