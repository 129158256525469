import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RiskModel } from '@shared/models/risk-models/risk-model';

enum RiskModelTableColumns {
  InitYear = 'InitYear',
  EndYear = 'EndYear',
  ProcessStart = 'ProcessStart',
  ProcessEnd = 'ProcessEnd',
  Status = 'Status',
  // Model = 'Model',
  Actions = 'Actions',
}

@Component({
  selector: 'app-risk-model-table',
  templateUrl: './risk-model-table.component.html',
  styleUrls: ['./risk-model-table.component.scss'],
})
export class RiskModelTableComponent implements OnInit {
  @Input() riskModelList: RiskModel[];
  @Output() openRiskModelDiagram = new EventEmitter<number>();
  @Output() riskModelChanged = new EventEmitter<void>();

  readonly RiskModelTableColumns = RiskModelTableColumns;
  readonly displayedColumns = [
    RiskModelTableColumns.InitYear,
    RiskModelTableColumns.EndYear,
    RiskModelTableColumns.ProcessStart,
    RiskModelTableColumns.ProcessEnd,
    RiskModelTableColumns.Status,
    // RiskModelTableColumns.Model,
    RiskModelTableColumns.Actions,
  ];

  ngOnInit() {}

  // openDiagram(riskModel: RiskModel) {
  //   this.openRiskModelDiagram.emit(riskModel.id);
  // }

  // onRiskModelDeleted(riskModelId: number): void {
  //   this.riskModelDeleted.emit(riskModelId);
  // }
}
