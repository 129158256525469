import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Customer } from '@shared/models/users/customers/customer.interface';
import { FindCustomersParam } from '@app/@shared/models/users/customers/inputs/find-customers.param';
import { AppConstants } from '@app/app-constants';
import { CustomersService } from '@shared/models/users/customers/customers.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
})
export class SelectCustomerComponent implements OnInit {
  @Output() customerSelected = new EventEmitter<Customer>();

  isLoading = false;
  list?: Customer[];
  control = new FormControl();
  filterCtrl = new FormControl();
  query?: string;

  constructor(private customersService: CustomersService) {}

  ngOnInit(): void {
    this.filterCtrl.valueChanges.pipe(debounceTime(AppConstants.DEFAULT_DEBOUNCE)).subscribe((query) => {
      this.query = query;
      this.loadData();
    });
    this.control.valueChanges.subscribe((customer) => {
      this.customerSelected.emit(customer);
    });

    this.loadData();
  }

  loadData(): void {
    const param = new FindCustomersParam();
    param.query = this.query;
    param.pageSize = 10;

    this.isLoading = true;
    this.customersService.findCustomers(param).subscribe((result) => {
      this.list = result.items;

      this.isLoading = false;
    });
  }
}
