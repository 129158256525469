<mat-form-field fxFlex appearance="outline" class="mat-overwrite">
  <mat-label>
    {{ 'common.infrastructureType' | translate | uppercase }}
  </mat-label>

  <mat-select [formControl]="formControl" (selectionChange)="onSelectChanged($event)" [multiple]="true" [value]="value">
    <mat-select-trigger>
      {{ getTriggerValue() }}
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search [formControl]="filterCtrl" (toggleAll)="toggleSelectAll($event)"
        showToggleAllCheckbox="true" [toggleAllCheckboxChecked]="isAllItemsSelected()"
        toggleAllCheckboxTooltipMessage="{{ 'common.selectAll' | translate | uppercase }}"
        placeholderLabel="{{ 'common.search' | translate | uppercase }}"
        noEntriesFoundLabel="{{ 'common.itemNotFound' | translate | uppercase }}">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredList" [value]="item.id">
      <div *ngIf="item.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
        <!-- {{ item.name }} -->
        <!-- {{ ('infrastructureType.' + InfrastructureTypeEnum[ item.id ]) | translate | uppercase}} -->
        {{ item.id | infrastructureTypeName }}
      </div>
    </mat-option>
  </mat-select>
  <mat-hint> {{ 'common.selectOneOrMoreInfrastructureTypes' | translate }}</mat-hint>
</mat-form-field>
