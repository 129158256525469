import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-infrastructure-multiple',
  templateUrl: './select-infrastructure-multiple.component.html',
  styleUrls: ['./select-infrastructure-multiple.component.scss'],
})
export class SelectInfrastructureMultipleComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: FormControl;

  private _list: Infrastructure2[];
  public get list(): Infrastructure2[] {
    return this._list;
  }
  @Input() public set list(list: Infrastructure2[]) {
    this._list = list;
    this.filteredList = list;
  }

  filteredList: Infrastructure2[] = [];
  isDisabled = false;
  filterCtrl = new FormControl();

  isLoading = false;

  private _onDestroy = new Subject();

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.filterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((query) => {
      this.filteredList = this.list.filter((value) => {
        const normalizedName = value.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        const normalizedQuery = query
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        return normalizedName.includes(normalizedQuery);
      });
    });
  }

  ngAfterViewInit() {
    this.selectAll();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(value: boolean) {
    if (value === true) {
      this.selectAll();
    } else {
      this.clear();
    }
  }

  selectAll() {
    const allIds = this.list.map((value) => value.id);
    this.control.setValue(allIds);
  }

  clear() {
    this.control.setValue([]);
  }

  getTriggerValue(): string {
    let valueAsString: string;

    const controlValue = this.control.value;

    if (Array.isArray(controlValue)) {
      const infrastructureIdList = controlValue;
      const itemCount = infrastructureIdList.length;

      if (itemCount === 1) {
        valueAsString = this.list
          .filter((infrastructure) => infrastructure.id === infrastructureIdList[0])
          .map((infrastructure) => infrastructure.name)
          .toString();
      } else {
        valueAsString = this.translateService.instant('common.nInfrastructuresSelected', { itemCount }); //`${itemCount} infrastructures selected`;
      }
    } else {
      //TODO: Implement single value behaviour
    }
    return valueAsString;
  }
}
