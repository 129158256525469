<h2 mat-dialog-title>{{ 'login.Change password' | translate | uppercase }}</h2>
<form (ngSubmit)="changePassword()" [formGroup]="form" novalidate>
  <mat-dialog-content class="mat-typography">
    <div fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'login.Current password' | translate | uppercase }}</mat-label>
        <input type="password" matInput formControlName="currentPassword" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label> {{ 'login.New password' | translate | uppercase }}</mat-label>
        <input type="password" matInput formControlName="newPassword" />
        <mat-error *ngIf="form.controls.newPassword.invalid && form.controls.newPassword.touched">
          <span>
            {{ 'common.passwordRequeriments' | translate | uppercase }}
          </span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'login.Confirm new password' | translate | uppercase }}
        </mat-label>
        <input type="password" matInput formControlName="confirmPassword" autocomplete="confirm-password"
          [placeholder]="'login.Confirm new password' | translate" />
        <mat-error *ngIf="form.controls.confirmPassword.invalid && form.controls.confirmPassword.touched">
          <span>
            {{ 'login.Passwords do not mismatch' | translate | uppercase }}
          </span>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button fxFill mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
      {{ 'common.accept' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
  <br>
</form>
