import { UserRoleEnum } from '@shared/models/users/users/user-role.enum';

export const basesettings = {
  defaultLanguage: 'es-ES',
  passwordPattern:
    '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=[^<>{}"/|;:.,~!?@#$%^=&*]*[<>{}"/|;:.,~!?@#$%^=&*]).{8,}',
  supportedLanguages: [
    {
      code: 'en-US',
      label: 'common.english',
    },
    {
      code: 'es-ES',
      label: 'common.spanish',
    },
  ],
  views: [
    {
      link: '/dashboard',
      label: 'common.dashboard',
      roles: [UserRoleEnum.All],
    },
    {
      link: '/users',
      label: 'common.users',
      roles: [UserRoleEnum.Manager],
    },
    {
      link: '/users/customers',
      label: 'common.customers',
      roles: [UserRoleEnum.Manager, UserRoleEnum.Engineer],
    },
    {
      link: '/infrastructures',
      label: 'common.infrastructures',
      roles: [UserRoleEnum.Manager, UserRoleEnum.Engineer],
    },
  ],
};
