import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IConfirmConfig } from '../models/dialogs.interfaces';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  title: string;
  message: string;
  acceptButton: string;
  cancelButton: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: IConfirmConfig) {
    this.title = data.title || 'common.confirm';
    this.message = data.message;
    this.acceptButton = data.acceptButton || 'common.accept';
    this.cancelButton = data.cancelButton || 'common.cancel';
  }
}
