<form fxFlex fxLayout="column" fxLayoutGap="1.0rem" [formGroup]="form" autocomplete="off">
  <div fxFlex fxLayout="column" fxLayoutGap="1.0rem">
    <div fxFlex fxLayout="column" fxLayoutGap="1.0rem">
      <div fxFlex fxLayout="column">
        <div fxFlex fxLayout="row">
          <div fxFlex fxLayout="column">
            <div>
              <img
                *ngIf="infrastructure?.signed_path_image && mode == FormMode.View"
                [src]="infrastructure?.signed_path_image"
                style="max-height: 100%; max-width: 100%"
              />
            </div>
          </div>
        </div>

        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5rem">
          <div fxFlex>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>
                {{ 'common.infrastructureType' | translate | uppercase }}
              </mat-label>

              <mat-select
                formControlName="infrastructure_type_id"
                (selectionChange)="onInfrastructureTypeChange($event.value)"
              >
                <mat-option *ngFor="let infrastructureType of infrastructureTypeList" [value]="infrastructureType.id">
                  {{ infrastructureType.id | infrastructureTypeName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls.infrastructure_type_id.hasError('required')">
                {{ 'common.thisFieldIsRequired' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <mat-form-field fxFlex appearance="outline">
            <mat-label>{{ 'infrastructure.Typology' | translate | uppercase }}</mat-label>
            <input matInput formControlName="typology" />
            <mat-error *ngIf="form.controls.typology.hasError('required')">
              {{ 'common.thisFieldIsRequired' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5rem">
          <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline">
            <mat-label>{{ 'infrastructure.Name' | translate | uppercase }}</mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="form.controls.name.hasError('required')">
              {{ 'common.thisFieldIsRequired' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline">
            <mat-label>{{ 'infrastructure.Tag' | translate | uppercase }}</mat-label>
            <input matInput formControlName="tag" />
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5rem">
          <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline">
            <mat-label>{{ 'infrastructure.Region' | translate | uppercase }}</mat-label>
            <input matInput formControlName="region" />
          </mat-form-field>
          <mat-form-field fxFlex="50%" fxFlex.xs="100%" appearance="outline">
            <mat-label>{{ 'infrastructure.Location' | translate | uppercase }}</mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5rem">
          <app-select-year formControlName="build_date" [label]="'common.buildDate' | translate | uppercase" fxFlex>
          </app-select-year>

          <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.5rem">
            <mat-slide-toggle color="primary" formControlName="is_risk_incremental">
              {{ 'infrastructure.Risk incremental' | translate | uppercase }}
            </mat-slide-toggle>
            <mat-slide-toggle color="primary" formControlName="is_risk_total">
              {{ 'infrastructure.Risk total' | translate | uppercase }}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div fxFlex fxLayout="column">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'infrastructure.Ubication' | translate | uppercase }}</mat-label>
          <input
            matInput
            formControlName="ubication"
            [placeholder]="'infrastructure.EnterTheCoordinatesInDecimalDegrees' | translate"
          />
          <mat-hint *ngIf="mode !== FormMode.View">
            {{ 'infrastructure.TheCoordinatesMustBeLike' | translate }}
          </mat-hint>
        </mat-form-field>
        <app-infrastructure-map [form]="form" [infrastructure]="infrastructure" [mode]="mode"></app-infrastructure-map>
      </div>
    </div>

    <div
      *ngIf="specificSchema?.length && infrastructureTypeList?.length"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center start"
      fxLayoutGap="0.5rem"
    >
      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0.5rem">
        <!-- <app-specific-information-form
          [parentForm]="form"
          [infrastructure]="infrastructure"
        ></app-specific-information-form> -->

        <span
          ><strong>{{ 'infrastructure.Infrastructure specific information' | translate | uppercase }}</strong></span
        >
      </div>
      <div fxFlex fxLayout="row wrap" formGroupName="specific_information" fxLayoutGap="0.5rem">
        <div *ngFor="let control of specificSchema" fxLayout="row" fxFlex fxFlex.xs="100%">
          <!--
            Duplicate input to avoid known angular bug binding [type]
            See https://github.com/angular/angular/issues/13243
          -->
          <mat-form-field *ngIf="control.type === 'text'" appearance="outline" fxFlex>
            <mat-label> {{ 'specificSchema.' + control.name | translate }}</mat-label>
            <input type="text" matInput [formControlName]="control.name" [required]="control.required" />
            <mat-error>
              {{ 'common.thisFieldIsRequired' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="control.type === 'number'" appearance="outline" fxFlex>
            <mat-label> {{ control.errors | json }} {{ 'specificSchema.' + control.name | translate }}</mat-label>
            <input
              type="number"
              matInput
              [formControlName]="control.name"
              [required]="control.required"
              style="text-align: right"
            />
            <mat-error>
              {{ 'common.thisFieldIsRequired' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
