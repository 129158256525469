import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Logger } from '@core/services/logger.service';
import { RiskMeasure } from '@shared/models/risk-measure/risk-measure';
import { RiskMeasuresService } from '@shared/models/risk-measure/risk-measures.service';
import { Subscription } from 'rxjs';
import { SharedDataService } from '../../services/shared-data.service';


const log = new Logger('SelectRiskRestrictionTypeComponent');

@Component({
  selector: 'app-select-risk-measure',
  templateUrl: './select-risk-measure.component.html',
  styleUrls: ['./select-risk-measure.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectRiskMeasureComponent),
    multi: true
  }]
})
export class SelectRiskMeasureComponent implements OnInit, OnDestroy, ControlValueAccessor {
  // @Input()
  value: RiskMeasure;

  // @Input()
  // name: string;


  @Input() list: RiskMeasure[];


  @Input() set selectedMeasureIds(ids: number[]) {
    this._selectedMeasureIds = ids.filter(value => value !== this.value?.id);
    this.calculateFilteredList();
  }
  get selectedMeasureIds(): number[] {
    return this._selectedMeasureIds;
  }

  filteredList: RiskMeasure[] = [];
  isDisabled = false;
  filterCtrl: FormControl = new FormControl([]);
  formControl: FormControl = new FormControl([]);
  isLoading = false;
  isSearchingCurrentStation = false;
  query = '';
  subs: Subscription;
  selectedInfrastructureIds?: number[];

  private _selectedMeasureIds = [];


  constructor(
    private riskMeasuresService: RiskMeasuresService,
    private sharedData: SharedDataService) {

  }

  ngOnInit() {
    this.loadList();
    // listen for search field value changes
    this.subs = this.filterCtrl.valueChanges
      .subscribe((query) => {
        this.query = query;
        this.calculateFilteredList();
      });


    this.sharedData.getSelectedInfrastructureIdList().subscribe(selectedInfrastructureIds => {
      this.selectedInfrastructureIds = selectedInfrastructureIds;
      if (this.selectedInfrastructureIds) {
        this.loadList();
      }
    });
  }

  calculateFilteredList() {
    // this.loadList();

    this.filteredList = this.list.filter((value) => {
      const normalizedName = value.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase('es-ES');
      const normalizedQuery = this.query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase('es-ES');
      return normalizedName.includes(normalizedQuery);
    });
  }

  onChange: (value: RiskMeasure) => void = () => { };
  onTouched = () => { };

  loadList() {
    this.isLoading = true;

    this.riskMeasuresService.findMeasuresByInfrastructureIds(this.selectedInfrastructureIds)
      .subscribe((list: RiskMeasure[]) => {
        this.query = '';
        this.list = list;
        this.filteredList = list;
        this.isLoading = false;
      });
  }

  onSelectChanged(event: MatSelectChange) {
    const newSelect = event.value;
    this.value = newSelect;
    this.onTouched();
    // this.on
    this.onChange(this.value);
  }

  toggleSelectAll(value: boolean) {

  }

  writeValue(value: RiskMeasure) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
