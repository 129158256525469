import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentProviderOptions, RxZuDefaultsModule, RxZuModule } from '@rxzu/angular';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPermissionsModule } from 'ngx-permissions';
import { COMPONENTS } from './components';
import { DefaultFormActionsComponent } from './components/default-form-actions/default-form-actions.component';
import { DiagramFlowExportDialogComponent } from './components/diagram-flow/diagram-flow-export-dialog/diagram-flow-export-dialog.component';
import {
  RiskManagementLabelComponent,
  RiskManagementLinkComponent,
  RiskManagementNodeComponent,
  RiskManagementPortComponent,
} from './components/diagram-flow/themes/risk-management/components';
import { UploadFileDialogComponent } from './components/dialogs/upload-file-dialog/upload-file-dialog.component';
import { DamSpecificInformationFormComponent } from './components/forms/infrastructure-form/specific-information-form/dam-specific-information-form/dam-specific-information-form.component';
import { SpecificInformationFormComponent } from './components/forms/infrastructure-form/specific-information-form/specific-information-form.component';
import { GraphFooterComponent } from './components/graph-footer/graph-footer.component';
import { InfrastructureDetailActionsComponent } from './components/infrastructure-detail-actions/infrastructure-detail-actions.component';
import { InfrastructureNotificationRequestCreationDialogComponent } from './components/infrastructure-notification-request-list/infrastructure-notification-request-creation-dialog/infrastructure-notification-request-creation-dialog.component';
import { InfrastructureNotificationRequestListTableComponent } from './components/infrastructure-notification-request-list/infrastructure-notification-request-list-table/infrastructure-notification-request-list-table.component';
import { RiskModelActionsComponent } from './components/risk-model-list/risk-model-table/risk-model-actions/risk-model-actions.component';
import { SelectCustomerComponent } from './components/select-customer/select-customer.component';
import { DIALOG_COMPONENTS, DIALOG_SERVICE } from './dialogs';
import { DIRECTIVES } from './directives';
import { MaterialModule } from './material/material.module';
import { MODELS_SERVICES } from './models';
import { PIPES } from './pipes';
import { ReportProcessTimePipe } from './pipes/report-process-time.pipe';
import { RestrictionTypeTranslatedDescriptionPipe } from './pipes/restriction-type-translated-description.pipe';
import { SERVICES } from './services';
import { VIEW_SNIPPETS } from './view-snippets';
import { ReportListTableActionsComponent } from './components/report-list/report-list-table/report-list-table-actions/report-list-table-actions.component';

// import { InfrastructureNotificationRequestListComponent } from './components/infrastructure-notification-request-list/infrastructure-notification-request-list.component';

const RiskManagementTheme: ComponentProviderOptions[] = [
  {
    type: 'node',
    component: RiskManagementNodeComponent,
    namespace: 'risk-management',
  },
  {
    type: 'port',
    component: RiskManagementPortComponent,
    namespace: 'risk-management',
  },
  {
    type: 'link',
    component: RiskManagementLinkComponent,
    namespace: 'risk-management',
  },
  {
    type: 'label',
    component: RiskManagementLabelComponent,
    namespace: 'risk-management',
  },
];

const COMPONENTS_DIAGRAM = [
  RiskManagementNodeComponent,
  RiskManagementPortComponent,
  RiskManagementLinkComponent,
  RiskManagementLabelComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    GoogleMapsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
    RecaptchaV3Module,
    NgxMatSelectSearchModule,
    RxZuDefaultsModule,
    RxZuModule.withComponents(RiskManagementTheme),
    NgxPermissionsModule,
  ],
  declarations: [
    DIALOG_COMPONENTS,
    DIRECTIVES,
    PIPES,
    VIEW_SNIPPETS,
    COMPONENTS,
    GraphFooterComponent,

    DefaultFormActionsComponent,
    SelectCustomerComponent,
    ReportProcessTimePipe,
    COMPONENTS_DIAGRAM,
    SpecificInformationFormComponent,
    RiskModelActionsComponent,
    UploadFileDialogComponent,
    InfrastructureNotificationRequestListTableComponent,
    InfrastructureNotificationRequestCreationDialogComponent,
    InfrastructureDetailActionsComponent,
    RestrictionTypeTranslatedDescriptionPipe,
    DamSpecificInformationFormComponent,
    DiagramFlowExportDialogComponent,
    ReportListTableActionsComponent,
    // InfrastructureNotificationRequestListComponent,
    // ReportListFilterComponent,
    // ActiveCustomerSelectorComponent,
    // EngineerActiveCustomerSelectorComponent,
    // ManagerActiveCustomerSelectorComponent,
    // SelectInfrastructureSingleComponent,
  ],
  exports: [
    CommonModule,
    DIRECTIVES,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    GoogleMapsModule,
    PIPES,
    ReactiveFormsModule,
    RecaptchaV3Module,
    NgxMatSelectSearchModule,
    TranslateModule,
    VIEW_SNIPPETS,
    COMPONENTS,
    COMPONENTS_DIAGRAM,
    NgxPermissionsModule,
  ],
  entryComponents: [DIALOG_COMPONENTS, COMPONENTS_DIAGRAM],
  providers: [
    DIALOG_SERVICE,
    MODELS_SERVICES,
    SERVICES,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
  ],
})
export class SharedModule {}
