import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent {

  @Output() openWebsiteEvent: EventEmitter<void> = new EventEmitter();
  @Output() contactEvent: EventEmitter<void> = new EventEmitter();
  @Output() helpEvent: EventEmitter<void> = new EventEmitter();

  constructor() { }

  openWebsite() {
    this.openWebsiteEvent.emit();
  }

  contact() {
    this.contactEvent.emit();
  }

  help() {
    this.helpEvent.emit();
  }
}
