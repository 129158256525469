<button
  *ngIf="!($isLoadingSelectedCustomer | async); else loading"
  [matTooltip]="'user.SelectCustomer' | translate"
  (click)="onChangeCustomerButtonClick()"
  mat-flat-button
  style="height: 60px"
  color="primary"
>
  <div *ngIf="selectedCustomer" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem">
    <div style="height: 50px; width: 50px; line-height: 50px; background-color: white">
      <img
        style="max-height: 100%; max-width: 100%; margin: 0 auto; vertical-align: middle; display: inline-block"
        [src]="selectedCustomer.signed_path_logo"
      />
    </div>
    <!-- <div fxFlex="60px">
      <img *ngIf="selectedCustomer.signed_path_logo" [src]="selectedCustomer.signed_path_logo" fxFlex="60px" fxFill style="background-color: white" />
    </div> -->
    <div class="mat-typography" style="font-size: 20px">
      {{ selectedCustomer.name }}
    </div>
  </div>

  <div *ngIf="!selectedCustomer" fxLayout="row" fxLayoutGap="0.5rem">
    <!-- <span>
      {{ 'common.noCustomerSelected' | translate }}
    </span>
    <span> -->
    {{ 'common.clickToSelectOne' | translate }}
    <!-- </span> -->
  </div>
</button>
<ng-template #loading>
  <mat-spinner diameter="40" color="accent"></mat-spinner>
</ng-template>

<!-- <button (click)="onChangeCustomerButtonClick()" mat-raised-button color="accent" style="height: 40px">
    <mat-icon> person_search </mat-icon>
    {{ 'user.SelectCustomer' | translate | uppercase }}
  </button> -->
