<div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="center center" class="shell-header">
  <mat-toolbar style="background: none; color: white">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-around center">
      <!-- [LEFT] Title current page -->
      <div fxFlex fxLayout="row" fxLayoutAlign="start center">
        <button class="menu-button" mat-icon-button (click)="toggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
        <span>{{ title }}</span>
      </div>
    </div>
  </mat-toolbar>

  <ng-template *ngIf="showSelectedCustomer()" [ngxPermissionsOnly]="['Manager', 'Engineer']">
    <app-selected-customer></app-selected-customer>
  </ng-template>

  <!-- <app-active-customer-selector
    *ngIf="roleId !== UserRoleEnum.Customer && urlStartWihDasboard"
    [roleId]="roleId"
    fxFlex="500px"
  >
  </app-active-customer-selector> -->

  <mat-toolbar style="background: none; color: white" fxFlex="0">
    <!-- [RIGHT] Translate + Username -->
    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
      <app-language-selector icon="true"></app-language-selector>
      <a mat-button [matMenuTriggerFor]="userMenu">
        <mat-icon matListIcon>account_circle</mat-icon>
        <span class="header-username">{{ username }}</span>
      </a>

      <mat-menu #userMenu="matMenu">
        <button mat-menu-item disabled="true">{{ 'UserRole.' + UserRoleEnum[roleId] | translate | uppercase }}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="updatePassword()">{{ 'shell.Update password' | translate | uppercase }}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">{{ 'shell.Logout' | translate | uppercase }}</button>
      </mat-menu>

      <button mat-icon-button (click)="getHelp()">
        <mat-icon>help</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>
