<div [formGroup]="form" fxLayout="row" fxLayout.lt-sm="column">
  <mat-form-field appearance="outline" fxFlex>
    <mat-label> {{ 'specificSchema.crowning_length' | translate }} </mat-label>
    <input
      type="number"
      matInput
      [formControlName]="DamSpecificInformationFieldsEnum.CrowningLength"
      style="text-align: right"
    />
    <div matSuffix style="position: relative; bottom: 4px">m</div>
    <mat-error>
      {{ 'common.thisFieldIsRequired' | translate }}
    </mat-error>
  </mat-form-field>
</div>
