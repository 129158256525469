import { Title } from '@angular/platform-browser';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { ChangePasswordComponent } from '@app/auth/change-password/change-password.component';
import { environment } from '@env/environment';
import { IView } from '../models/view.interface';

import { UserRoleEnum } from '@shared/models/users/users/user-role.enum';
import { UserService } from '@app/@shared/services/user.service';
import { AuthenticationService } from '@app/@shared/services/authentication.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent {
  @ViewChild('sidenav') sidenav;

  roleId: UserRoleEnum;
  views: IView[];

  constructor(
    private router: Router,
    private titleService: Title,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private dialogService: DialogsService
  ) {
    this.getRoleAndViews();
  }

  get username(): string | null {
    const username = this.userService.username();
    return username ? username : null;
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  openLink(link: string) {
    this.router.navigate([link], { replaceUrl: true });
    this.sidenav.close();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  logout() {
    this.dialogService
      .openConfirm({
        title: 'shell.Confirm logout',
        message: 'shell.Are you sure to logout',
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.doLogout();
        }
      });
  }

  updatePassword() {
    this.dialogService.open(ChangePasswordComponent).subscribe((changePass) => {
      if (changePass) {
        this.authenticationService
          .changePassword(changePass.currentPassword, changePass.newPassword)
          .then(() => this.dialogService.openSnackBar({ message: 'shell.Password updated' }))
          .catch((error) => this.dialogService.openSnackBar({ message: error.err.message }));
      }
    });
  }

  private doLogout() {
    this.userService.logout().then(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  private getRoleAndViews() {
    this.userService.roleId().then((roleId: UserRoleEnum) => {
      this.roleId = roleId;
      this.views = environment.views.filter(
        (view) => view.roles.includes(UserRoleEnum.All) || view.roles.includes(roleId)
      );
    });
  }
}
