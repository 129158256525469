import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ContactService {

  constructor(private httpClient: HttpClient) { }

  send(query: any): Observable<any> {
    return this.httpClient.disableToken().post('/contact', query);
  }
}
