import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Logger } from '@core';
import { RiskRestrictionType } from '@shared/models/risk-restriction-types/risk-restriction-type';
import { RiskRestrictionTypesService } from '@shared/models/risk-restriction-types/risk-restriction-types.service';
import { Subscription } from 'rxjs';

const log = new Logger('SelectRiskRestrictionTypeComponent');

@Component({
  selector: 'app-select-risk-restriction-type',
  templateUrl: './select-risk-restriction-type.component.html',
  styleUrls: ['./select-risk-restriction-type.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectRiskRestrictionTypeComponent),
    multi: true
  }]
})
export class SelectRiskRestrictionTypeComponent implements OnInit, OnDestroy, ControlValueAccessor {

  value: number;
  list?: RiskRestrictionType[];
  filteredList?: RiskRestrictionType[];
  isDisabled = false;
  filterCtrl: FormControl = new FormControl();
  formControl: FormControl = new FormControl([]);
  isLoading = false;
  isSearchingCurrentStation = false;
  query: string;
  subs: Subscription;


  constructor(private riskRestrictionTypesService: RiskRestrictionTypesService) { }

  ngOnInit() {
    this.loadList();

    // listen for search field value changes
    this.subs = this.filterCtrl.valueChanges
      .subscribe((query) => {
        this.filteredList = this.list.filter((value) => {
          const normalizedName = value.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase('es-ES');
          const normalizedQuery = query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase('es-ES');
          return normalizedName.includes(normalizedQuery);
        });
      });
  }

  onChange: (value: number) => void = () => { };
  onTouched = () => { };

  loadList() {
    this.isLoading = true;

    this.riskRestrictionTypesService.getAllRiskRestrictionTypes()
      .subscribe((list: RiskRestrictionType[]) => {
        this.list = list;
        this.filteredList = list;
        this.isLoading = false;
      });
  }



  onSelectChanged(event: MatSelectChange) {
    const newSelect = event.value;
    this.value = newSelect;
    this.onTouched();
    // this.on
    this.onChange(this.value);
  }

  toggleSelectAll(value: boolean) {

  }

  writeValue(value: number) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

}
