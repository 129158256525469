import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../paginated-result.interface';
import { CreateRiskMeasuresParam } from './params/create-risk-measure.param';
import { EditRiskMeasuresParam } from './params/edit-risk-measure.param';
import { RiskMeasure } from './risk-measure';
import { RiskMeasureAmount } from './risk-measure-amount';

@Injectable({
  providedIn: 'root',
})
export class RiskMeasuresService {
  constructor(private http: HttpClient) {}

  findAllRiskMeasures(): Observable<RiskMeasure[]> {
    const url = '/risk_measures';
    const params = new HttpParams().set('_page_size', '0').set('_order_by', 'name');

    return this.http.get<PaginatedResult<RiskMeasure>>(url, { params }).pipe(map((result) => result.items));
  }

  findAllSourceRiskMeasures(): Observable<RiskMeasure[]> {
    const url = '/risk_measures';
    const params = new HttpParams()
      .set('_filter_measure_source_id__isnull', '1')
      .set('_page_size', '0')
      .set('_order_by', 'name');

    return this.http.get<PaginatedResult<RiskMeasure>>(url, { params }).pipe(map((result) => result.items));
  }

  findMeasuresByInfrastructureId(infrastructureId: number): Observable<RiskMeasure[]> {
    const infrastructureIds = [infrastructureId];
    return this.findMeasuresByInfrastructureIds(infrastructureIds);
  }

  findMeasuresByInfrastructureIds(infrastructureIds: number[]): Observable<RiskMeasure[]> {
    if (infrastructureIds == null) {
      throw new Error('infrastructureIds is not set');
    }
    const infrastructureIdsAsString = infrastructureIds.toString();
    const url = '/risk_measures';
    const params = new HttpParams()
      .set('_page_size', '0')
      .set('_order_by', 'name')
      .set('_filter_infrastructure_id__in', infrastructureIdsAsString);

    return this.http.get<PaginatedResult<RiskMeasure>>(url, { params }).pipe(map((result) => result.items));
  }

  createRiskMeasure(createRiskMeasuresParam: CreateRiskMeasuresParam): Observable<{ id: number }> {
    const url = '/risk_measure';
    return this.http.post<{ id: number }>(url, createRiskMeasuresParam);
  }

  updateRiskMeasure(param: EditRiskMeasuresParam): Observable<RiskMeasure> {
    const url = '/risk_measure';
    return this.http.patch<RiskMeasure>(url, param);
  }

  createRiskMeasureAmount(riskMeasureAmount: RiskMeasureAmount): Observable<{ id: number }> {
    const url = '/risk_measure_amount';
    return this.http.post<{ id: number }>(url, riskMeasureAmount);
  }

  updateRiskMeasureAmount(riskMeasureAmount: RiskMeasureAmount): Observable<RiskMeasureAmount> {
    const url = '/risk_measure_amount';
    return this.http.patch<RiskMeasureAmount>(url, riskMeasureAmount);
  }

  deleteRiskMeasureAmount(id: number): Observable<any> {
    const url = '/risk_measure_amount';
    const params = new HttpParams().set('id', id.toString());
    return this.http.delete(url, { params });
  }

  deleteDiagramNode(query: any): Observable<number> {
    return this.http
      .delete('/infrastructure_risk_model_diagram/node', { params: query })
      .pipe(map((node: any) => node));
  }

  getRiskMeasureCountByCustomer(customerId: number): Observable<number> {
    const url = '/risk_measures';
    const params = new HttpParams().set('_page_size', '1');

    // TODO: Allow filter by customer_id.
    // This will be necessary when there are roles that allow managing customers
    // .set('_filter_customer_id__exact', customerId.toString());

    return this.http.get<PaginatedResult<RiskMeasure>>(url, { params }).pipe(map((result) => result.total));
  }

  updateMeasureImplementationYear(measureId: number, implementationYear: number): Observable<RiskMeasure> {
    const url = '/risk_measure';
    const body = { id: measureId, implementation_year: implementationYear };

    return this.http.patch<RiskMeasure>(url, body);
  }

  deleteRiskMeasure(riskMeasureId: number): Observable<any> {
    const url = '/risk_measure';

    const params = new HttpParams().set('id', riskMeasureId.toString());

    return this.http.delete(url, { params });
  }
}
