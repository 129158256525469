import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-graph-footer',
  templateUrl: './graph-footer.component.html',
  styleUrls: ['./graph-footer.component.scss'],
})
export class GraphFooterComponent implements OnInit {
  @Input() showLegends = true;
  @Input() showLabels = true;
  @Input() showLabelsCheckboxVisible = true;

  @Output() showLegendsChanged = new EventEmitter<boolean>();
  @Output() showLabelsChanged = new EventEmitter<boolean>();
  @Output() buttonExportAsImageClicked = new EventEmitter<void>();
  @Output() buttonExportAsExcelClicked = new EventEmitter<void>();
  @Output() buttonExportDataClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
