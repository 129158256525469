<mat-form-field *ngIf="list && control" fxFlex appearance="outline" class="mat-select-compact" floatLabel="always">
  <mat-label>
    {{ 'common.infrastructure' | translate | uppercase }}
  </mat-label>

  <mat-select [formControl]="control" multiple="true">
    <mat-select-trigger>
      {{ getTriggerValue() }}
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [formControl]="filterCtrl"
        (toggleAll)="toggleSelectAll($event)"
        [toggleAllCheckboxChecked]="control.value.length === list.length"
        showToggleAllCheckbox="multiple"
        toggleAllCheckboxTooltipMessage="{{ 'common.selectAll' | translate | uppercase }}"
        placeholderLabel="{{ 'common.search' | translate | uppercase }}"
        noEntriesFoundLabel="{{ 'common.itemNotFound' | translate | uppercase }}"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      [matTooltip]="'common.noRiskModel' | translate | uppercase"
      [matTooltipDisabled]="item.risk_model"
      *ngFor="let item of filteredList"
      [value]="item.id"
      [disabled]="!item.risk_model"
    >
      <div *ngIf="item.id" class="mat-option-detail" fxLayout="column" fxLayoutAlign="center start">
        {{ item.name }}
        <span *ngIf="!item.risk_model">
          {{ 'common.noRiskModel' | translate | uppercase }}
        </span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
