import { Component, forwardRef, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';

@Component({
  selector: 'app-select-year',
  templateUrl: './select-year.component.html',
  styleUrls: ['./select-year.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectYearComponent),
      multi: true,
    },
  ],
})
export class SelectYearComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() value: number;
  @Input() label: string;

  @Input() showClearButton: boolean = true;

  @Input() set minYear(minYear: number) {
    this.minDate = new Date(minYear, 1);
  }

  @Input() set maxYear(maxYear: number) {
    this.maxDate = new Date(maxYear, 1);
  }

  @Input() set isDisabled(disabled: boolean) {
    console.log("boolean", disabled);
    this._isDisabled = disabled;
  }
  get isDisabled(): boolean {
    return this._isDisabled;
  }

  @Input() showResetButton = false;

  minDate?: Date;
  maxDate?: Date;

  _isDisabled: boolean;

  constructor() {}
  ngOnInit(): void {}

  chosenYearHandler(date: Date, datepicker: MatDatepicker<Date>) {
    const year = _moment(date).year();
    this.value = year;
    this.onTouched();
    this.onChange(this.value);
    datepicker.close();
  }

  onChange(value: number | number[]): void {}
  onTouched() {}

  ngOnChanges() {}

  writeValue(value: number) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onNextYearButtonClicked(event: MouseEvent): void {
    event.stopPropagation();

    this.value++;
    this.onTouched();
    this.onChange(this.value);
    // this.writeValue(this.value);
  }

  onPrevYearButtonClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.value--;
    this.onTouched();
    this.onChange(this.value);
    // this.writeValue(this.value);
  }

  onResetButtonClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.value = null;
    this.onTouched();
    this.onChange(this.value);
    // this.writeValue(this.value);
  }
}
