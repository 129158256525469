import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../paginated-result.interface';
import { CreateInfrastructureParam } from './params/create-infrastructure-param';

import { InfrastructureSummary } from './infrastructure-summary';
import { Infrastructure2 } from './infrastructure2';
import { UpdateInfrastructureImageParam } from './params/update-infrastructure-image.param';
import { UpdateInfrastructureInput } from './params/update-infrastructure.input';
import { FindInfrastructuresParam } from './params/find-infrastructures.param';
import { PaginatedParam } from '../paginated.param';
import { RequestParam } from '../_results';

@Injectable()
export class InfrastructureService {
  constructor(private http: HttpClient) {}

  createInfrastructure(param: CreateInfrastructureParam): Observable<{ id: number }> {
    const url = '/infrastructure';
    return this.http.post<{ id: number }>(url, param);
  }

  deleteInfrastructure(infrastructureId: number): Observable<any> {
    const url = `/infrastructure?id=${infrastructureId}`;
    return this.http.delete(url);
  }

  uploadPicture(query: any): Observable<string> {
    return this.http.post('/infrastructure/image', query).pipe(map((infrastructure: string) => infrastructure));
  }

  updateInfrastructure(param: UpdateInfrastructureInput): Observable<Infrastructure2> {
    const url = '/infrastructure';
    return this.http.patch<Infrastructure2>(url, param);
  }

  updateInfrastructureImage(param: UpdateInfrastructureImageParam): Observable<any> {
    const url = '/infrastructure/image';
    const formData = param.toMultipartFormData();

    return this.http.post<Infrastructure2>(url, formData);
  }

  getInfrastructureCountByCustomerId(customerId: number): Observable<number> {
    const url = '/infrastructures';
    const params = new HttpParams().set('_page_size', '1');

    // TODO: Allow filter by customer_id.
    // This will be necessary when there are roles that allow managing customers
    // .set('_filter_customer_id__exact', customerId.toString())

    return this.http.get(url, { params }).pipe(map((value: PaginatedResult<Infrastructure2>) => value.total));
  }

  getInfrastructureListByCustomerUsername(customerUsername: string): Observable<Infrastructure2[]> {
    // const url = `/infrastructures/_by_customer_username/${customerUsername}`;
    const url = '/infrastructures';
    const params = new HttpParams().set('_page_size', '0');

    const headers = this.getCustomHeadersByCustomerUsername(customerUsername);

    return this.http
      .get<PaginatedResult<Infrastructure2>>(url, { params, headers })
      .pipe(map((result) => result.items));
  }

  getInfrastructureListByCustomerId(customerId: number): Observable<Infrastructure2[]> {
    if (customerId == null) {
      throw new Error(`${customerId} must be a number`);
    }
    const url = '/infrastructures';
    const params = new HttpParams().set('_filter_customer_id__exact', customerId.toString()).set('_page_size', '0');

    return this.http.get<PaginatedResult<Infrastructure2>>(url, { params }).pipe(map((result) => result.items));
  }

  getFullInfrastructureListByCustomerId(customerId: number): Observable<Infrastructure2[]> {
    return new Observable<Infrastructure2[]>((subscriber) => {
      this.getInfrastructureListByCustomerId(customerId).subscribe((infrastructructureList) => {
        const observables = infrastructructureList.map((infrastructure) =>
          this.getInfrastructureById(infrastructure.id)
        );

        const newInfrastructureList = [];
        concat(...observables).subscribe({
          next: (infrastructure) => {
            newInfrastructureList.push(infrastructure);
          },
          complete: () => {
            subscriber.next(newInfrastructureList);
            subscriber.complete();
          },
          error: (error) => {
            subscriber.error(error);
          },
        });
      });
    });
  }

  // getInfrastructureListByCustomerId(customerId: number): Observable<Infrastructure2[]> {
  //     if (customerId == null) {
  //       throw new Error(`${customerId} must be a number`);
  //     }
  //     const url = '/infrastructures';
  //     const params = new HttpParams().set('_filter_customer_id__exact', customerId.toString()).set('_page_size', '0');

  //     return this.http.get<PaginatedResult<Infrastructure2>>(url, { params }).pipe(map((result) => result.items));
  //   }

  findPaginatedInfrastructures(
    param: RequestParam,
    customerUsername?: string
  ): Observable<PaginatedResult<Infrastructure2>> {
    // const url = `/infrastructures/_by_customer_username/${customerUsername}`;
    const url = '/infrastructures';
    const params = param.toHttpParams();
    let options = { params };
    if (customerUsername) {
      const headers = this.getCustomHeadersByCustomerUsername(customerUsername);
      options = { ...options, headers } as any;
    }

    return this.http.get<PaginatedResult<Infrastructure2>>(url, options);
  }

  getAllCustomerInfrastructures(customerUsername?: string): Observable<Infrastructure2[]> {
    // const url = `/infrastructures/_by_customer_username/${customerUsername}`;

    const param = new PaginatedParam();
    param.pageSize = 0;

    const url = '/infrastructures';
    const params = param.toHttpParams();
    let options = { params };
    if (customerUsername) {
      const headers = this.getCustomHeadersByCustomerUsername(customerUsername);
      options = { ...options, headers } as any;
    }

    return this.http.get<PaginatedResult<Infrastructure2>>(url, options).pipe(map((result) => result.items));
  }

  getInfrastructuresSummaryByCustomerUsername(customerUserName: string): Observable<InfrastructureSummary> {
    // const url = `/infrastructures_summary/_by_customer_username/${customerUsername}`;
    const url = '/infrastructures_summary';

    let params = new HttpParams();

    const headers = this.getCustomHeadersByCustomerUsername(customerUserName);

    // if (userId !== undefined) {
    //   params = params.set('user_id', userId.toString());
    // }
    return this.http.get<InfrastructureSummary>(url, { params, headers });
  }

  getInfrastructureById(infrastructureId: number): Observable<Infrastructure2> {
    if (infrastructureId == null) {
      return null;
    }
    const url = '/infrastructure';

    const params = new HttpParams().set('infrastructure_id', infrastructureId.toString());

    return this.http.get<Infrastructure2>(url, { params });
  }

  // TODO: Eliminar cuando se se lleve acabo limpieza de código antiguo que lo usa
  // getAll(page?: Page, query?: String): Observable<InfrastructureList> {
  //   let filterSchema = ['typology', 'name', 'tag', 'region', 'location'];

  //   let params = new HttpParams();
  //   const reportProgress = page === undefined;

  //   if (page) {
  //     params = params.set('_page', '' + page.pageIndex).set('_page_size', '' + page.pageSize);
  //   }

  //   if (query) {
  //     params = params.set('_operator', 'or');

  //     for (let key of filterSchema) {
  //       params = params.set('_filter_' + key, '' + query);
  //     }
  //   }

  //   return this.http.get('/infrastructures', { reportProgress: reportProgress, params: params }).pipe(
  //     map((json: any) => {
  //       const items: Infrastructure2[] = json.items.map((infrastructure) => new Infrastructure2(infrastructure));
  //       const pagination: Pagination = new Pagination(json);
  //       return new InfrastructureList(items, pagination);
  //     })
  //   );
  // }

  // TODO: Eliminar cuando se se lleve acabo limpieza de código antiguo que lo usa
  // getOne(id: number): Observable<Infrastructure2> {
  //   const params = new HttpParams().set('infrastructure_id', '' + id);

  //   return this.http.get('/infrastructure', { params: params }).pipe(
  //     map((json: any) => {
  //       return new Infrastructure2(json);
  //     })
  //   );
  // }

  // TODO: Eliminar cuando se se lleve acabo limpieza de código antiguo que lo usa
  // create(query: any): Observable<number> {
  //   return this.http.post('/infrastructure', query).pipe(map((infrastructure: any) => infrastructure.id));
  // }

  private getCustomHeadersByCustomerUsername(customerUsername: string): HttpHeaders {
    const customHeader = {
      'selected-customer-username': customerUsername,
    };

    const headers = new HttpHeaders(customHeader);

    return headers;
  }
}
