import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Logger } from '@core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { finalize, map, withLatestFrom } from 'rxjs/operators';
import { Evaluation } from '../models/infrastructures/evaluations/evaluation';
import { InfrastructureService } from '../models/infrastructures/infrastructure.service';
import { Infrastructure2 } from '../models/infrastructures/infrastructure2';
import { Priorization } from '../models/priorization/priorization';
import { PriorizationCriteria } from '../models/priorization/priorization-criteria';
import { PriorizationResults } from '../models/priorization/priorization-results';
import { PriorizationsService } from '../models/priorization/priorizations.service';
import { RestrictionsService } from '../models/restriction/restrictions.service';
import { Customer } from '../models/users/customers/customer.interface';
import { CustomersService } from '../models/users/customers/customers.service';
import { NotificationService } from './notification.service';

// interface CustomerState{

// }

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  priorizationId = new BehaviorSubject<number>(null);
  priorizationViewMode = new BehaviorSubject<boolean>(true);
  currentPriorization = new BehaviorSubject<Priorization>(null);
  priorizationResults = new BehaviorSubject<PriorizationResults[]>([]);
  selectedEvaluation = new BehaviorSubject<Evaluation>(null);

  private priorizationCriteria = new BehaviorSubject<PriorizationCriteria>(null);
  private selectedInfrastructure = new BehaviorSubject<Infrastructure2>(null);
  private selectedInfrastructureId = new BehaviorSubject<number>(null);
  private isLoadingSelectedInfrastructure = new BehaviorSubject<boolean>(false);
  private selectedInfrastructureIdList = new BehaviorSubject<number[]>([]);
  private selectedModelIds = new BehaviorSubject<number[]>([]);

  private isLoadingSelectedCustomerInfrastructureList = new BehaviorSubject<boolean>(false);

  private selectedPriorizationResult = new BehaviorSubject<PriorizationResults>(null);
  private _isLoadingSelectedCustomer = new BehaviorSubject<boolean>(false);
  private selectedCustomer = new BehaviorSubject<Customer>(null);
  private _isLoadingInfraestructureList = new BehaviorSubject<boolean>(false);
  private infrastructureList = new BehaviorSubject<Infrastructure2[]>([]);

  currentCustomerUnits: string;
  log = new Logger(SharedDataService.name);

  constructor(
    private priorizationsService: PriorizationsService,
    private restrictionsService: RestrictionsService,
    private customersService: CustomersService,
    private infrastructureService: InfrastructureService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private location: Location
  ) {}

  getPriorizationViewMode(): Observable<boolean> {
    return this.priorizationViewMode.asObservable();
  }

  setPriorizationViewMode(isViewMode: boolean) {
    this.priorizationViewMode.next(isViewMode);
  }

  getPriorizationId(): Observable<number> {
    return this.priorizationId.asObservable();
  }

  setPriorizationId(priorizationId?: number) {
    const queryParams: Params = { priorizationId };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });

    if (priorizationId != null) {
      combineLatest([
        this.restrictionsService.findRestrictionsByPriorizationId(priorizationId),
        this.priorizationsService.getPriorizationByPriorizationId(priorizationId),
        this.priorizationsService.findAllPriorizationResultsByPriorizationId(priorizationId),
      ]).subscribe(([additional_restrictions, currentPriorization, priorizationResults]) => {
        setTimeout(() => {
          this.setSelectedInfrastructureIdList(currentPriorization.infrastructure_ids);
        }, 1000);

        this.priorizationId.next(priorizationId);
        this.currentPriorization.next({ ...currentPriorization, additional_restrictions });

        this.setPriorizationCriteriaId(currentPriorization.priorization_criteria_id);

        this.selectedPriorizationResult.next(null);
        this.priorizationResults.next(priorizationResults);
      });
    } else {
      this.priorizationId.next(null);
      this.currentPriorization.next(null);
      this.selectedPriorizationResult.next(null);
      this.priorizationResults.next([]);
    }
  }

  getCurrentPriorization(): Observable<Priorization> {
    return this.currentPriorization.asObservable();
  }

  getPriorizationResults(): Observable<PriorizationResults[]> {
    return this.priorizationResults.asObservable();
  }

  setPriorizationCriteriaId(priorizationCriteriaId: number) {
    this.priorizationsService.getPriorizationCriteriaById(priorizationCriteriaId).subscribe((priorizationCriteria) => {
      this.priorizationCriteria.next(priorizationCriteria);
    });
  }

  getPriorizationCriteria(): Observable<PriorizationCriteria> {
    return this.priorizationCriteria.asObservable();
  }

  setPriorizationCriteria(priorizationCriteria: PriorizationCriteria) {
    this.priorizationCriteria.next(priorizationCriteria);
  }

  getIsLoadingSelectedCustomerInfrastructureList(): Observable<boolean> {
    return this.isLoadingSelectedCustomerInfrastructureList.asObservable();
  }

  isLoadingInfrastructureList(): Observable<boolean> {
    return this._isLoadingInfraestructureList.asObservable();
  }

  getInfrastructureList(): Observable<Infrastructure2[]> {
    return this.infrastructureList.asObservable();
  }

  // setInfrastructureList(infrastructureList: Infrastructure2[]) {
  //   this.infrastructureList.next(infrastructureList);
  // }

  getIsLoadingSelectedInfrastructure(): Observable<boolean> {
    return this.isLoadingSelectedInfrastructure.asObservable();
  }

  getSelectedInfrastructureId(): Observable<number> {
    return this.selectedInfrastructureId.asObservable();
  }

  getSelectedInfrastructure(): Observable<Infrastructure2> {
    return this.selectedInfrastructure.asObservable();
  }

  setSelectedInfrastructureId(selectedInfrastructureId?: number) {
    if (selectedInfrastructureId === this.selectedInfrastructureId.value) {
      return;
    }

    this.selectedInfrastructureId.next(selectedInfrastructureId);

    const url = this.router
      .createUrlTree([], {
        relativeTo: this.route,
        queryParams: { selectedInfrastructureId: selectedInfrastructureId },
        queryParamsHandling: 'merge',
      })
      .toString();

    this.location.replaceState(url);

    if (selectedInfrastructureId != null) {
      this.isLoadingSelectedInfrastructure.next(true);
      this.infrastructureService
        .getInfrastructureById(selectedInfrastructureId)
        .pipe(finalize(() => this.isLoadingSelectedInfrastructure.next(false)))
        .subscribe({
          next: (infrastructure) => {
            this.log.info('Infrastructura loaded successful');
            this.selectedInfrastructure.next(infrastructure);
          },
          error: (error) => {
            this.log.error('Error loading infrastructure', error);
            this.notificationService.open('infrastructure.ErrorLoadingInfrastructure');
          },
        });
    } else {
      this.selectedInfrastructure.next(null);
    }
  }

  getSelectedInfrastructureIdList(): Observable<number[]> {
    return this.selectedInfrastructureIdList.asObservable();
  }

  getSelectedInfrastructureList(): Observable<Infrastructure2[]> {
    return this.getInfrastructureList().pipe(
      withLatestFrom(this.getSelectedInfrastructureIdList()),
      map(([infrastructureList, selectedInfrastructureIdList]) => {
        return infrastructureList.filter((value) => selectedInfrastructureIdList?.includes(value?.id));
      })
    );
  }

  setSelectedInfrastructureIdList(selectedInfrastructureIdList?: number[]) {
    this.selectedInfrastructureIdList.next(selectedInfrastructureIdList);
  }

  getSelectedModelIds(): Observable<number[]> {
    return this.selectedModelIds.asObservable();
  }

  setSelectedModelIds(modelIds?: number[]) {
    this.selectedModelIds.next(modelIds);
  }

  getSelectedPriorizationResult(): Observable<PriorizationResults> {
    return this.selectedPriorizationResult.asObservable();
  }

  setSelectedPriorizationResult(priorizationResult: PriorizationResults) {
    this.selectedPriorizationResult.next(priorizationResult);
  }

  getSelectedEvaluation(): Observable<Evaluation> {
    return this.selectedEvaluation.asObservable();
  }

  setSelectedEvaluation(evaluation: Evaluation) {
    this.selectedEvaluation.next(evaluation);
  }

  isLoadingSelectedCustomer(): Observable<boolean> {
    return this._isLoadingSelectedCustomer.asObservable();
  }
  getSelectedCustomer(): Observable<Customer> {
    return this.selectedCustomer.asObservable();
  }

  // setSelectedCustomer(selectedCustomer: Customer) {
  //   this.selectedCustomer.next(selectedCustomer);
  // }

  setSelectedCustomerUsername(selectedCustomerUsername: string) {
    this._isLoadingSelectedCustomer.next(true);

    this.customersService
      .getCustomerByUsername(selectedCustomerUsername)
      .pipe(finalize(() => this._isLoadingSelectedCustomer.next(false)))
      .subscribe({
        next: (customer) => {
          this.log.info('Customer loaded succesful', customer);
          this.selectedCustomer.next(customer);
          // this.setSelectedCustomer(customer);
        },
        error: (error) => {
          this.log.error('Error loading customer', error);
          this.notificationService.open('user.ErrorLoadingCustomer');
        },
      });

    this._isLoadingInfraestructureList.next(true);
    this.infrastructureService
      .getAllCustomerInfrastructures(selectedCustomerUsername)
      .pipe(finalize(() => this._isLoadingInfraestructureList.next(false)))
      .subscribe({
        next: (infrastructureList) => {
          this.infrastructureList.next(infrastructureList);
          if (infrastructureList.length > 0) {
            this.log.debug('infrastructureList', infrastructureList);
            // if (this.selectedInfrastructureId.value === null) {
            const firstInfrastructure = infrastructureList[0];

            this.setSelectedInfrastructureId(firstInfrastructure.id);
            // }
          }
          if (infrastructureList.length === 0) {
            this.setSelectedInfrastructureId(null);
          }
          // this.list = result.items;
          // this.filteredList = this.list;
          // this.allInfrastructureIdsWithModel = this.list.filter((value) => value.risk_model).map((value) => value.id);

          // this.sharedDataService.setInfrastructureList(this.list);

          // if (this.list?.length > 0) {
          //   if (this.selectedInfrastructureId) {
          //     // const selectedInfrastructure = this.list.find(value => value.id === this.selectedInfrastructureId);
          //     this.formControl.setValue(this.selectedInfrastructureId);
          //   } else if (this.selectFirstWhenIsLoaded) {
          //     const firstValidItem = this.list.find((value) => value.risk_model != null);
          //     if (firstValidItem) {
          //       this.selectedInfrastructureId = firstValidItem.id;
          //       this.formControl.setValue(firstValidItem.id);
          //       // this.formControl.setValue(firstValidItem.id, { emitEvent: false });
          //     }
          //   }
          // }
        },
      });
  }
}
