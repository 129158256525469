import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { UserRole } from '../../models/users/users/user-role';
import { UsersService } from '../../models/users/users/users.service';

@Component({
  selector: 'app-select-user-role',
  templateUrl: './select-user-role.component.html',
  styleUrls: ['./select-user-role.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectUserRoleComponent),
      multi: true,
    },
  ],
})
export class SelectUserRoleComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() value?: number;
  list: UserRole[] = [];
  filteredList: UserRole[] = [];
  isDisabled = false;
  filterCtrl: FormControl = new FormControl();
  @Input() formControl: FormControl; //= new FormControl([]);
  isLoading = false;
  query: string;
  subs: Subscription;

  constructor(private usersService: UsersService) {}

  ngOnInit() {
    this.loadList();
    // this.selectAll();
    this.subs = this.filterCtrl.valueChanges.subscribe((query) => {
      this.filteredList = this.list.filter((value) => {
        const normalizedName = value.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        const normalizedQuery = query
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('es-ES');
        return normalizedName.includes(normalizedQuery);
      });
    });
  }

  onChange: (value: number) => void = () => {};
  onTouched = () => {};

  loadList() {
    this.isLoading = true;

    this.usersService.getUserRoleList().subscribe((userRoleList) => {
      this.list = userRoleList;
      this.filteredList = this.list;
      this.isLoading = false;
    });
  }

  onSelectChanged(event: MatSelectChange) {
    const newSelect = event.value;
    this.value = newSelect;

    this.onTouched();
    this.onChange(this.value);
  }

  // toggleSelectAll(value: boolean) {
  //   if (value === true) {
  //     this.selectAll();
  //   } else {
  //     this.clear();
  //   }
  //   this.onTouched();
  //   this.onChange(this.value);
  // }

  // clear() {
  //   this.value = undefined;
  // }

  // getTriggerValue(): string {
  //   if (!Array.isArray(this.value)) {
  //     return;
  //   }

  //   let valueAsString: string;

  //   const itemCount = this.value.length;

  //   if (itemCount === 1) {
  //     valueAsString = this.list.filter(value => value.id === this.value[0])
  //       .map(value => value.name).toString();
  //   } else if (this.isAllItemsSelected()) {
  //     valueAsString = 'All infrastructure types selected';
  //   } else {
  //     valueAsString = `${itemCount} infrastructure types selected`;
  //   }

  //   return valueAsString;
  // }

  // isAllItemsSelected(): boolean {
  //   if (Array.isArray(this.value)) {
  //     const valueAsArray = this.value as number[];
  //     const isAllItemsSelected = this.allIds.every(id => valueAsArray.includes(id));

  //     return isAllItemsSelected;
  //   }
  //   return false;
  // }

  writeValue(value: number) {
    this.value = value;
    // this.formControl.setValue(value);
    this.onChange(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
