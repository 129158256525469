import { Component, Input } from '@angular/core';

import { I18nService } from '@core/services/i18n.service';

import { ILanguage } from '../../models/language.interface';
import { environment } from '@env/environment';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  @Input() icon = false;

  constructor(
    private i18nService: I18nService
  ) { }

  setLanguage(language: string) {
    this.i18nService.setLanguage(language);
    // window.location.reload();
  }

  get currentLanguage(): string {
    const langCode = this.i18nService.getLanguage();
    const lang = environment.supportedLanguages.find(language => language.code === langCode);
    return lang ? lang.label : langCode;
  }

  get languages(): ILanguage[] {
    return environment.supportedLanguages;
  }

}
