<div fxLayout="column" class="login-presentation-container">
  <div fxFlex layout="row">
    <div fxFlex fxFlex="50" fxLayout="row" fxLayoutAlign="end end" class="login-presentation-grid-1">
      <img class="logo" src="assets/images/infrastructure/dam_white.png" alt="Icono presa" />
    </div>
    <div fxFlex fxFlex="50" fxLayout="row" fxLayoutAlign="start end" class="login-presentation-grid-2">
      <img class="logo" src="assets/images/infrastructure/bridge_white.png" alt="Icono puente" />
    </div>
  </div>
  <div fxFlex layout="row">
    <div fxFlex fxFlex="50" fxLayout="column" fxLayoutAlign="center start" fxFlexLayoutGap="1.0rem" class="login-presentation-grid-3 mat-typography">
      <span>{{ 'app.description' | translate }}</span>
      <br>
      <span>
        <!-- TODO: Redirect file -->
        {{ 'app.help'| translate }}: <strong><a (click)="help()"> {{ 'common.documentation' | translate }}</a></strong>
      </span>
      <br>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign="end center" fxLayoutGap="0.5rem" class="full-width">
        <button mat-flat-button color="accent" (click)="openWebsite()">
          <span translate>login.Website</span>
        </button>
        <button mat-flat-button color="primary" (click)="contact()">
          <span translate>login.Contact</span>
        </button>
      </div>
    </div>
    <div fxFlex fxFlex="50" fxLayout="row" fxLayoutAlign="start start" class="login-presentation-grid-4">
      <img class="logo" src="assets/images/infrastructure/pier_white.png" alt="Icono puente" />
    </div>
  </div>
</div>
