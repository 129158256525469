<!-- Label Host -->
<ng-template #labelLayer></ng-template>

<svg class="link-container">
  <!-- Link -->
  <g [id]="model.id">
    <path
      class="risk-management-link"
      stroke-width="2"
      [attr.stroke]="
        (model.selectSelected() | async) || (model.selectHovered() | async)
          ? 'rgba(101, 186, 255, 0.7)'
          : '#B3B3B3'
      "
      [attr.d]="model.selectPath() | async"
      [ngClass]="{ hovered: model.selectHovered() | async }"
      (mouseleave)="model.setHovered(false); onHover()"
      (mouseenter)="model.setHovered(); onHover()"
    ></path>
    <path
      class="default-link"
      (mouseleave)="model.setHovered(false); onHover()"
      (mouseenter)="model.setHovered(); onHover()"
      [attr.stroke-width]="20"
      [attr.stroke]="
        (model.selectSelected() | async) || (model.selectHovered() | async)
          ? 'rgba(101, 186, 255, 0.7)'
          : '#B3B3B3'
      "
      [attr.stroke-linecap]="'round'"
      [attr.data-linkid]="model.id"
      [attr.stroke-opacity]="(model.selectHovered() | async) ? '0.1' : '0'"
      [attr.d]="model.selectPath() | async"
      (mouseleave)="model.setHovered(false); onHover()"
      (mouseenter)="model.setHovered(); onHover()"
    ></path>
  </g>

  <!-- Points -->
  <g
    *ngFor="let point of model.getPoints(); trackBy: trackByPoints"
    [id]="point.id"
  >
    <circle
      fill="currentColor"
      [attr.cx]="point.selectX() | async"
      [attr.cy]="point.selectY() | async"
      r="5"
      class="risk-management-point"
      [ngClass]="{ hovered: point.selectHovered() | async }"
    />
    <circle
      [attr.data-type]="point.type"
      [attr.data-id]="point.id"
      [attr.data-parent-id]="model.id"
      (mouseleave)="point.setHovered(false); model.setHovered(false)"
      (mouseenter)="point.setHovered(); model.setHovered()"
      [attr.cx]="point.selectX() | async"
      [attr.cy]="point.selectY() | async"
      r="15"
      [attr.opacity]="0"
      [ngClass]="{ selected: point.selectSelected() | async }"
    />
  </g>
</svg>
