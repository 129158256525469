import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../paginated-result.interface';
import { CalculatePriorizationParam } from './calculate-priorization.param';
import { FindPriorizationResultsParam } from './params/find-priorization-results.param';
import { Priorization } from './priorization';
import { PriorizationCriteria } from './priorization-criteria';
import { PriorizationResults } from './priorization-results';

@Injectable({
  providedIn: 'root',
})
export class PriorizationsService {
  constructor(private http: HttpClient) {}

  getAllPriorizationCriterias(): Observable<PriorizationCriteria[]> {
    const url = '/priorization_criteria';
    const params = new HttpParams().set('page_size', '0');
    return this.http.get<PaginatedResult<PriorizationCriteria>>(url, { params }).pipe(map((result) => result.items));
  }

  getPriorizationCriteriaById(priorizationCriteriaId): Observable<PriorizationCriteria> {
    const url = '/priorization_criteria';
    const params = new HttpParams().set('_filter_id__exact', priorizationCriteriaId);

    return this.http.get<PaginatedResult<PriorizationCriteria>>(url, { params }).pipe(map((result) => result.items[0]));
  }

  calculatePriorization(param: CalculatePriorizationParam): Observable<number> {
    const url = '/priorization_operation';

    return this.http.post<{ id: number }>(url, param).pipe(map((value) => value.id));
  }

  findAllPriorizationResultsByPriorizationId(priorizationId: number): Observable<PriorizationResults[]> {
    const url = '/priorization_operation_results';
    const priorizationIdAsString = priorizationId.toString();
    const params = new HttpParams()
      .set('_page_size', '0')
      .set('_filter_priorization_operation_id__exact', priorizationIdAsString);

    return this.http.get<PaginatedResult<PriorizationResults>>(url, { params }).pipe(map((value) => value.items));
  }

  getPriorizationByPriorizationId(priorizationId: number): Observable<Priorization> {
    const url = '/priorization_operations';
    const params = new HttpParams().set('_filter_id__exact', priorizationId.toString());
    return this.http.get<PaginatedResult<Priorization>>(url, { params }).pipe(map((value) => value.items[0]));
  }

  findPaginatedPriorization(param: FindPriorizationResultsParam): Observable<PaginatedResult<Priorization>> {
    const url = '/priorization_operations';
    const params = param.toHttpParams();

    return this.http.get<PaginatedResult<Priorization>>(url, { params });
  }

  deletePriorization(priorizationId: number): Observable<any> {
    const url = `/priorization_operation?id=${priorizationId}`;
    return this.http.delete(url);
  }
}
