import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormMode } from '@shared/FormMode';

@Component({
  selector: 'app-default-form-actions',
  templateUrl: './default-form-actions.component.html',
  styleUrls: ['./default-form-actions.component.scss'],
})
export class DefaultFormActionsComponent implements OnInit {
  @Input() mode?: FormMode;
  @Input() createButtonDisabled = false;
  @Input() saveButtonDisabled = false;
  @Input() editButtonDisabled = false;
  @Input() hideEditAndRemove = false;

  @Output() editButtonClicked = new EventEmitter();
  @Output() saveButtonClicked = new EventEmitter();
  @Output() createButtonClicked = new EventEmitter();
  // @Output() resetClicked = new EventEmitter();
  @Output() cancelButtonClicked = new EventEmitter();

  FormMode = FormMode;

  constructor() {}

  ngOnInit(): void {}
}
