import {
  HttpClient,
  HttpXhrBackend,
  HttpHeaders,
  HttpParams,
  HttpParameterCodec,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '@env/environment';

export enum WebhookSource {
  JS = 'JS',
  API = 'API',
}

// export interface WebhookMessage {
//   environment: string;
//   source: WebhookSource;
//   level?: string;
//   content: WebhookContent;
// }
//
// export interface WebhookContent {
//   endopint?: string,
//   event?: string,
//   message: string
// }

/**
 * Log output webhook function > To Google Chat
 */
export class WebhookMonitor {
  /**
   * Format styles
   * https://developers.google.com/hangouts/chat/reference/message-formats/basic
   */
  FORMAT_BOX: string = '`';
  FORMAT_BOLD: string = '*';
  FORMAT_CODE: string = '```';

  static env = environment.production ? 'PROD' : 'DEV';

  constructor() {}

  console(objects: any[]) {
    try {
      let _msg = this._format(objects);
      this.call({ text: _msg });
    } catch (error) {
      console.log('[Webhook] Abort notification');
    }
  }

  private call(mesasge: any) {
    const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

    let _params = new HttpParams({ encoder: new HttpParamsEncodingCodec() });
    _params = _params.append('key', environment.webhook.Key);
    _params = _params.append('token', environment.webhook.Token);

    httpClient.post(environment.webhook.Endpoint, mesasge, { params: _params }).toPromise();
  }

  private _format(trace: any) {
    let msg =
      '' +
      this._box(`[Environment] ${WebhookMonitor.env}`) +
      ' ' +
      this._box(`[Source] ` + this._filterSource(trace)) +
      '\n\n' +
      this._bold('[Controller]') +
      ` ${trace[0]}` +
      '\n' +
      this._bold('[Error]') +
      ` ${trace[1]}` +
      '\n' +
      this._bold('[Type]') +
      ` ${trace[2].name || 'undefined'}` +
      '\n\n' +
      this._bold('[RAW]') +
      ' Error' +
      '\n' +
      this._code(JSON.stringify(trace[2], null, 2));

    return msg;
  }

  private _filterSource(trace: any) {
    for (let t of trace) {
      if (t instanceof HttpErrorResponse) return WebhookSource.API;
    }

    return WebhookSource.JS;
  }

  private _box(text: string) {
    return `${this.FORMAT_BOX}${text}${this.FORMAT_BOX}`;
  }

  private _bold(text: string) {
    return `${this.FORMAT_BOLD}${text}${this.FORMAT_BOLD}`;
  }

  private _code(text: string) {
    return `${this.FORMAT_CODE}${text}${this.FORMAT_CODE}`;
  }
}

// Required scape especial characters token parameter > Custom Params Encoder/Decoder
export class HttpParamsEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }
  encodeValue(v: string): string {
    return v;
  }
  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }
  decodeValue(v: string) {
    return decodeURIComponent(v);
  }
}
