<div *ngIf="mode == FormMode.View" fxFlex fxLayout="row" fxLayoutGap="0.5rem">
  <button
    *ngIf="!hideEditAndRemove"
    [disabled]="editButtonDisabled"
    (click)="editButtonClicked.emit()"
    mat-raised-button
    color="primary"
    fxFlex
  >
    <mat-icon>create</mat-icon>
    {{ 'common.edit' | translate | uppercase }}
  </button>
  <button *ngIf="!hideEditAndRemove" disabled mat-raised-button color="warn" fxFlex>
    <mat-icon>delete</mat-icon>
    {{ 'common.delete' | translate | uppercase }}
  </button>
</div>

<div *ngIf="mode == FormMode.Edit" fxFlex fxLayout="row" fxLayoutGap="0.5rem">
  <button (click)="saveButtonClicked.emit()" [disabled]="saveButtonDisabled" mat-raised-button color="primary" fxFlex>
    <mat-icon>save</mat-icon>
    {{ 'common.save' | translate | uppercase }}
  </button>
  <button (click)="cancelButtonClicked.emit()" mat-raised-button fxFlex>
    <mat-icon>cancel</mat-icon>
    {{ 'common.cancel' | translate | uppercase }}
  </button>
</div>

<div *ngIf="mode == FormMode.Create" fxFlex fxLayout="row" fxLayoutGap="0.5rem">
  <button (click)="cancelButtonClicked.emit()" mat-raised-button fxFlex>
    <mat-icon>cancel</mat-icon>
    {{ 'common.cancel' | translate | uppercase }}
  </button>

  <button
    (click)="createButtonClicked.emit()"
    [disabled]="createButtonDisabled"
    mat-raised-button
    color="primary"
    fxFlex
  >
    <mat-icon>add</mat-icon>
    {{ 'common.create' | translate | uppercase }}
  </button>
</div>
