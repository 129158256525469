import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'failureModeConsequence' })
export class FailureModeConsequencePipe implements PipeTransform {
  transform(failureModeConsequence: string): string {
    if (failureModeConsequence == null) {
      return '';
    }
    return failureModeConsequence.replace('_', '-');
  }
}
