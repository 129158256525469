import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Customer } from '@shared/models/users/customers/customer.interface';
import { UserRoleEnum } from '@shared/models/users/users/user-role.enum';
import { Subject } from 'rxjs';

import { SharedDataService } from '@shared/services/shared-data.service';
import { DialogsService } from '@app/@shared/dialogs/services/dialogs.service';
import { UserService } from '@app/@shared/services/user.service';
import { HelpService } from '@app/@shared/models/help/help.service';

import { takeUntil } from 'rxjs/operators';
import {} from 'selenium-webdriver';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() username: string;
  @Input() roleId: UserRoleEnum;

  @Output() toggleSidenavEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() updatePasswordEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() logoutEvent: EventEmitter<void> = new EventEmitter<void>();

  UserRoleEnum = UserRoleEnum;
  selectedCustomer?: Customer;
  urlStartWihDasboard: boolean;

  private _onDestroy = new Subject();

  constructor(
    private router: Router,
    private sharedDataService: SharedDataService,
    private dialogService: DialogsService,
    private userService: UserService,
    private helpService: HelpService
  ) {}

  ngOnInit(): void {
    this.sharedDataService
      .getSelectedCustomer()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((customer) => {
        this.selectedCustomer = customer;
      });

    this.userService.roleId().then((roleId) => {
      this.roleId = roleId;
    });

    this.urlStartWihDasboard = this.router.url.startsWith('/dashboard');

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;

        this.urlStartWihDasboard = url.startsWith('/dashboard');
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  toggleSidenav() {
    this.toggleSidenavEvent.emit();
  }

  updatePassword() {
    this.updatePasswordEvent.emit();
  }

  getHelp() {
    this.helpService.getHelp('platform').subscribe(
      (help) => {
        window.open(help.url_file);
      },
      (error) => {
        this.dialogService.openSnackBar({ message: 'Help.Documentation could not be downloaded' });
      }
    );
  }

  logout() {
    this.logoutEvent.emit();
  }

  showSelectedCustomer(): boolean {
    return this.router.url.startsWith('/dashboard/');
  }
}
