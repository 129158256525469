import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'sortBy' })

export class SortByPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(array: any[], field: string): any {
    array.sort((a: any, b: any) => {
      const fieldA = a[field].trim() ? this.translate.instant(a[field].trim()) : a[field].trim();
      const fieldB = b[field].trim() ? this.translate.instant(b[field].trim()) : b[field].trim();

      if (fieldA < fieldB) {
        return -1;
      } else if (fieldA > fieldB) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

}
