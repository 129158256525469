<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0.5rem" fxFlex>
  <div fxLayout="column" fxLayoutGap="0.5rem" fxFlex>
    <div fxLayout="column">
      <mat-card class="diagram-header">
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between" fxFlex>
          <div fxLayout="column" fxFlex>
            <mat-card-title [matTooltip]="diagramFlow?.model?.title">
              {{ diagramFlow?.model?.title }}
            </mat-card-title>
            <mat-card-subtitle>
              <div fxLayout="row" fxLayoutGap="0.5rem">
                <span><strong>{{ 'common.Last update' | translate }}:</strong></span>
                <span>{{ diagramFlow?.updated_on | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
              </div>
            </mat-card-subtitle>
          </div>

          <div fxLayout="row" fxLayoutGap="0.5rem">
            <mat-button-toggle-group [(ngModel)]="diagramMode">
              <mat-button-toggle value="{{ DiagramMode.FLOW }}"><code><strong>{{ 'diagram.Mode.flow' | translate | uppercase }}</strong></code></mat-button-toggle>
              <mat-button-toggle value="{{ DiagramMode.PICTURE }}"><code><strong>{{ 'diagram.Mode.picture' | translate | uppercase }}</strong></code></mat-button-toggle>
            </mat-button-toggle-group>
            <button
              [matTooltip]="'diagram.Update diagram' | translate"
              (click)="getRiskModelDiagram()"
              mat-button
              ngClass.xl="mat-flat-button"
              ngClass.lt-xl="mat-icon-button"
              color="primary">
              <mat-icon>refresh</mat-icon>
              <span fxHide.lt-xl>{{ 'diagram.Update diagram' | translate }}</span>
            </button>
            <button
              *ngIf="showOptions"
              [matTooltip]="'diagram.Save diagram' | translate"
              (click)="updateDiagramFlow()"
              mat-button
              ngClass.xl="mat-flat-button"
              ngClass.lt-xl="mat-icon-button"
              color="primary">
              <mat-icon>save</mat-icon>
              <span fxHide.lt-xl>{{ 'diagram.Save diagram' | translate }}</span>
            </button>
            <button
              [matTooltip]="'diagram.Download all files' | translate"
              (click)="downloadCompressedFiles()"
              mat-button
              ngClass.xl="mat-flat-button"
              ngClass.lt-xl="mat-icon-button"
              color="primary">
              <mat-icon>file_download</mat-icon>
              <span fxHide.lt-xl>{{ 'diagram.Download all files' | translate }}</span>
            </button>
            <button *ngIf="diagramMode == DiagramMode.FLOW"
              [matTooltip]="'diagram.ExportToImage' | translate"
              (click)="onExportToImageButtonClicked()"
              mat-button
              ngClass.xl="mat-flat-button"
              ngClass.lt-xl="mat-icon-button"
              color="primary">
              <mat-icon>insert_photo</mat-icon>
              <span fxHide.lt-xl>{{ 'diagram.ExportToImage' | translate }}</span>
            </button>
            <button
              [fxHide]="!showOptions"
              [matTooltip]="'diagram.Clear diagram' | translate"
              (click)="clearDiagram()"
              mat-button
              ngClass.xl="mat-flat-button"
              ngClass.lt-xl="mat-icon-button"
              color="accent">
              <mat-icon>delete</mat-icon>
              <span fxHide.lt-xl>{{ 'diagram.Clear diagram' | translate }}</span>
            </button>
          </div>
        </mat-card-header>
      </mat-card>
      <mat-progress-bar *ngIf="isLoadingDiagramFlow" mode="query" style="height: 2px"></mat-progress-bar>
    </div>

    <rxzu-diagram  [style.display]="diagramMode == DiagramMode.FLOW ? 'flex' : 'none'"
      id="flow-diagram"
      class="diagram mat-elevation-z2"
      [model]="diagramModel"
      (drop)="onBlockDropped($event)"
      (dragover)="$event.preventDefault()">
    </rxzu-diagram>
    <div fxFlex *ngIf="diagramMode == DiagramMode.PICTURE" class="mat-typography diagram-picture">
      <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1.0rem" *ngIf="!diagramFlow.signed_picture_path" class="diagram-picture">
        <span><strong>{{ 'diagram.Picture' | translate | uppercase }}</strong></span>
        <span>Picture not upload</span>
      </div>
      <img fxFlex src="{{ diagramFlow.signed_picture_path }}" *ngIf="diagramFlow.signed_picture_path" class="diagram-picture"/>
    </div>
  </div>

  <div fxFlex="380px" fxFlex.lt-md="100%" fxLayout="column" fxLayoutGap="0.5rem">
    <mat-accordion multi>
      <mat-expansion-panel [expanded]="true" *ngIf="showOptions && diagramMode == DiagramMode.FLOW">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span><strong>{{ 'diagram.Nodes' | translate | uppercase }}</strong></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutGap="0.5rem">
          <div fxFlex
            *ngFor="let node of nodesLibrary"
            class="node-drag"
            draggable="true"
            [attr.data-type]="node.name"
            (dragstart)="onBlockDrag($event)"
            [ngStyle]="{ 'background-color': node.color }">
            <span>{{ 'diagram.' + node.name | translate | titlecase }}</span>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true" *ngIf="diagramMode == DiagramMode.FLOW">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span><strong>{{ 'diagram.Information' | translate | uppercase }}</strong></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxFlex [hidden]="selectedNode || selectedLink">
          <p>{{ 'diagram.Please select a node/link to obtain information about it.' | translate }}</p>
        </div>
        <form fxFlex fxLayout="column" fxLayoutGap="0.5rem" *ngIf="selectedLink" [formGroup]="linkForm">
          <mat-form-field fxFlex appearance="outline">
            <mat-label>{{ 'diagram.Title' | translate }}</mat-label>
            <input matInput formControlName="linkLabel" type="text" />
          </mat-form-field>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10"
            fxLayoutGap="0.5rem"
            *ngIf="showOptions">
            <button (click)="updateLabel()" mat-raised-button type="button" color="primary" fxFlex>
              {{ 'diagram.Update label' | translate }}
            </button>
            <button (click)="removeLabel()" mat-raised-button type="button" color="primary" fxFlex>
              {{ 'diagram.Remove label' | translate }}
            </button>
          </div>
        </form>
        <div fxFlex *ngIf="selectedNode" fxLayout="column" fxLayoutGap="0.5rem">
          <form fxFlex fxLayout="column" fxLayoutGap="0.5rem" [formGroup]="nodeForm">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem">
              <mat-form-field fxFlex appearance="outline">
                <mat-label>{{ 'diagram.Title' | translate }}</mat-label>
                <input matInput formControlName="title" type="text" />
              </mat-form-field>
              <mat-form-field fxFlex appearance="outline">
                <mat-label>{{ 'diagram.Content' | translate }}</mat-label>
                <input matInput formControlName="content" type="text" />
              </mat-form-field>
            </div>
            <mat-form-field fxFlex appearance="outline">
              <mat-label>{{ 'diagram.Description' | translate }}</mat-label>
              <!-- <input matInput formControlName="description" type="text" /> -->
              <textarea matInput formControlName="description" rows="3"></textarea>
            </mat-form-field>
            <mat-form-field fxFlex appearance="outline">
              <mat-label>{{ 'diagram.Comment' | translate }}</mat-label>
              <!-- <input matInput formControlName="comment" type="text" /> -->
              <textarea matInput formControlName="comment" rows="3"></textarea>
            </mat-form-field>
            <div fxFlex fxFill *ngIf="showOptions">
              <app-files-to-upload
                style="height: 100px"
                fxFlex
                *ngIf="!selectedNode.signed_path_file"
                [formControl]="fileFormControl"
                [limit]="1">
              </app-files-to-upload>
            </div>
            <button
              *ngIf="selectedNode.signed_path_file"
              mat-raised-button
              type="button"
              color="primary"
              (click)="downloadFile()"
              fxFlex>
              {{ 'diagram.Download file' | translate | uppercase }}
            </button>
            <button
              fxFlex
              mat-raised-button
              color="primary"
              (click)="selectedNode.signed_path_file = undefined"
              *ngIf="selectedNode.signed_path_file"
              [disabled]="!showOptions">
              {{ 'diagram.Change file' | translate | uppercase }}
            </button>
          </form>
          <div fxFlex fxLayout="row" fxLayoutGap="0.5rem" *ngIf="showOptions">
            <button fxFlex mat-raised-button color="primary" (click)="updateInformationNode()">
              {{ 'diagram.Update node' | translate | uppercase }}
            </button>
            <button fxFlex mat-flat-button color="primary" (click)="removeNodes()" [disabled]="!selectedNode">
              {{ 'diagram.Remove node' | translate | uppercase }}
            </button>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span><strong>{{ 'diagram.FileLinks' | translate | uppercase }}</strong></span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="outline" floatLabel="always" fxFlex>
          <mat-label>
            {{ 'diagram.FileLinks' | translate | uppercase }}
          </mat-label>
          <textarea matInput [formControl]="linksFormControl" rows="6"></textarea>
        </mat-form-field>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- PICTURE UPDATE -->
    <mat-expansion-panel [expanded]="true" *ngIf="showOptions && diagramMode == DiagramMode.PICTURE">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span><strong>{{ 'diagram.Picture' | translate | uppercase }}</strong></span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxFlex fxLayout="column" fxLayoutGap="0.5rem">
        <app-files-to-upload fxFlex style="height: 100px" [formControl]="pictureFormControl" [limit]="1" *ngIf="!diagramPictureReset"></app-files-to-upload>
        <br>
        <button fxFlex mat-raised-button color="primary" (click)="saveDiagramPicture()" [disabled]="!pictureFormControl.value" *ngIf="showOptions">
          {{ 'diagram.Update picture' | translate | uppercase }}
        </button>
      </div>
    </mat-expansion-panel>

    <div fxLayout="column" fxLayoutGap="0.5rem">
      <button (click)="onReturnButtonClicked()" mat-raised-button color="primary">
        {{ 'diagram.GoToInfrastructure' | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
