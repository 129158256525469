import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InfrastructureTypeEnum } from '../models/infrastructure-type/infrastructure-type.enum';

@Pipe({ name: 'infrastructureTypeName', pure: false })
export class InfrastructureTypeNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }
  transform(infrastructureTypeId: InfrastructureTypeEnum): string {
    if (infrastructureTypeId === null) {
      return '';
    }
    const name = InfrastructureTypeEnum[infrastructureTypeId];
    const translatedName = this.translateService.instant(`infrastructureType.${name}`);
    return translatedName;
    // return name;
  }
}
