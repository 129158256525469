import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpService } from '@app/@shared/models/help/help.service';
import { UserRoleEnum } from '@app/@shared/models/users/users/user-role.enum';
import { AuthenticationService } from '@app/@shared/services/authentication.service';
import { ContactService } from '@app/@shared/services/contact.service';
import { UserService } from '@app/@shared/services/user.service';
import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxRolesService } from 'ngx-permissions';
import { ContactComponent } from '../contact/contact.component';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { RecoverPasswordComponent } from '../recover-password/recover-password.component';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent {
  isLoading = false;

  private dialogRef: MatDialogRef<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private contactService: ContactService,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private userService: UserService,
    private helpService: HelpService,
    private ngxRolesService: NgxRolesService
  ) {}

  openWebsite() {
    window.open('http://www.ipresas.com/');
  }

  login(credentials: { username: string; password: string }) {
    this.isLoading = true;
    this.authenticationService
      .login(credentials.username.toLowerCase(), credentials.password)
      .then(() => {
        // this.userService.roleId().then((roleId) => {
        //   const roleName = UserRoleEnum[roleId];

        //   this.ngxRolesService.addRole(roleName, []);
        // });
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
        this.isLoading = false;
      })
      .catch((error) => {
        this.handleError(error);
        this.isLoading = false;
      });
  }

  contact() {
    this.dialogService.open(ContactComponent).subscribe((message) => {
      if (message) {
        this.reCaptchaV3Service.execute('contact').subscribe(
          (token) => {
            this.contactService.send(message).subscribe((data) => {
              if (data) {
                this.dialogService.openSnackBar({ message: 'contact.Message sent successfully.' });
              }
            });
          },
          (error) => {
            this.dialogService.openSnackBar({ message: 'contact.An error has occurred when sending a message.' });
          }
        );
      }
    });
  }

  getHelp() {
    this.helpService.getHelp('service').subscribe(
      (help) => {
        window.open(help.url_file);
      },
      (error) => {
        this.dialogService.openSnackBar({ message: 'Help.Documentation could not be downloaded' });
      }
    );
  }

  recoverPassword() {
    this.dialogRef = this.dialog.open(RecoverPasswordComponent);
    // this.dialogRef.afterClosed().subscribe(result => {
    //   this.dialogRef = null;
    //   if (result && result.username) {
    //     this.authenticationService.forgotPassword(result.username)
    //       .then((data) => this.dialogService.openSnackBar({message: `Verification code sent to ${data.CodeDeliveryDetails.Destination}`}))
    //       .catch(error => this.dialogService.openSnackBar({message: error.err.message, duration: 2000}));
    //   }
    // });
  }

  private handleError(error: any) {
    if (error.reason === 'mfaRequired') {
      // Do nothing
    } else if (error.reason === 'newPasswordRequired') {
      this.dialogService.open(NewPasswordComponent).subscribe((newPass) => {
        this.newPassword(error.cognitoUser, newPass);
      });
    } else if (error.reason === 'error' && error.err.message === 'Password reset required for the user') {
      /*  TODO  - > this.router.navigate(['login', 'verify']); */
    } else {
      const snackBar = {
        message: 'login.Username or password not valid',
      };
      this.dialogService.openSnackBar(snackBar);
    }
  }

  private newPassword(user: CognitoUser, newPassword: string) {
    this.authenticationService
      .completeNewPasswordChallenge(user, newPassword, {})
      .then(() => this.router.navigate(['/']))
      .catch((error) => this.dialogService.openSnackBar({ message: error.err.message }));
  }
}
