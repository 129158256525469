import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GraphOption } from '../models/graphs/interfaces/graph-option';
import { SharedDataService } from '../services/shared-data.service';

@Pipe({ name: 'graphOptionName', pure: false })
export class GraphOptionNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private sharedDataService: SharedDataService) {}
  transform(graphOption: GraphOption, customerEconomicUnit = ''): string {
    if (!graphOption) {
      return '';
    }
    const option = graphOption.option;
    const units = graphOption.units === '#' ? customerEconomicUnit : graphOption.units;
    // this.sharedDataService.currentCustomerUnits;
    const translatedName = this.translateService.instant(`graphOptions.${option}`);
    const result = translatedName + (units ? ` (${units})` : '');
    return result;
    // return name;
  }
}
