import { IInfrastructureType } from './infrastructure-type.interface';
import { InfrastructureSpecificSchema } from '../infrastructure-specific-schema/infrastructure-specific-schema.model';

export class InfrastructureType implements IInfrastructureType {

  id: number;
  name: string;
  description: string;
  infrastructure_table: string;
  infrastructure_specific_schema: InfrastructureSpecificSchema[];

  constructor(public infrastructureType: IInfrastructureType) {
    this.id = infrastructureType.id;
    this.name = infrastructureType.name;
    this.description = infrastructureType.description;
    this.infrastructure_table = infrastructureType.infrastructure_table;
    this.infrastructure_specific_schema =
      infrastructureType.infrastructure_specific_schema.map(schema => new InfrastructureSpecificSchema(schema))
  }

  get searchString() {
    return this.name;
  }
}
