import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from '@app/@core';
import { UpdateRiskModelParam } from '@app/@shared/models/risk-models/params/update-risk-model.param';
import { RiskModel } from '@app/@shared/models/risk-models/risk-model';
import { RiskModelsService } from '@app/@shared/models/risk-models/risk-models.service';

const log = new Logger('RiskModelEnabledButtonComponent');
@Component({
  selector: 'app-risk-model-enabled-button[riskModel]',
  templateUrl: './risk-model-enabled-button.component.html',
  styleUrls: ['./risk-model-enabled-button.component.scss'],
})
export class RiskModelEnabledButtonComponent implements OnInit {
  @Input() riskModel: RiskModel;
  @Output() riskModelChanged = new EventEmitter<RiskModel>();

  isLoading = false;

  constructor(private riskModelsService: RiskModelsService) {}

  ngOnInit(): void {}

  onRiskModelEnableChange(isActive: boolean) {
    this.isLoading = true;

    const param = {
      id: this.riskModel.id,
      is_active: isActive,
    } as UpdateRiskModelParam;

    this.riskModelsService.updateRiskModel(param).subscribe({
      next: (result) => {
        this.riskModel.is_active = isActive;
        this.isLoading = false;
        this.riskModelChanged.emit(this.riskModel);
      },
      error: (error) => {
        log.error(error);
        this.isLoading = false;
      },
    });
  }
}
