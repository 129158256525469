import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { UserRoleEnum } from '@shared/models/users/users/user-role.enum';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { NgxRolesService } from 'ngx-permissions';
import { AuthenticationService } from './authentication.service';
import { TokenService } from './token.service';

@Injectable()
export class UserService {
  private userPool: any = null;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private ngxRolesService: NgxRolesService // private ngxPermissions: NgxPermissionsService
  ) {
    const poolData = {
      UserPoolId: environment.cognito.UserPoolId,
      ClientId: environment.cognito.ClientId,
      Paranoia: 7,
    };
    this.userPool = new CognitoUserPool(poolData);
  }

  user(): CognitoUser {
    return this.userPool.getCurrentUser();
  }

  username(): string {
    const user = this.user();
    const username = user.getUsername();
    return username;
  }

  roleId(): Promise<UserRoleEnum> {
    return new Promise<UserRoleEnum>((resolve) => {
      this.tokenService
        .getPayload()
        .then((data) => {
          const roleIdAsString = data['custom:role_id'];
          const roleId = isNaN(roleIdAsString) ? UserRoleEnum.Invalid : (Number(roleIdAsString) as UserRoleEnum);

          resolve(roleId);
        })
        .catch(() => {
          this.logout().then(() => this.router.navigate(['/login']));
        });
    });
  }

  permissions(): Promise<string[]> {
    return this.tokenService
      .getPayload()
      .then((data) => {
        const permissions = data['custom:permissions'].replace('[', '').replace(']', '');
        return permissions.split(', ');
      })
      .catch(() => {
        this.logout().then(() => this.router.navigate(['/login']));
      });
  }

  isLoggedIn(): Promise<boolean> {
    return this.authenticatedUser()
      .then(() => true)
      .catch(() => false);
  }

  logout(): Promise<void> {
    this.completeLogout();
    return Promise.resolve();
  }

  private authenticatedUser(): Promise<CognitoUser> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      cognitoUser.getSession((err: any) => {
        if (err) {
          reject();
        }
        resolve(cognitoUser);
      });
    });
  }

  private completeLogout() {
    const cognitoUser = this.userPool.getCurrentUser();
    this.authenticationService.authState$.next(false);
    this.ngxRolesService.flushRolesAndPermissions();
    // this.ngxPermissions.

    if (cognitoUser) {
      cognitoUser.signOut();
    }
  }
}
