import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { AppConstants } from '@app/app-constants';
import { Logger } from '@core';
import { FindReportsParam } from '@shared/models/reports/find-reports.param';
import { Report } from '@shared/models/reports/report';
import { ReportsService } from '@shared/models/reports/reports.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ReportListFilterValue } from './report-list-filter/report-list-filter.component';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit, OnDestroy {
  private _infrastructureId?: number;
  @Input() set infrastructureId(infrastructureId: number) {
    this.selectedReport = null;
    this._infrastructureId = infrastructureId;

    console.log('infrastructure change', infrastructureId);
    this.loadData();
  }
  get infrastructureId(): number {
    return this._infrastructureId;
  }
  @Input() set lastGeneratedReport(lastGeneratedReport: Report) {
    this._lastGeneratedReport = lastGeneratedReport;
    this.loadData();
  }
  get lastGeneratedReport(): Report {
    return this._lastGeneratedReport;
  }

  private _priorizationId?: number;
  @Input() set priorizationId(priorizationId: number) {
    this._priorizationId = priorizationId;
    this.loadData();
  }
  get priorizationId(): number {
    return this._priorizationId;
  }

  private _filterValue: ReportListFilterValue;
  public get filterValue(): ReportListFilterValue {
    return this._filterValue;
  }
  @Input() public set filterValue(reportListFilterInput: ReportListFilterValue) {
    this._filterValue = reportListFilterInput;
    this.loadData();
  }

  @Output() selectedReportChanged = new EventEmitter<Report>();

  private _selectedReport: Report;
  public get selectedReport(): Report {
    return this._selectedReport;
  }
  public set selectedReport(report: Report) {
    console.log('report selectedReport', report);
    if (report?.id !== this._selectedReport?.id) {
      this.selectedReportChanged.emit(report);
      this._selectedReport = report;
    }
  }
  isLoading = false;
  private _list: Report[];
  public get list(): Report[] {
    return this._list;
  }
  public set list(reportList: Report[]) {
    this._list = reportList;
    // load first report
    if (this.list?.length > 0 && this.selectedReport == null) {
      // const firstValidReport = this.list[0];
      const firstValidReport = this.list.find((report) => report.signed_path_file != null);

      // setTimeouts avoids ExpressionChangedAfterItHasBeenCheckedError:
      // setTimeout(() => {
      this.selectedReport = firstValidReport;
      // });
    }
  }
  page = 0;
  pageSize = AppConstants.DEFAULT_PAGE_SIZE;
  total = 0;

  pageSizeOptions = AppConstants.DEFAULT_PAGE_SIZE_OPTIONS;

  private _lastGeneratedReport?: Report;
  private _query?: string;
  set query(query: string) {
    this._query = query;
    this.page = 0;
    this.loadData();
  }
  get query(): string {
    return this._query;
  }

  log = new Logger(ReportListComponent.name);
  private _onDestroy = new Subject();

  constructor(private reportsService: ReportsService) {}

  ngOnInit(): void {
    // this.loadData();
  }
  ngOnDestroy(): void {
    this._onDestroy;
  }
  loadData(): void {
    if (this.priorizationId == null && this.infrastructureId == null) {
      return;
    }

    this.isLoading = true;
    const param = new FindReportsParam();
    param.orderByField = 'dt_process_start';
    param.orderByAsc = false;
    param.query = this.query;
    param.pageSize = this.pageSize;
    param.page = this.page;
    param.infrastructureId = this.infrastructureId;
    param.priorizationId = this.priorizationId;
    param.startCreateDate = this.filterValue?.startDate
      ? moment(this.filterValue?.startDate).startOf('day').toDate()
      : null;
    param.endCreateDate = this.filterValue?.endDate ? moment(this.filterValue?.endDate).endOf('day').toDate() : null;

    this.reportsService
      .findReports(param)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result) => {
          this.list = result.items;
          this.page = result.page;
          this.pageSize = result.pageSize;
          this.total = result.total;
        },

        error: (error) => {
          this.log.error(error);
          //TODO: Added notification.open
        },
      });
  }

  onPage(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.page = pageEvent.pageIndex;

    this.loadData();
  }

  onReportDeleted(reportId: number): void {
    this.loadData();
  }

  onPreviewReportButtonClicked(report: Report): void {
    console.log('onPreviewReportButtonClicked', report);
    this.selectedReport = report;
  }
}
