<div class="toolbar-search">
  <mat-card [ngClass]="{ 'mat-elevation-z0': outline, outline: outline }">
    <div *ngIf="!isSearchToolbarVisible" fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{ title }}</span>
      <div fxFlex></div>
      <button
        *ngIf="hasSearchButton"
        mat-icon-button
        (click)="toggleSearchToolbar()"
        [disabled]="isLoading"
        matTooltip="{{ 'common.search' | translate | uppercase }}"
      >
        <mat-icon aria-label="search">search</mat-icon>
      </button>
      <button
        *ngIf="hasRefreshButton"
        mat-icon-button
        (click)="onButtonRefreshPressed()"
        [disabled]="isLoading"
        matTooltip="{{ 'common.refresh' | translate | uppercase }}"
      >
        <mat-icon aria-label="refresh">cached</mat-icon>
      </button>
      <!-- <button mat-icon-button (click)="onButtonCreatePressed()" color="primary"
        matTooltip="{{ 'common.New'  | translate | uppercase" *ngIf="hasCreateButton">
        <mat-icon aria-label="add">add</mat-icon>
      </button> -->
      <button
        mat-icon-button
        color="primary"
        (click)="onButtonCreatePressed()"
        matTooltip="{{ 'common.create' | translate | uppercase }}"
        *ngIf="hasNewButton"
      >
        <mat-icon aria-label="add">add</mat-icon>
      </button>
    </div>
    <div *ngIf="isSearchToolbarVisible" fxLayout="row" fxLayoutAlign="space-between center">
      <input
        #searchInput
        autofocus
        fxFlex="100"
        [formControl]="searchQueryForm"
        placeholder="{{ 'common.search' | translate | uppercase }}"
        class="mat-typography"
      />
      <div fxFlex></div>
      <button mat-icon-button (click)="toggleSearchToolbar()">
        <mat-icon aria-label="close">close</mat-icon>
      </button>
    </div>
  </mat-card>
  <mat-progress-bar mode="query" color="primary" style="height: 2px" *ngIf="isLoading"></mat-progress-bar>
  <mat-progress-bar mode="query" color="accent" style="height: 2px" *ngIf="isCreating"></mat-progress-bar>
</div>
