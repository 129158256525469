import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../../paginated-result.interface';
import { AssociateCustomer } from '../associate-customer';
import { Customer } from './customer.interface';
import { CreateCustomerParam } from './inputs/create-customer.param';
import { FindCustomersParam } from './inputs/find-customers.param';
import { UpdateCustomerImageParam } from './inputs/update-customer-image.param';
import { UpdateCustomerInput } from './inputs/update-customer.input';

@Injectable()
export class CustomersService {
  constructor(private http: HttpClient) {}

  getCustomerByUsername(username?: string): Observable<Customer> {
    const url = '/customers';
    const params = new HttpParams().set('_filter_user_login__exact', username);

    return this.http.get<PaginatedResult<Customer>>(url, { params }).pipe(
      map((result) => {
        if (result?.items?.length) {
          return result.items[0];
        }
      })
    );
  }

  getCustomerListByIdList(customerIdList: number[]): Observable<Customer[]> {
    const url = '/customers';
    const params = new HttpParams().set('_filter_id__in', customerIdList.toString());

    return this.http.get<PaginatedResult<Customer>>(url, { params }).pipe(
      map((result) => {
        return result.items;
      })
    );
  }

  findCustomers(param: FindCustomersParam): Observable<PaginatedResult<Customer>> {
    const url = '/customers';
    const params = param.toHttpParams();

    return this.http.get<PaginatedResult<Customer>>(url, { params });
  }

  createCustomer(param: CreateCustomerParam): Observable<any> {
    const url = '/customer';
    const formData = param.toMultipartFormData();
    const options = {} as any;

    return this.http.post(url, formData, options);
  }

  updateCustomer(param: UpdateCustomerInput): Observable<Customer> {
    const url = '/customer';

    return this.http.patch<Customer>(url, param);
  }

  findCustomersByEngineer(username: string): Observable<Customer[]> {
    const url = '/user/customers';
    const params = new HttpParams().set('user', username);

    return this.http
      .get<PaginatedResult<AssociateCustomer>>(url, { params })
      .pipe(map((value) => value.items[0].customers));
  }

  create(query: FormData): Observable<number> {
    return this.http.post('/customer', query).pipe(map((customer: any) => customer.id));
  }

  updateCustomerImage(param: UpdateCustomerImageParam) {
    const url = '/customer/image';
    const formData = param.toMultipartFormData();
    const options = {} as any;

    return this.http.patch(url, formData, options);
  }

  deleteCustomerById(customerId: number): Observable<void> {
    const url = `/customer?id=${customerId}`;

    return this.http.delete<void>(url);
  }
}
