import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Report } from '../models/reports/report';

@Pipe({
  name: 'reportProcessTime'
})
export class ReportProcessTimePipe implements PipeTransform {

  transform(report: Report): string {
    if (report.dt_process_end == null || report.dt_process_start == null) {
      return '';
    }
    // moment.locale('es');

    const processStartDate = moment(report.dt_process_start);
    const processEndDate = moment(report.dt_process_end);
    const diff = processEndDate.diff(processStartDate);
    const diffOnSeconds = diff / 1000;
    return diffOnSeconds.toString() + 's';
    // const humanizeDuration = moment.duration(diff).humanize();

    // return humanizeDuration;
  }

}
