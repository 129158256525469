import { FindRestrictionsParam } from './find-restrictions.param';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../paginated-result.interface';
import { Restriction } from './restriction';

@Injectable({
  providedIn: 'root'
})
export class RestrictionsService {

  constructor(private http: HttpClient) { }

  // findPaginatedRiskRestrictions(
  //   param: FindRestrictionsParam)
  //   : Observable<PaginatedResult<RiskRestriction>> {
  //   const url = '/infrastructure_risk_restrictions';

  //   const params = param.toHttpParams();
  //   return this.http.get<PaginatedResult<RiskRestriction>>(url, { params });
  // }

  findPaginatedRestrictionsByModelIds(param: FindRestrictionsParam): Observable<PaginatedResult<Restriction>> {
    const url = '/infrastructure_risk_restrictions';
    const params = param.toHttpParams();

    return this.http.get<PaginatedResult<Restriction>>(url, { params });
  }


  findRestrictionsByModelIds(modelIds: number[]): Observable<Restriction[]> {
    const url = '/infrastructure_risk_restrictions';
    const params = new HttpParams()
      .set('_filter_infrastructure_risk_model_id__in', modelIds.toString())
      .set('_page_size', '0');

    return this.http.get<PaginatedResult<Restriction>>(url, { params }).pipe(map(value => value.items));
  }

  findRestrictionsByPriorizationId(priorizationId: number): Observable<Restriction[]> {
    const url = '/infrastructure_risk_restrictions';
    const params = new HttpParams().set(
      '_filter_priorization_operation_id__exact',
      priorizationId.toString());
    return this.http.get<PaginatedResult<Restriction>>(url, { params }).pipe(map(value => value.items));
  }
}

