<h1 mat-dialog-title class="center-align">
  {{"common.updateImage" | translate | uppercase}}
</h1>

<div mat-dialog-content fxLayout="column">
  <app-files-to-upload [limit]="1" [formControl]="control">

  </app-files-to-upload>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
  <button mat-raised-button mat-dialog-close fxFlex>
    <mat-icon>cancel</mat-icon>
    {{ 'common.cancel' | translate | uppercase}}
  </button>
  <button (click)="onAcceptButtonClicked()" [disabled]="control.invalid" mat-raised-button color="primary" fxFlex>
    <mat-icon>
      done
    </mat-icon>
    {{ 'common.accept' | translate | uppercase}}
  </button>
</div>
