import { Location } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from '@app/@core';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { NotificationService } from '@app/@shared/services/notification.service';
import {
  DiagramModel,
  DispatchedAction,
  LabelModel,
  LinkModel,
  MoveCanvasAction,
  MoveItemsAction,
  NodeModel,
  RxZuDiagramComponent,
} from '@rxzu/angular';
import { DialogsService } from '@shared/dialogs/services/dialogs.service';
import { DiagramFlowInterface, DiagramFlowNode } from '@shared/models/diagram-flow/diagram-flow.interface';
import { DiagramFlowService } from '@shared/models/diagram-flow/diagram-flow.service';
import * as htmlToImage from 'html-to-image';
import { Options } from 'html-to-image';
import { interval, Subject } from 'rxjs';
import { debounce, finalize, takeUntil } from 'rxjs/operators';
import {
  DiagramFlowExportDialogComponent,
  DiagramFlowExportDialogData,
} from './diagram-flow-export-dialog/diagram-flow-export-dialog.component';
import { RiskManagementNodeModel, RiskManagementPortModel } from './themes/risk-management/models';


export enum DiagramMode {
  FLOW = 'flow',
  PICTURE = 'picture',
}


@Component({
  selector: 'app-diagram-flow',
  templateUrl: './diagram-flow.component.html',
  styleUrls: ['./diagram-flow.component.scss'],
})
export class DiagramFlowComponent implements OnInit, AfterViewInit, OnDestroy {
  DiagramMode = DiagramMode;

  private _showOptions: boolean = true;
  public get showOptions(): boolean {
    return this._showOptions;
  }
  @Input() public set showOptions(showOptions: boolean) {
    this._showOptions = showOptions;

    if (showOptions) {
      this.nodeForm.enable();
      this.linkForm.enable();

      this.linksFormControl.enable();
    } else {
      this.linksFormControl.disable();
      this.nodeForm.disable();
    }
  }

  @Input() set riskModelId(modelId: number) {
    this.infrastructureRiskModelId = modelId;
    if (modelId) {
      this.getRiskModelDiagram();
    }
  }

  // @Input() infrastructureId: number;

  private _infrastructure: Infrastructure2;
  public get infrastructure(): Infrastructure2 {
    return this._infrastructure;
  }
  @Input() public set infrastructure(infrastructure: Infrastructure2) {
    this._infrastructure = infrastructure;
    this.riskModelId = infrastructure?.risk_model?.id;
  }

  @Output() events: EventEmitter<DispatchedAction> = new EventEmitter();
  // @Output() exitButtonClicked = new EventEmitter<void>();

  protected _onDestroy = new Subject();

  infrastructureRiskModelId: number;
  diagramFlow: DiagramFlowInterface;
  diagramModel: DiagramModel;
  @ViewChild(RxZuDiagramComponent, { static: true })
  diagram?: RxZuDiagramComponent;

  nodeForm = new FormGroup({
    title: new FormControl('', [Validators.maxLength(50)]),
    content: new FormControl('', [Validators.maxLength(50)]),
    description: new FormControl(''),
    comment: new FormControl(''),
    file_path: new FormControl(),
  });
  linkForm = new FormGroup({
    linkLabel: new FormControl('', [Validators.maxLength(50)]),
  });

  fileFormControl = this.fb.control(null);
  pictureFormControl = this.fb.control(null);
  linksFormControl = this.fb.control(null);

  diagramBlocked: Boolean = false;
  diagramMode: DiagramMode = DiagramMode.FLOW;

  diagramPictureReset: boolean = false;

  selectedNode: DiagramFlowNode;
  selectedLink: LinkModel;

  isLoadingDiagramFlow: Boolean = false;

  nodesDefaultDimensions = { height: 200, width: 200 };
  nodesLibrary = [
    { color: '#4AC637', name: 'risk-management-green' },
    { color: '#79CBFA', name: 'risk-management-blue' },
    { color: '#FC4242', name: 'risk-management-red' },
  ];

  log = new Logger(DiagramFlowComponent.name);

  constructor(
    private diagramFlowService: DiagramFlowService,
    private dialogService: DialogsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public location: Location
  ) {
    this.diagramModel = new DiagramModel();

    // this.createNodeForm();
    // this.createLinkForm();
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  ngOnInit() {
    // this.createNodeForm();
    // this.createLinkForm();

    this.suscribeFile();

    this.nodeForm.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        debounce(() => interval(1500))
      )
      .subscribe(() => {
        if (this.showOptions) {
          this.log.debug('updateInformationNode');
          this.updateInformationNode();
        }
      });
  }

  ngAfterViewInit() {
    // this.log.debug('ngAfterViewInit');
    // this.diagram?.zoomToFit();
    this.subscribeToDiagramEvents();
  }

  /**
   * Method for create node form
   */
  // private createNodeForm() {
  //   this.nodeForm = new FormGroup({
  //     title: new FormControl('', [Validators.maxLength(50)]),
  //     content: new FormControl('', [Validators.maxLength(50)]),
  //     description: new FormControl(''),
  //     comment: new FormControl(''),
  //     file_path: new FormControl(),
  //   });

  //   this.nodeForm.disable();
  // }

  /**
   * Method for create link form
   */
  // private createLinkForm() {
  //   this.linkForm = new FormGroup({
  //     linkLabel: new FormControl('', [Validators.maxLength(50)]),
  //   });

  //   this.linkForm.disable();
  // }

  private suscribeFile() {
    this.fileFormControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((fileList: File[]) => {
      let file: File;
      if (fileList?.length) {
        file = fileList[0];
      } else {
        file = null;
      }
      this.nodeForm.get('file_path').setValue(file);
    });
  }

  public getRiskModelDiagram() {
    this.log.debug('getRiskModelDiagram');
    this.diagramModel.reset();

    this.isLoadingDiagramFlow = true;
    this.diagramFlowService
      .getDiagramModelById(this.infrastructureRiskModelId)
      .pipe(finalize(() => (this.isLoadingDiagramFlow = false)))
      .subscribe({
        next: (diagramFlow) => {
          this.diagramFlow = diagramFlow;

          if (this.diagramFlow) {
            this.deserializeDiagram(JSON.parse(this.diagramFlow.model_diagram));
            this.linksFormControl.setValue(diagramFlow.links);

            if (this.diagramFlow.signed_picture_path)
              this.diagramMode = DiagramMode.PICTURE;
          }

          if (!this.showOptions) {
            this.blockDiagram();
          }
          this.centerDiagram();
        },
        error: (error) => {
          this.notificationService.open('diagram.ErrorLoadingDiagram');
          this.log.error('Error loading diagrama', error);
        },
      });
  }

  /**
   * Method for remove nodes from the diagram
   */
  removeNodes() {
    if (!this.diagramModel.getLocked()) {
      this.diagramModel
        .getSelectedItems()
        .filter((item) => item instanceof RiskManagementNodeModel)
        .map((node) => {
          this.diagramModel.deleteNode(node.id);
          const nodeDeleted = this.diagramFlow.diagram_nodes.find((element) => element.uid_node === node.id);
          if (nodeDeleted) {
            this.deleteNode(nodeDeleted);
          }
        });
    }
  }

  /**
   * Method for blocking the diagram
   */
  blockDiagram() {
    this.diagramModel.getLinksArray().map((val) => {
      val.setLocked(true);
      val.getLabel().setLocked(true);
    });
    this.diagramModel.getNodesArray().map((val) => {
      val.setLocked(true);
      val.getPortsArray().map((port) => {
        port.setLocked(true);
      });
    });
    this.diagramBlocked = true;
  }

  /**
   * Method for unlocking the diagram
   */
  unlockDiagram() {
    this.diagramModel.getLinksArray().map((val) => {
      val.setLocked(false);
      val.getLabel().setLocked(false);
    });
    this.diagramModel.getNodesArray().map((val) => {
      val.setLocked(false);
      val.getPortsArray().map((port) => {
        port.setLocked(false);
      });
    });
    this.diagramBlocked = false;
  }

  /**
   * Method to clean the diagram
   */
  clearDiagram() {
    this.dialogService
      .openConfirm({
        title: 'diagram.Confirm clear diagram',
        message: 'diagram.Are you sure you want to clear the entire diagram?',
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.diagramModel.reset();
          this.diagramFlow.diagram_nodes.map((val) => {
            this.deleteNode(val);
          });
          this.diagramFlow.model_diagram = null;
          this.diagramFlow.diagram_nodes = [];
          this.updateDiagramFlow();
        }
      });
  }

  createNode(type: string) {
    const nodeData = this.nodesLibrary.find((nodeLib) => nodeLib.name === type);
    if (nodeData) {
      const node = new RiskManagementNodeModel({});
      node.setExtras(nodeData);

      // Port: Position START
      node.addPort(new RiskManagementPortModel({ direction: 'in' }));
      // Port: Position END
      node.addPort(new RiskManagementPortModel({ direction: 'out' }));

      return node;
    }

    return null;
  }

  /**
   * On drag start, assign the desired properties to the dataTransfer
   */
  onBlockDrag(e: DragEvent) {
    const type = (e.target as HTMLElement).getAttribute('data-type');
    if (e.dataTransfer && type) {
      e.dataTransfer.setData('type', type);
    }
  }

  /**
   * on block dropped, create new intent with the empty data of the selected block type
   */
  onBlockDropped(e: DragEvent): void | undefined {
    if (e.dataTransfer) {
      const nodeType = e.dataTransfer.getData('type');
      const node = this.createNode(nodeType);
      const canvasManager = this.diagram?.diagramEngine.getCanvasManager();
      if (canvasManager) {
        const droppedPoint = canvasManager.getZoomAwareRelativePoint(e);

        const coords = {
          x: droppedPoint.x - this.nodesDefaultDimensions.width / 2,
          y: droppedPoint.y - this.nodesDefaultDimensions.height / 2,
        };

        if (node) {
          node.setCoords(coords);
          this.diagramModel.addNode(node);

          const formData = new FormData();
          formData.append('id', node.id.toString());
          formData.append('title', '');
          formData.append('content', '');
          formData.append('description', '');
          formData.append('comment', '');
          formData.append('color', node.getExtras().color);
          formData.append('file_path', '');
          formData.append('infrastructure_risk_model_diagram_id', this.diagramFlow.id.toString());

          this.saveNode(node, formData);
          this.updateDiagramFlow();
        }
      }
    }
  }

  /**
   * Method for deserialize diagram
   */
  deserializeDiagram(diagram: any) {
    const nodeArray: NodeModel[] = [];
    const linksArray: LinkModel[] = [];

    if (diagram) {
      Object.values(diagram.nodes).forEach((node) => {
        const newNode = new RiskManagementNodeModel({
          id: node['id'],
          coords: { x: node['x'], y: node['y'] },
          dimensions: { height: node['height'], width: node['width'] },
          extras: { title: node['extras'].title ?? null, color: node['extras'].color ?? null },
        });
        for (let port of node['ports']) {
          newNode.addPort(
            new RiskManagementPortModel({
              id: port['id'],
              locked: port['locked'],
              magnetic: port['magnetic'],
              direction: port['direction'] ?? 'out',
            })
          );
        }
        nodeArray.push(newNode);
      });
      this.diagramModel.addAll(...nodeArray);
      Object.values(diagram.links).forEach((link) => {
        const linkModel = new LinkModel({
          id: link['id'],
          locked: link['locked'],
          namespace: link['namespace'],
        });
        linkModel.setSourcePort(this.diagramModel.getAllPorts().get(link['sourcePortId']));
        linkModel.setTargetPort(this.diagramModel.getAllPorts().get(link['targetPortId']));

        let _label = new LabelModel({
          id: link['label']['id'],
          text: link['label']['text'],
          coords: link['label']['coords'],
          rotation: link['label']['rotation'],
          namespace: link['label']['namespace'],
        });

        _label.setPainted(true);

        linkModel.setLabel(_label);

        linksArray.push(linkModel);
      });
      this.diagramModel.addAll(...linksArray);
    }
  }

  /**
   * Method for serialize diagram
   */
  serializedDiagram(diagram: DiagramModel): string {
    const nodesArray = [];
    const linksArray = [];
    const graph = {
      id: diagram.id,
      locked: diagram.getLocked(),
    };
    Object.values(diagram.getNodesArray()).forEach((node) => {
      const portsArray = [];
      Object.values(node.getPortsArray()).forEach((port: RiskManagementPortModel) => {
        portsArray.push({
          id: port.id,
          locked: port.getLocked(),
          type: port.type,
          magnetic: port.getMagnetic(),
          direction: port.direction$.value,
        });
      });
      nodesArray.push({
        id: node.id,
        locked: node.getLocked(),
        width: node.getWidth(),
        height: node.getHeight(),
        x: node.getCoords().x,
        y: node.getCoords().y,
        extras: node.getExtras(),
        ports: portsArray,
      });
    });
    Object.values(diagram.getLinksArray()).forEach((link) => {
      const label = link.getLabel();

      if (label.getText() != '_trash') {
        linksArray.push({
          id: link.id,
          locked: false,
          namespace: link.namespace,
          sourcePortId: link.getSourcePort()?.id ?? null,
          targetPortId: link.getTargetPort()?.id ?? null,
          label: {
            id: label?.id,
            text: label?.getText() ?? null,
            type: label?.type ?? 'default',
            rotation: label?.getRotation() ?? null,
            coords: label?.getCoords() ?? null,
            namespace: label?.namespace,
          },
          extras: link.getExtras(),
          path: link.getPath(),
        });
      }
    });
    graph['nodes'] = nodesArray;
    graph['links'] = linksArray;

    this.log.debug('[DIAGRAM SERIALIZED]', JSON.stringify(graph));

    return JSON.stringify(graph);
  }

  subscribeToDiagramEvents() {
    this.diagram?.diagramEngine
      .getActionsManager()
      .observeActions()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((e) => {
        if (e !== null && e !== undefined) {
          this.events.emit(e);

          if (e.action instanceof MoveCanvasAction && e.state == 'started') {
            this.selectedNode = null;
            this.selectedLink = null;
          }

          if (e.action instanceof MoveItemsAction && e.state == 'started') {
            const selectedItems = this.diagramModel.getSelectedItems();
            const nodes = selectedItems.filter((val) => val instanceof RiskManagementNodeModel);
            const links = selectedItems.filter((val) => val instanceof LinkModel);
            if (nodes.length == 1) {
              this.selectedLink = null;
              this.selectedNode = this.diagramFlow.diagram_nodes.find((element) => element.uid_node === nodes[0].id);
              if (this.selectedNode) {
                this.nodeForm.setValue(
                  {
                    title: this.selectedNode.title,
                    comment: this.selectedNode.comment,
                    description: this.selectedNode.description,
                    content: this.selectedNode.content,
                    file_path: null,
                  },
                  { emitEvent: false }
                );
              }
            }
            if (links.length == 1) {
              this.selectedNode = null;
              this.selectedLink = links[0] as LinkModel;
              this.linkForm.setValue({
                linkLabel: this.selectedLink.getLabel()?.getText() ?? '',
              });
            }
          }
        }
      });
  }

  updateInformationNode() {
    const file = this.nodeForm.get('file_path').value as File;
    const formData = new FormData();

    formData.append('id', this.selectedNode.id.toString());
    formData.append('title', this.nodeForm.value.title);
    formData.append('content', this.nodeForm.value.content);
    formData.append('description', this.nodeForm.value.description);
    formData.append('comment', this.nodeForm.value.comment);

    if (file) {
      formData.append('file_path', file ?? null);
      formData.append('file_name', file?.name ?? null);
    }

    this.updateNode(formData);
  }

  /**
   * Method for create node into database
   */
  saveNode(node: NodeModel, formData: FormData) {
    this.isLoadingDiagramFlow = true;
    this.diagramFlowService.postDiagramNode(formData).subscribe((nodeId) => {
      this.isLoadingDiagramFlow = false;
      this.diagramFlow.diagram_nodes.push({
        infrastructure_risk_model_diagram_id: this.diagramFlow.id,
        uid_node: node.id.toString(),
        title: '',
        content: '',
        color: node.getExtras().color,
        description: '',
        comment: '',
        id: nodeId,
      });
      this.selectedNode = this.diagramFlow.diagram_nodes.find((element) => element.uid_node === node.id.toString());
    });
  }

  /**
   * Method for create/update image source diagram (from diagram flow)
   */
  saveDiagramImage(diagramImage: File) {
    const formData = new FormData();
    formData.append('id', this.diagramFlow.id.toString());
    formData.append('image_path', diagramImage);
    formData.append('file_name', 'diagram_flow.png');

    this.isLoadingDiagramFlow = true;
    this.diagramFlowService
      .patchDiagramImage(formData)
      .pipe(finalize(() => (this.isLoadingDiagramFlow = false)))
      .subscribe({
        next: (response) => {
          this.log.debug('diagram saved. response:', response);
        },

        error: (error) => {
          this.log.error('Error saving diagram', error);
          this.notificationService.open('diagram.ErrorSavingDiagram', 'error');
        },
      });
  }

  /**
  * Method for create/update picture source diagram
  */
  saveDiagramPicture() {
    const file: File = this.pictureFormControl.value[0];

    const formData = new FormData();
    formData.append('id', this.diagramFlow.id.toString());
    formData.append('picture_path', file);
    formData.append('file_name', file.name);

    this.isLoadingDiagramFlow = true;
    this.diagramPictureReset = true;

    this.diagramFlowService
      .patchDiagramPicture(formData)
      .pipe(finalize(() => (this.isLoadingDiagramFlow = false)))
      .subscribe({
        next: (response) => {
          this.log.debug('diagram saved. response:', response);
          this.diagramFlow.signed_picture_path = response.signed_picture_path;

          this.pictureFormControl.reset();
          this.diagramPictureReset = false;
        },
        error: (error) => {
          this.diagramPictureReset = false;
          this.log.error('Error saving diagram', error);
          this.notificationService.open('diagram.ErrorSavingDiagram', 'error');
        }
      });
  }

  /**
   * Method for update node information
   */
  updateNode(formData: FormData) {
    this.isLoadingDiagramFlow = true;
    this.diagramFlowService.patchDiagramNode(formData).subscribe((node) => {
      this.isLoadingDiagramFlow = false;
      const itemIndex = this.diagramFlow.diagram_nodes.findIndex((node) => node.uid_node == this.selectedNode.uid_node);
      this.diagramFlow.diagram_nodes[itemIndex] = {
        infrastructure_risk_model_diagram_id: node.infrastructure_risk_model_diagram_id,
        uid_node: node.uid_node,
        color: node.color,
        title: node.title,
        content: node.content,
        description: node.description,
        comment: node.comment,
        signed_path_file: node.signed_path_file,
        file_path: node.file_path,
        id: node.id,
      };
      const nodeDiagram = this.diagramModel.getNode(this.selectedNode.uid_node) as NodeModel;
      // TODO: Revisar tema extras
      nodeDiagram.setExtras({ title: this.nodeForm.value.title, color: nodeDiagram.getExtras().color });
      this.updateDiagramFlow();
    });
  }

  /**
   * Method for delete node from diagram and database
   */
  deleteNode(nodeDeleted: any) {
    this.isLoadingDiagramFlow = true;
    this.diagramFlowService.deleteDiagramNode({ id: nodeDeleted.id }).subscribe(() => {
      this.isLoadingDiagramFlow = false;
      this.selectedNode = null;
      this.diagramFlow.diagram_nodes = this.diagramFlow.diagram_nodes.filter(
        (element) => element.uid_node != nodeDeleted.id.toString()
      );
      this.updateDiagramFlow();
    });
  }

  /**
   * Method for update complete diagram
   */
  updateDiagramFlow() {
    this.isLoadingDiagramFlow = true;
    const diagramUpdated = this.serializedDiagram(this.diagramModel);

    this.diagramFlowService
      .patchDiagram({
        id: this.diagramFlow.id,
        model_diagram: diagramUpdated,
        links: this.linksFormControl.value,
      })
      .subscribe((diagramFlow) => {
        this.isLoadingDiagramFlow = false;
        this.diagramFlow = diagramFlow;
        this.diagramFlow.model_diagram = diagramUpdated;

        this.dialogService.openSnackBar({ message: 'common.diagramUpdatedSuccessfully' });
      });

    this.generateImageDiagram();
  }

  /**
   * Method for update the label
   */
  updateLabel() {
    if (this.selectedLink.getLabel()) {
      this.selectedLink.getLabel().setText(this.linkForm.value.linkLabel);
    } else {
      const label = new LabelModel({
        text: this.linkForm.value.linkLabel,
        namespace: this.diagramModel.namespace,
      });
      this.selectedLink.setLabel(label);
    }
    this.updateDiagramFlow();
  }

  removeLabel() {
    const model_diagram = JSON.parse(this.diagramFlow.model_diagram);
    model_diagram.links = model_diagram.links.filter((link) => link.id !== this.selectedLink.id);
    this.diagramFlow.model_diagram = model_diagram;

    // TODO: Fix temportal to bug library
    this.selectedLink.getLabel().setText('_trash');
    this.selectedLink.destroy();
    this.selectedLink.destroy();

    this.updateDiagramFlow();
  }

  /**
   * Method for download file
   */
  downloadFile() {
    window.open(this.selectedNode.signed_path_file);
  }

  /**
   * Method for download all files (compressed)
   */
  downloadCompressedFiles() {
    this.diagramFlowService.getCompressedFiles(this.infrastructureRiskModelId).subscribe((url) => {
      window.open(url);
    });
  }

  /**
   * Method for download diagram file
   */
  downloadImageDiagram() {
    this.diagram.zoomToFit();
    const option = {
      pixelRatio: 5,
      quality: 0.95,
    } as Options;
    htmlToImage.toPng(document.getElementById('flow-diagram'), option).then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = 'flow-diagram.png';
      link.href = dataUrl;
      link.click();
    });
  }

  onExportToImageButtonClicked(): void {
    const zoomLevel = this.diagramModel.getZoomLevel();
    const offsetX = this.diagramModel.getOffsetX();
    const offsetY = this.diagramModel.getOffsetY();
    this.diagram.zoomToFit();

    const dialogRef = this.dialog.open(DiagramFlowExportDialogComponent, {
      data: {
        diagram: this.diagram,
        infrastructure: this.infrastructure,
      } as DiagramFlowExportDialogData,
    });

    dialogRef.afterClosed().subscribe(() => {
      // Restore diagram position and zoom
      this.diagramModel.setOffsetX(offsetX);
      this.diagramModel.setOffsetY(offsetY);
      this.diagramModel.setZoomLevel(zoomLevel);
    });
  }

  /**
   * Method for download diagram file
   */
  generateImageDiagram() {
    var _this = this;
    htmlToImage.toBlob(document.getElementById('flow-diagram'), { quality: 0.95 }).then(function (blob) {
      var file = <File>blob;
      _this.saveDiagramImage(file);
    });
  }

  centerDiagram() {
    this.diagram.zoomToFit();
  }

  onReturnButtonClicked(): void {
    this.location.back();
  }
}
