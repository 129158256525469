<div [formGroup]="form" fxLayout="row" fxLayoutGap="0.5rem">
  <mat-form-field appearance="outline" fxFlex>
    <mat-label> {{ 'common.dateRange' | translate | uppercase }} </mat-label>



    <mat-select [formControl]="dateInputModeControl" (valueChange)="onDateInputModeChange($event)">
      <mat-option [value]="null">
        -- {{ ('DateInputMode.Clear') | translate | uppercase}} --
      </mat-option>
      <mat-option *ngFor="let mode of dateInputModeList" [value]="mode.key">
        {{ ('DateInputMode.' + mode.value) | translate | uppercase}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <mat-form-field appearance="outline">

    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="startDate" [placeholder]="'common.startDate' | translate ">
      <input matEndDate formControlName="endDate" [placeholder]="'common.endDate' | translate ">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-error *ngIf="form.controls.startDate.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="form.controls.endDate.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>

</div>
