import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Logger } from '@core/services/logger.service';
import enUS from '../../../translations/en-US.json';
import esES from '../../../translations/es-ES.json';
import { environment } from '@env/environment';

const log = new Logger('I18nService');
const languageKey = 'language';

@Injectable()
export class I18nService {

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation('en-US', enUS);
    translateService.setTranslation('es-ES', esES);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init() {
    const language = this.getLanguage();
    this.setLanguage(language);

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange
      .subscribe((event: LangChangeEvent) => { localStorage.setItem(languageKey, event.lang); });
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   */
  setLanguage(language: string) {
    this.translateService.use(language);
  }

  /**
   * Gets the current language.
   * If no parameter is specified, the language is loaded from local storage (if present).
   */
  getLanguage(): string {

    let language = localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    const supportedLanguages = environment.supportedLanguages.map(lang => lang.code);
    let isSupportedLanguage = supportedLanguages.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = environment.defaultLanguage;
    }

    return language;
  }

}
