<div fxLayout="column" fxLayoutGap="0.5erm" class="mat-typography">
  <h2 mat-dialog-title style="text-align: center">{{ title | translate | uppercase }}</h2>

  <mat-dialog-content style="white-space: pre-wrap">
    {{ message | translate }}
  </mat-dialog-content>
  <br />
  <mat-dialog-actions fxLayout="row">
    <button mat-raised-button #closeBtn (keydown.arrowright)="acceptBtn.focus()" [matDialogClose]="false" fxFlex>
      <mat-icon> cancel </mat-icon>
      {{ cancelButton | translate | uppercase }}
    </button>
    <button
      mat-raised-button
      color="primary"
      #acceptBtn
      (keydown.arrowleft)="closeBtn.focus()"
      [matDialogClose]="true"
      fxFlex
    >
      <mat-icon> check </mat-icon>
      {{ acceptButton | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
