import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { FormMode } from '@app/@shared/FormMode';
import { Infrastructure2 } from '@app/@shared/models/infrastructures/infrastructure2';
import { AppConstants } from '@app/app-constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-infrastructure-map[form][infrastructure]',
  templateUrl: './infrastructure-map.component.html',
  styleUrls: ['./infrastructure-map.component.scss'],
})
export class InfrastructureMapComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;

  private _infrastructure: Infrastructure2;
  public get infrastructure(): Infrastructure2 {
    return this._infrastructure;
  }
  @Input() public set infrastructure(infrastructure: Infrastructure2) {
    this._infrastructure = infrastructure;
    const ubication = infrastructure?.ubication;

    this.checkPosition(ubication);
  }
  @Input() mode: FormMode;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapMarker, { static: false }) marker: MapMarker;

  protected _onDestroy = new Subject();

  position = AppConstants.DEFAULT_POSITION;

  zoom = AppConstants.DEFAULT_ZOOM;

  options: { animation: google.maps.Animation.BOUNCE };

  constructor() {}

  ngOnInit(): void {
    this.form.controls.ubication.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((value) => {
      this.checkPosition(value);
    });

    if (!this.infrastructure?.ubication) {
      this.position = AppConstants.DEFAULT_POSITION;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  private checkPosition(value: string) {
    if (this.isValidPosition(value)) {
      const coordinates = value.split(',');

      const location = {
        lat: +coordinates[0],
        lng: +coordinates[1],
      };

      this.position = location;
    }
  }

  private isValidPosition(value: string) {
    if (value == null) {
      return false;
    }
    const coordinates = value?.split(',');
    if (coordinates.length !== 2 || coordinates.some((coor) => isNaN(parseFloat(coor)))) {
      return false;
    } else {
      return true;
    }
  }

  moveMarker(event: google.maps.MapMouseEvent | google.maps.IconMouseEvent): void {
    if (this.mode === FormMode.View) {
      return;
    }
    const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };

    const value = `${position.lat},${position.lng}`;

    this.form.controls.ubication.setValue(value);
  }
}
