import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DamSpecificInformation } from '@app/@shared/models/infrastructures/specific-information/infrastructure2';

export enum DamSpecificInformationFieldsEnum {
  CrowningLength = 'crowning_length',
  ReservoiHeigth = 'reservoi_heigth',
  ReservoiVolume = 'reservoi_volume',
  RiverName = 'river_name',
}

@Component({
  selector: 'app-dam-specific-information-form',
  templateUrl: './dam-specific-information-form.component.html',
  styleUrls: ['./dam-specific-information-form.component.scss'],
})
export class DamSpecificInformationFormComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() specificInformation: DamSpecificInformation;

  form = this.fb.group({
    [DamSpecificInformationFieldsEnum.CrowningLength]: [0, [Validators.required]],
    [DamSpecificInformationFieldsEnum.ReservoiHeigth]: [0, [Validators.required]],
    [DamSpecificInformationFieldsEnum.ReservoiVolume]: [0, [Validators.required]],
    [DamSpecificInformationFieldsEnum.RiverName]: [null, [Validators.required]],
  });
  DamSpecificInformationFieldsEnum = DamSpecificInformationFieldsEnum;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}
}
