import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginatedResult } from '../../paginated-result.interface';
import { CreateUserParam } from './create-user.param';
import { FindUsersParam } from './find-users.param';
import { UpdateUserParam } from './update-user.param';
import { UserRole } from './user-role';
import { User } from './users';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  findUsers(param: FindUsersParam): Observable<PaginatedResult<User>> {
    const url = '/users';
    const params = param.toHttpParams();
    return this.http.get<PaginatedResult<User>>(url, { params });
  }

  getUserById(userId: number): Observable<User> {
    const url = '/users';
    const params = new HttpParams().set('_filter_id__exact', userId.toString());

    return this.http.get<PaginatedResult<User>>(url, { params }).pipe(map((value) => value.items[0]));
  }

  getUserByUsername(username: string): Observable<User> {
    const url = '/users';
    const params = new HttpParams().set('_filter_user__exact', username);

    return this.http.get<PaginatedResult<User>>(url, { params }).pipe(map((value) => value.items[0]));
  }

  getUserByEmail(email: string): Observable<User> {
    const url = '/users';
    const params = new HttpParams().set('_filter_email__exact', email);

    return this.http.get<PaginatedResult<User>>(url, { params }).pipe(map((value) => value.items[0]));
  }

  getUserRoleList(): Observable<UserRole[]> {
    const url = '/roles';
    const params = new HttpParams().set('_page_size', '0');

    return this.http
      .cache()
      .get<PaginatedResult<UserRole>>(url, { params })
      .pipe(map((value) => value.items));
  }

  createUser(param: CreateUserParam): Observable<string> {
    const url = '/user';
    // const params = param.toHttpParams();
    return this.http.post<string>(url, param);
  }

  updateUser(param: UpdateUserParam): Observable<User> {
    const url = '/user';
    return this.http.patch<User>(url, param);
  }

  enableOrDisableUser(user: string, is_active: boolean): Observable<User> {
    const param = { user, is_active } as UpdateUserParam;
    return this.updateUser(param);
  }

  // enableOrDisableUser(username: string, enableOrDisableUser: boolean): any {
  //   if (enableOrDisableUser) {
  //     return this.enableUser(username);
  //   } else {
  //     return this.disableUser(username);
  //   }
  // }

  // enableUser(user: string): any {
  //   const url = '/enable_user';

  //   return this.http.post(url, { user });
  // }

  // disableUser(user: string): any {
  //   const url = '/disable_user';

  //   return this.http.post(url, { user });
  // }
}
