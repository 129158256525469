<div fxLayout="row" fxLayoutGap="0.5rem">
  <!-- <button (click)="onUpdateImageClicked(infrastructure)" mat-raised-button fxFlex>
    <mat-icon>add_photo_alternate_outlined</mat-icon>
    {{ 'common.updateImage' | translate | uppercase}}
  </button> -->
  <button (click)="onEditInfrastructureButtonClicked()" color="primary" mat-raised-button fxFlex>
    <mat-icon>edit</mat-icon>
    {{ 'common.edit' | translate | uppercase }}
  </button>

  <button (click)="onUpdateImageButtonClicked()" color="accent" mat-raised-button fxFlex>
    <mat-icon>add_photo_alternate_outlined</mat-icon>
    {{ 'common.updateImage' | translate | uppercase }}
  </button>

  <button disabled (click)="onDeleteInfrastructureButtonClicked()" color="warn" mat-raised-button fxFlex>
    <mat-icon>delete</mat-icon>
    {{ 'common.delete' | translate | uppercase }}
  </button>
</div>
