import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IView } from '../models/view.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input() views: IView[];

  @Output() openLinkEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }


  openLink(link: string) {
    this.openLinkEvent.emit(link);
  }

}
