import { AbstractControl } from '@angular/forms';

export function UbicationValidator(control: AbstractControl) {
  const coordinates = control?.value?.split(',');
  if (
    control.value &&
    (coordinates.length !== 2 || coordinates.some((coor) => isNaN(parseFloat(coor)) || !isFinite(coor)))
  ) {
    return { validUbication: true };
  }
  return null;
}
