// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';
import { basesettings } from './base-settings';

export const environment = Object.assign(
  {},
  basesettings,
  {
    cognito: {
      Region: 'eu-west-1',
      UserPoolId: 'eu-west-1_tT3u8NTBg',
      ClientId: 't1s0sfhfrfiv4afdji4g4v18r'
    },
    cache: {
      Key: '_httpCache',
      Persistence: 'local', /* local / session / memory */
      Duration: 5 /* minutes */
    },
    webhook: {
      Endpoint: 'https://chat.googleapis.com/v1/spaces/AAAAE06rzQA/messages',
      Key: 'AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI',
      Token: 'g3vuDzPS6w3XOeRjPuqUW3wxv2DIwKFamm-u9m2EI4c%3D'
    },
    recaptchaV3SiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    production: false,
    serverUrl: 'https://api-dev.riskmanager.cloud/api',
    version: env.npm_package_version + '-dev'
  });

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

