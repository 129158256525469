<div fxFlexFill>
  <mat-toolbar>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
      <mat-divider fxFlex class="line-decorator"></mat-divider>
      <img class="logo" src="assets/images/logo_white.png" alt="iPresas logo" />
    </div>
  </mat-toolbar>
  <nav>
    <mat-list>
      <ng-container *ngFor="let view of views">
        <mat-list-item (click)="openLink(view.link)">
          <span> {{ view.label | translate | uppercase }}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
  </nav>
</div>
