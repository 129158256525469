import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResult } from '../paginated-result.interface';
import { InfrastructureNotificationRequest } from './infrastructure-notification-request';
import { CreateInfrastructureNotificationRequest } from './params/create-infrastructure-notification-request';
import { FindInfrasctructureNotificationRequestParam } from './params/find-infrastructure-notification-request.param';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureNotificationRequestsService {
  constructor(private http: HttpClient) {}

  findInfrastructureNotificationRequests(
    param: FindInfrasctructureNotificationRequestParam
  ): Observable<PaginatedResult<InfrastructureNotificationRequest>> {
    const url = '/infrastructure_notification_request';
    const params = param.toHttpParams();

    return this.http.get<PaginatedResult<InfrastructureNotificationRequest>>(url, { params });
  }

  createInfrastructureNotificationRequest(param: CreateInfrastructureNotificationRequest): Observable<{ id: number }> {
    const url = '/infrastructure_notification_request';

    return this.http.post<{ id: number }>(url, param);
  }
}
