import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Logger } from '@app/@core';

const log = new Logger('UploadFileDialogComponent');

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent implements OnInit {
  // customer: Customer;

  control = new FormControl(null, [Validators.required]);
  isUpdating = false;

  constructor(
    // @Inject(MAT_DIALOG_DATA) private data: { customer: Customer },
    private dialogRef: MatDialogRef<UploadFileDialogComponent> // private customersService: CustomersService
  ) {
    // this.customer = this.data.customer;
  }

  ngOnInit(): void {}

  onAcceptButtonClicked(): void {
    const file = this.control.value[0];
    this.dialogRef.close(file);
  }
}
