import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {
  title: string;
  description: string;
  titleParams?: any;
  descriptionParams?: any;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  description: string;
  titleParams: any;
  descriptionParams: any;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    this.title = data?.title ?? 'Confirmation';
    this.description = data?.description ?? 'Are you sure?';

    this.titleParams = data?.titleParams ?? {};
    this.descriptionParams = data?.descriptionParams ?? {};
    // this.description = data?.description ?? 'Are you sure?';
  }

  ngOnInit(): void {}

  onNoButtonClicked(): void {
    this.dialogRef.close(false);
  }

  onYesButtonClicked(): void {
    this.dialogRef.close(true);
  }
}
