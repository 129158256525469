import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  form: FormGroup;
  url: string | ArrayBuffer;

  @Input() title: string;
  @Input() disabled: boolean;

  @Input()
  set image(image: string) {
    this.url = image;
  }

  @Output() uploadedFile: EventEmitter<File> = new EventEmitter<File>();

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.initForm();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadedFile.next(file);
      const reader = new FileReader();

      reader.readAsDataURL(file); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = reader.result;
      }
    }

  }

  private initForm() {
    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });
  }


}
