<div>
  <h2 fxFlex mat-dialog-title class="center-align">
    {{'common.addNewRiskModel' | translate | uppercase }}
  </h2>
  <!-- <button mat-icon-button class="close-button" [mat-dialog-close]="null">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button> -->
</div>

<!-- <form   (ngSubmit)="addAdditionaRestriction()" [formGroup]="formGroup" novalidate> -->
<mat-dialog-content class="mat-typography" fxLayout="columm" fxLayoutGap="0.5rem">
  <div [formGroup]="form" fxLayout="column" fxLayoutGap="0.5rem" fxFlex>

    <mat-form-field appearance="outline" floatLabel='always' fxFlex>
      <mat-label>
        {{'common.title' | translate | uppercase}}
      </mat-label>
      <input matInput type="string" formControlName="title">
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel='always' fxFlex>
      <mat-label translate>
        {{'common.comment' | translate | uppercase}}
      </mat-label>
      <textarea matInput type="string" formControlName="comment"></textarea>
    </mat-form-field>

    <div fxFlex fxLayout="row" fxLayoutGap="0.5rem">
      <app-select-year fxFlex formControlName="initYear" label="common.initYear" [showClearButton]="false">
      </app-select-year>
      <app-select-year fxFlex formControlName="endYear" label="common.endYear" [showClearButton]="false">
      </app-select-year>
    </div>


    <app-files-to-upload [formControl]="fileFormControl" [limit]="1" fxFlex>
    </app-files-to-upload>

    <mat-form-field floatLabel="always" appearance="outline" fxFlex style="padding-top: 1rem; padding-bottom: 2rem;">
      <mat-label>
        {{'common.documentationLink' | translate | uppercase}}
      </mat-label>
      <input matInput type="string" formControlName="link_documentation">
      <mat-hint>
        {{ 'common.documentationLinkHint' | translate }}
      </mat-hint>
      <mat-error *ngIf="form.controls.link_documentation.hasError('pattern')">
        {{ 'common.documentationLinkInvalidFormat' | translate }} <br>
        {{ 'common.documentationLinkHint' | translate }}
      </mat-error>
    </mat-form-field>

  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
  <button [mat-dialog-close]="null" mat-raised-button fxFlex>
    <mat-icon>cancel</mat-icon>
    {{'common.cancel'| translate | uppercase}}
  </button>

  <button (click)="onSaveButtonClicked()" [disabled]="form.invalid || isSaving" mat-raised-button color="primary"
    fxFlex>
    <mat-icon>save</mat-icon>
    {{ (isSaving?'common.saving':'common.save')| translate | uppercase}}
  </button>

</mat-dialog-actions>
