import {
  NodeModel,
  NodeModelOptions,
} from '@rxzu/angular';


export class RiskManagementNodeModel extends NodeModel {

  constructor(options: NodeModelOptions) {
    super({
      ...options,
      namespace: 'risk-management',
    });

  }

}
